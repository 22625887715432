import styled from "styled-components";

export const Portrait2Style = styled.div`
width:100%;
height:100%;

.container_two_two .img-fluid {
  display: block;
  max-width: 100%;
}

.container_two_two {
  width:100%;
  height:100%;
  overflow: hidden;
}
  

.container_two_two div {
  font-family: 'Poppins', sans-serif;
  line-height: 1.2;
  font-weight: 600;
  margin: 0;
}

.container_two_two .front-layer {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.front-layer img, .front-layer video{
  position: absolute;
}

.container_two_two .front-layer img {
  width:100%;
height:100%;
  transform: rotateY(180deg);
  /* position: absolute; */
}

.container_two_two .back-layer {
  width:100%;
  height:100%;
  position: absolute;
  background-size: cover;
  /* transform: rotateY(180deg); */
}

.container_two_two .back-layer:after {
  content: '';
  width:100%;
height:100%;
  background: #f5bfcf;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.container_two_two .text-wrappers {
  position: absolute;
  top: 0;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  left: 0;
  padding: 5%;
  width:60%;
  height:100%;
  /* transform: rotateY(180deg); */
}

.container_two_one{
  width:100%;
  height:100%;
}
.back-layer{
  width: 100%;
  height: 100%;
}

.front-layer img, .front-layer video{
  width:100%;
}

.text-wrappers {
  position:absolute;
  top:0;
  left:50%;
  width:50%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;
}
.text-wrappers > div {
  width:100%;
  overflow: hidden;
}


`;
