import React from "react";
import { Regular8Style } from "./css/Regular8Style";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";

const regular_1_8 = (props) => {
  return (
    <Regular8Style>
      <div className="template-wrapper">
        <TemplateLogo logo={props.logo} />
        <TemplateWatermark watermark={props.watermark} />
        <div className="t8-containereight">
          <div className="t8-img-wrapper t8-woman">
            <TemplateImage slideType={props.slideType} file={props.file} />
          </div>
          <div className="t8-text-wrappers">
            <TemplateText
              id={props.id}
              text={props.text}
              textStyle={props.textStyle}
              editStatus={props.editStatus}
              onInputSlideText={props.onInputSlideText}
            />
          </div>
        </div>
      </div>
    </Regular8Style>
  );
};

export default regular_1_8;
