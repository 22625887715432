import React from "react";
import { Story1Style } from "./css/Story1Style";
import OutroLogo from "../../components/editor/Templates/OutroLogo";
import OutroText from "../../components/editor/Templates/OutroText";

const story_1 = (props) => {
  return (
    <Story1Style>
      <div className="poster flex-column">
        <div className="logo-container flex-column">
          <OutroLogo logo={props.file} />
        </div>
        <div className="text-wrapper flex-column">
          <OutroText
            id={props.id}
            text={props.text}
            textStyle={props.textStyle}
            editStatus={props.editStatus}
            onInputSlideText={props.onInputSlideText}
          />
        </div>
      </div>
    </Story1Style>
  );
};

export default story_1;
