import styled from "styled-components";

export const Square2Style = styled.div`
  .flex-column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .flex-row-center {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .flex-row-left {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: left;
    justify-content: left;
  }

  .subflex-left {
    width: 100%;
    text-align: left;
  }

  .subflex-right {
    width: 100%;
    text-align: right;
  }

  .subflex-center {
    width: 100%;
    text-align: center;
  }
  .text-wrapper {
    white-space: pre-wrap;
  }
  /*.f-arial {*/
  /*	font-family: Arial;*/
  /*}*/

  /*.f-mvboli {*/
  /*	font-family: mvboli;*/
  /*}*/

  /*.f-nexalight {*/
  /*	font-family: nexalight;*/
  /*}*/

  /*.f-nexabold {*/
  /*	font-family: nexabold;*/
  /*}*/

  .f-cyan {
    color: #00fffc;
  }

  .f-cyan-dark {
    color: #00d1cf;
  }

  .f-blue {
    color: #005596;
  }

  .f-white {
    color: #ffffff;
  }

  .poster {
    position: relative;
    overflow: hidden !important;
  }

  .poster.nine-by-sixteen {
    width: 1080px;
    height: 1920px;
  }

  .poster.one-by-one {
    width: 310px;
    height: 310px;
  }

  .poster.nine-by-sixteen {
    width: 171px;
    height: 304px;
  }

  .poster .bg.two::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(0, 85, 150, 0.4);
  }
  .poster one-by-one {
    position: relative;
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .poster-content {
    width: 100%;
  }
  .poster-content.two {
    overflow: hidden;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #005596;
  }

  .poster-content.two::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: #00fffc;
  }

  .poster-content.two .text-wrapper {
    height: 100%;
    width: 85% !important;
  }

  .poster-content.two .ta {
    margin: 0 -100% 0 0;
    text-align: right;
  }

  .poster.one-by-one .bg.two {
    background-position: -43px center;
  }

  .poster.one-by-one .poster-content.two .ta {
    font-size: 14px !important;
    line-height: 19px;
  }

  .text-wrapper {
    width:100%;
    height:100%
  }
  .text-wrapper > div {
    width:100%;
    overflow: hidden;
    margin-bottom: 5%;
  }
`;
