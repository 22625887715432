import styled from "styled-components";

export const Regular4Style = styled.div`
  width: 100% !important;
  height: 100% !important;


  .t4-containerfour .t4-img-fluid {
    display: block;
    max-width: 100%;
  }

  .t4-containerfour {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .t4-containerfour {
    background: transparent;
  }

  .f-cyan {
    color: #00fffc;
  }

  .f-white {
    color: #fff;
  }

  .t4-containerfour div {
    font-family: "Poppins", sans-serif;
    line-height: 1.3;
    font-weight: 500;
    margin: 0;
  }

  .t4-containerfour .t4-text-wrappers div {
    font-size: 1.2vw;
    text-align: center;
    margin-bottom: 10px !important;
  }

  .t4-containerfour .t4-text-wrappers {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: -40%;
    width: 55%;
    margin: auto;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: 150px;
    right: 6%;
    background: #0000;
    padding: 10px 20px;
    -webkit-clip-path: inset(0% 100% 0% 0%);
    clip-path: inset(0% 100% 0% 0%);
    overflow: hidden;
  }

  .t4-containerfour .t4-woman img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: 50% 50%;
  }
`;
