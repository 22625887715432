import styled from "styled-components";

export const Regular1Style = styled.div`
  width: 100%;
  height: 100%;

  .flex-column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .flex-row-center {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .flex-row-left {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: left;
    justify-content: left;
  }

  .subflex-left {
    width: 100%;
    text-align: left;
  }

  .subflex-right {
    width: 100%;
    text-align: right;
  }

  .subflex-center {
    width: 100%;
    text-align: center;
  }

  .f-arial {
    font-family: Arial;
  }

  .f-helv {
    font-family: Helvetica;
  }

  .f-helvbold {
    font-family: HelveticaBold;
  }

  .f-black {
    color: #000;
  }

  .f-white {
    color: #ffffff;
  }

  .f-orange {
    color: #ff7d01;
  }

  .f-blue {
    color: #0076fe;
  }

  .poster {
    position: relative;
    overflow: hidden !important;
  }

  .poster.sixteen-by-nine.lg {
    // width: 1920px;
    // height: 1080px;
    width: 100%;
    height: 100%;
  }

  .poster.sixteen-by-nine.sm {
    width: 560px;
    height: 315px;
  }

  .poster.nine-by-sixteen.lg {
    width: 1080px;
    height: 1920px;
  }

  .poster.nine-by-sixteen.sm {
    width: 171px;
    height: 304px;
  }

  .poster.one-by-one.lg {
    width: 1920px;
    height: 1920px;
  }

  .poster.one-by-one.sm {
    width: 310px;
    height: 310px;
  }

  .img-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .img-container > img {
    position: absolute;
    width: 100%;
  }

  .cover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .text-wrapper {
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
  }

  .text-wrapper > div {
    width: 100%;
    position: relative;
    color:#fff;
  }

  /* FOR TESTING ONLY */

  .f-lg {
    font-size: 122px;
  }

  .f-sm {
    margin-top: 30px;
    font-size: 62px;
  }

  .poster.sm .f-lg {
    font-size: 28px;
  }

  .poster.sm .f-sm {
    margin-top: 3px;
    font-size: 14px;
  }
`;
