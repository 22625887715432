import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import {baseURL} from "../../global/global";
import {fetchSocialAccounts} from "../../actions/socialAction";
import Alert from "../Alert";
import {setAlert} from "../../actions/alert";

const ConnectReelapps = (props) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [appDetails, setAppDetails] = useState({
        user_id:  auth.user.id,
        app_name: props.type,
        email: '',
        password: ''
    });

    const [intData, setIntData] = useState({
        id: '',
        user_id: auth.user.id
    })

    // Sweetalert
    const [button, setButton] = useState('Disconnect');
    const [showDelete, setShowDelete] = useState(false);

    const onConfirm = () => {
        setButton('Disconnecting...');
        deleteSocialAccounts();
    }

    const onCancel = () => {
        setShowDelete(false);
    }

    const deleteIntegration = (id) => {
        setIntData({...intData, id: id});
        setShowDelete(true);
    }

    const connectPopUp = (e) => {
        setShowModal(true);
    }

    const onInputChange = (e) => {
        setAppDetails({...appDetails, [e.target.name] : e.target.value});
    }

    const deleteSocialAccounts = () => {
        axios({
            method: "POST",
            url: `${baseURL}delete-integration`,
            data: intData,
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                dispatch(fetchSocialAccounts(auth.token));
            }else{
                dispatch(setAlert(res.data.message,'danger'));
            }
            setShowDelete(false);
            setButton('Delete');
        }).catch(err=>{
            setShowDelete(false);
            setButton('Delete');
        })
    }


    const connectSocial = async () => {
        await axios({
            method: "POST",
            url: `${baseURL}connect-to-app`,
            data: appDetails,
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                dispatch(setAlert(`${props.name} connected successfully`,'success'))
                dispatch(fetchSocialAccounts(auth.token));
            }else{
                dispatch(setAlert(res.data.message,'danger'));
            }
            setShowModal(false);
            setLoader(false);
        }).catch(err=>{
            setShowModal(false);
            setLoader(false);
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        connectSocial().then(r => console.log(r));
    }

    return(
        <>
            <Alert/>
            <div className="connection-box mt-3" id={`${props.name}_link`}>
                <div className="row">
                    <div className="col-md-1 d-flex justify-content-center">
                        <div className="connection-img">
                            <img src={props.icon} width="56px" alt={props.name} />
                        </div>
                    </div>
                    <div className="col-md-11">
                        {
                            props.data.length > 0 ?
                                props.data.map((item,index)=>{
                                    return(
                                        <div key={index} className="facebook-connection text-white d-flex justify-content-between  align-items-center">
                                            <div className="facebook-content">
                                                <h6>{props.name} {item?.email}</h6>
                                                <p className="m-0">Added on {item.created}</p>
                                            </div>
                                            <div className="facebook-delete">
                                                <button onClick={(e)=>deleteIntegration(item.id)} type="button" className="btn-change7">Disconnect</button>
                                            </div>
                                        </div>
                                    )
                                })
                            : <div className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                <div className="youtube-content">
                                    <h6>{props.name}</h6>
                                    <p className="m-0">Not Connected</p>
                                </div>
                            </div>
                        }

                        <div className="facebook-connect-account d-flex justify-content-center  mt-4">
                            <button onClick={(e)=>connectPopUp(e)} className="btn-change7">Connect</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal ${showModal ? 'show-modal' : ''} `}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{margin: "auto"}}>
                                <img src={props.icon} width="56px" alt={props.name} className="mr-2" /> {`Connect ${props.name}`}
                            </h5>
                            <button onClick={(e)=>setShowModal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <i className="fas fa-times-circle" style={{color: "#ffffff"}} ></i></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="add-account-box mt-2" method="post" onSubmit={(e)=>onFormSubmit(e)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <small className="form-text text-muted">Email Address</small>
                                            <input type="email" className="form-control" placeholder="Enter Email"
                                                   required name="email"
                                                   onChange={(e) => onInputChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <small className="form-text text-muted">Password</small>
                                            <input type="password" className="form-control"
                                                   placeholder="********" required name="password"
                                                   onChange={(e) => onInputChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mt-3 d-flex justify-content-center align-items-center ">
                                        <div className="add-account-save-btn">
                                            <button type="submit" className="btn-change7" disabled={loader}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Connect
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e)=>onConfirm(e)}
                onCancel={(e)=>onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to disconnect this {props.name} account?
            </SweetAlert>
        </>
    )
}

export default ConnectReelapps;