import React from "react";
import { Portait5Style } from "./css/Portrait5Style";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";

const Template = (props) => {
  return <Portait5Style>
    <div className="container9">
        <TemplateLogo logo={props.logo} />
        <TemplateWatermark watermark={props.watermark} />
        <div className="back-layer">
          <TemplateImage slideType={props.slideType} file={props.file} />
        </div>
        <div className="text-wrapper">
          <div className="yellow-bar">
            <div className="yellow-wrap top">
              <div className="bar yellow" />
              <div className="bar corn" />
            </div>
            <div className="yellow-wrap bottom">
              <div className="bar corn" />
              <div className="bar yellow" />
            </div>
          </div>
          <div className="wrap">
            <div className="inner-wrap">
              <TemplateText
                id={props.id}
                text={props.text}
                textStyle={props.textStyle}
                editStatus={props.editStatus}
                onInputSlideText={props.onInputSlideText}
              />
            </div>
          </div>
        </div>
      </div>
  </Portait5Style>;
};

export default Template;
