import styled from "styled-components";

export const Square1Style = styled.div`
width:100%;
height:100%;


  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container8 {
    width:100%;
    height:100%;  
    overflow: hidden;
  }

  .f-white {
    color: #fff;
  }

  .f-pippin {
    color: #ffe0db;
  }

  .text-wrapper div {
    width:100%
  }

  .back-layer {
    width:100%;
    height:100%;
  
  }

  .back-layer img,
  .back-layer video {
    width:100%;
  height:100%;
    object-fit: cover;
    object-position: center;
  }

  .text-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
  }
`;
