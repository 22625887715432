import styled from "styled-components";

export const Square7Style = styled.div`
width: 100%;
height: 100%;

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container10 {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .back-layer {
    width: 100%;
height: 100%;
    background: -moz-linear-gradient(
      top,
      rgba(255, 38, 252) 0%,
      rgba(255, 245, 128) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(255, 38, 252) 0%,
      rgba(255, 245, 128) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(255, 38, 252) 0%,
      rgba(255, 245, 128) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccff26fc', endColorstr='#ccfff580',GradientType=0 );
  }

  .back-layer img,
  .back-layer video {
    width: 100%;
height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0.5;
  }

  .front-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    z-index: 1;
  }

  .front-layer .col {
    flex: 0 0 10%;
    max-width: 10%;
  }

  .front-layer .col .fw {
    height: 100%;
    background: #fff;
    width: 100%;
    position:absolute;
  }

  .text-wrapper {
    position: absolute;
    bottom: 0;
    top: 0;
    margin: auto;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
  }

  .text-wrapper div {
    text-align: center;
    width:100%;
  }
`;
