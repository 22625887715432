import React from "react";
import { Square2Style } from "./css/Square2Style";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";
const square_10_1 = (props) => {
  return (
    <Square2Style>
      <div className="poster one-by-one lg flex-column">
        <TemplateLogo logo={props.logo} />
        <TemplateWatermark watermark={props.watermark} />
        <div className="text-wrapper flex-column">
          <TemplateText
            id={props.id}
            text={props.text}
            textStyle={props.textStyle}
            editStatus={props.editStatus}
            onInputSlideText={props.onInputSlideText}
          />
        </div>
        <div className="video-outer">
          <div className="video-container">
            <TemplateImage slideType={props.slideType} file={props.file} />
          </div>
        </div>
      </div>
    </Square2Style>
  );
};

export default square_10_1;
