import React from "react";
import { Regular5Style } from "./css/Regular5Style";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";

const regular_1_5 = (props) => {
  return (
    <Regular5Style>
      <div className="template-wrapper">
        <TemplateLogo logo={props.logo} />
        <TemplateWatermark watermark={props.watermark} />
        <div className="t5-containerfive">
          <div className="t5-first-layer">
            <div className="t5-t-colmn t5-t-colmn-1" />
            <div className="t5-t-colmn t5-t-colmn-2" />
          </div>
          <div className="t5-second-layer">
            <div className="t5-t-colmn t5-t-colmn-1">
              <div className="t5-img-wrapper t5-woman">
                <TemplateImage slideType={props.slideType} file={props.file} />
              </div>
            </div>
            <div className="t5-t-colmn t5-t-colmn-2">
              <div className="t5-text-wrappers">
                <TemplateText
                  id={props.id}
                  text={props.text}
                  textStyle={props.textStyle}
                  editStatus={props.editStatus}
                  onInputSlideText={props.onInputSlideText}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Regular5Style>
  );
};

export default regular_1_5;
