import React, { useEffect, useState } from "react";
import { HiOutlineUpload } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { FaSpinner } from "react-icons/fa";
import { SketchPicker } from 'react-color';
import rgbHex from "rgb-hex";
import { onUploadMedia } from "../../actions/CommonActions";
import { onFetchWhiteLabel, onWhiteLabel } from "../../actions/WhiteLabelActions";
import TitleBar from "../Common/TitleBar";
import { setAlert } from "../../actions/alert";
// import Header from "../Header/Header";

const WhiteLabel = () => {
    const dispatch = useDispatch()
    // const autoresponderList = useSelector(state => state.social.autoresponder)
    const [percent, setPercent] = useState(0)
    const [state, setState] = useState({
        name: "",
        aiKey: "",
        // autoresponderId: "",
        logo: "https://backend.clipsreel.io/assets/media/4857/images/1731909790_9097179301_4857.png",
        color: {
            first: "#9461fc",
            second: "#EF1564"
        },
        domain: ""

    })
    const [colorShow, setColorShow] = useState({
        first: false,
        second: false
    })

    const [loader, setLoader] = useState({
        upload: false,
        submit: false,
        fetch: true
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const onCompeleteUpload = (url) => {

        setState({
            ...state,
            logo: url
        })

    }

    const onInputColor = (e, name) => {
        let rgba = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a.toFixed(2)})`
        let hex = `#${rgbHex(rgba)}`
        setState({
            ...state,
            color: {
                ...state.color,
                [name]: hex
            }
        })
    }

    const onInputImage = (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
                if (e.target.files[0].size < 5000000) {
                    formData.append("file_type", "image")
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 5MB!',
                        confirmButtonColor: "#000"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: "#000"
                })
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = { ...state }
        const domainRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(:[0-9]{1,5})?(\/.*)?$/;
        if (!domainRegex.test(state.domain)) {
            dispatch(setAlert("Please enter a valid domain URL", "danger"))
            return;
        }
        if (!state.aiKey.startsWith("sk")) {
            dispatch(setAlert("Please enter valid key", "danger"))
            return;
        }
        setLoader({
            ...loader,
            submit: true
        })
        let url = "create-whitelabel"
        if (data.id) {
            url = "update-whitelabel"
            delete data.domain
        }
        dispatch(onWhiteLabel(url, data, loader, setLoader))
    }

    const fetchWhiteLabel = () => {
        let data = {}
        dispatch(onFetchWhiteLabel(data, setState, loader, setLoader))
    }

    useEffect(() => {
        fetchWhiteLabel()
    }, [])

    return (
        <>
            <TitleBar title="White Label" />
            {/* <Header /> */}
            <form className="White_label_wrap" onSubmit={handleSubmit}>
                <div className="white-titleBar-left">
                    <h2>White Label</h2>
                    <p>Create and Manage White Label</p>
                </div>
                <div className="white-block" >
                    {loader.fetch ?
                        <div style={{ height: 600, display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <FaSpinner className="spin" size={25} />
                        </div> :
                        <>
                            <div className="white-input-wrap mt-0">
                                <label htmlFor="">Name</label>
                                <div className="white-outer">
                                    <input
                                        type="text"
                                        className="white_inp"
                                        placeholder="Enter Your Name"
                                        value={state.name}
                                        name="name"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="white-input-wrap">
                                <label htmlFor="">Open AI key</label>
                                <div className="white-outer">
                                    <input
                                        type="text"
                                        className="white_inp"
                                        placeholder="Enter Open AI key"
                                        value={state.aiKey}
                                        name="aiKey"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>

                            {/* <div className="white-input-wrap">
                                <label htmlFor="">Autoresponder</label>
                                <div className="white-outer">
                                    <select
                                        name="autoresponderId"
                                        onChange={handleChange}
                                        value={state.autoresponderId}
                                        className="white_inp"
                                    >
                                        <option>Select Autoresponder</option>
                                        {autoresponderList.length > 0 ?
                                            autoresponderList.map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem.id}>{curElem.name}</option>
                                                )
                                            })
                                            : ""}
                                    </select>
                                </div>
                            </div> */}

                            <div className="white-input-wrap" style={{ position: "relative" }}>
                                <label className="label" htmlFor="">Gradient Color</label>
                                <div className="row">
                                    <div className="col-12 col-sm-6 white-sketch-picker">
                                        <label className="label" htmlFor=""> First</label>
                                        <div className="white-outer">
                                            <div style={{ position: "relative" }}>
                                                <input className="white_inp" type="text" value={state.color.first} readOnly />
                                                <span style={{
                                                    width: 50,
                                                    height: "100%",
                                                    background: state.color.first,
                                                    top: 0,
                                                    right: 0,
                                                    borderRadius: 5,
                                                    position: "absolute",
                                                    border: "1px solid #b3b0b0",
                                                    cursor: "pointer"
                                                }}
                                                    onClick={() => setColorShow({
                                                        ...colorShow,
                                                        first: true
                                                    })}
                                                />
                                            </div>
                                        </div>

                                        {colorShow.first ?
                                            <>
                                                <div className="white-swatch-cover"
                                                    onClick={() => setColorShow({
                                                        ...colorShow,
                                                        first: false
                                                    })}
                                                />
                                                <SketchPicker
                                                    color={state.color.first} onChange={(e) => onInputColor(e, "first")}
                                                />
                                            </>
                                            : null}
                                    </div>
                                    <div className="col-12 col-sm-6 white-sketch-picker">
                                        <label className="label" htmlFor="">Second</label>

                                        <div className="white-outer">
                                            <div style={{ position: "relative" }}>
                                                <input className="white_inp" type="text" value={state.color.second} readOnly />
                                                <span style={{
                                                    width: 50,
                                                    height: "100%",
                                                    background: state.color.second,
                                                    top: 0,
                                                    right: 0,
                                                    borderRadius: 5,
                                                    position: "absolute",
                                                    border: "1px solid #b3b0b0",
                                                    cursor: "pointer"
                                                }}
                                                    onClick={() => setColorShow({
                                                        ...colorShow,
                                                        second: true
                                                    })}
                                                />
                                            </div>
                                        </div>

                                        {colorShow.second ?
                                            <>
                                                <div className="white-swatch-cover"
                                                    onClick={() => setColorShow({
                                                        ...colorShow,
                                                        second: false
                                                    })}
                                                />
                                                <SketchPicker
                                                    color={state.color.second} onChange={(e) => onInputColor(e, "second")}

                                                />
                                            </>
                                            : null}
                                    </div>
                                </div>
                            </div>

                            <div className="white-input-wrap">
                                <label htmlFor="">Upload Logo</label>
                                <div className='white-file-upload text-center'>
                                    <div style={{ height: 40, width: 210 }}>
                                        {loader.upload ?
                                            <FaSpinner className="spin" size={25} />
                                            :
                                            <img alt="" src={state.logo} style={{ maxHeight: "100%" }} />
                                        }
                                    </div>
                                    <div className='fs-3'>
                                        <HiOutlineUpload />
                                    </div>
                                    <p>Upload Logo (JPG, PNG, JPEG upto 5MB is allowed) <br /> (210x40) Pixels recommended</p>
                                    {/* {loader.upload ?
                                        "" : */}
                                    <input
                                        type="file"
                                        onChange={onInputImage}
                                        accept='image/png, image/jpg, image/jpeg'
                                    />
                                    {/* } */}
                                </div>
                            </div>

                            <div className="white-input-wrap">
                                <label htmlFor="">Add Domain</label>
                                <div className="row align-items-center flex-column flex-sm-row">
                                    <div className="col mb-2 mb-sm-0">
                                        <div className="white-outer">
                                            <input
                                                type="text"
                                                className="white_inp"
                                                placeholder="Enter Domain URL"
                                                value={state.domain}
                                                name="domain"
                                                onChange={handleChange}

                                                readOnly={state.id ? true : false}

                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-auto"><button className='white-theme-btn'><span>Check & Submit {loader.submit ? <FaSpinner className="spin" /> : ""}</span></button></div>
                                </div>
                            </div>
                            <ul className="white-list-item mt-4 mt-md-5">
                                <li>Please use top-level domains only (sub-domains won’t work).</li>
                                <li>Please enter your domain above without any www, http or https (domain.com for example).</li>
                                <li>Enter your domain name for example "mydomain.com" (without the quotation marks) and click the "Add Domain" button.</li>
                                <li>Copy this IP address for the A Record: 72.52.158.36</li>
                                <li>Log into your domain registrar account and find the option to add Host Records, which can usually be found in the </li>
                                <li>Advanced DNS section. If you're struggling to find this, perform a Google search for "[Domain Registrar Here] how to change host records".</li>
                                <li>Select your type as "A Record".</li>
                                <li>Set Host as "@".</li>
                                <li>Paste the copied IP Address from step 4 into the value field.</li>
                                <li>Leave TTL as Automatic or 14400.</li>
                                <li>Click Save and you're done.</li>
                                <li>Note: It can take up to 48-72 hours to reflect.</li>
                            </ul>
                        </>
                    }
                </div>
            </form>

        </>
    )
}

export default WhiteLabel;