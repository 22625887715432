import React from "react";
import { Square8Style } from "./css/Square8Style";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";

const Template = (props) => {
  return <Square8Style>
      <div className="container7">
        <TemplateLogo logo={props.logo} />
        <TemplateWatermark watermark={props.watermark} />
        <div className="back-layer">
          <TemplateImage slideType={props.slideType} file={props.file} />
          <div className="shape-wrap">
            <div className="shape" />
            <div className="shape transparent" />
          </div>
        </div>
        <div className="text-wrapper">
          <TemplateText
            id={props.id}
            text={props.text}
            textStyle={props.textStyle}
            editStatus={props.editStatus}
            onInputSlideText={props.onInputSlideText}
          />
        </div>
      </div>
  </Square8Style>;
};

export default Template;
