import React from "react";
import { Portait7Style } from "./css/Portrait7Style";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";

const Template = (props) => {
  return (
    <Portait7Style>
      <div className="poster nine-by-sixteen lg">
        <TemplateLogo logo={props.logo} />
        <TemplateWatermark watermark={props.watermark} />
        <div className="img-container flex-column">
          <TemplateImage slideType={props.slideType} file={props.file} />
        </div>
        <div className="cover" />
        <div className="text-wrapper flex-column">
          <TemplateText
            id={props.id}
            text={props.text}
            textStyle={props.textStyle}
            editStatus={props.editStatus}
            onInputSlideText={props.onInputSlideText}
          />
        </div>
      </div>
    </Portait7Style>
  );
};

export default Template;
