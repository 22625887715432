const initialState = {
    facebook: [],
    youtube: [],
    scriptreel: [],
    livereel: [],
    trimreel: [],
    syvid: [],
    sonority: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'ADD_SOCIAL_ACCOUNTS':
            return {
                ...state,
                facebook: action.payload?.facebook ? action.payload?.facebook : [],
                youtube: action.payload?.youtube ? action.payload?.youtube : [],
                scriptreel: action.payload?.scriptreel ? action.payload?.scriptreel : [],
                livereel: action.payload?.livereel ? action.payload?.livereel : [],
                trimreel: action.payload?.trimreel ? action.payload?.trimreel : [],
                syvid: action.payload?.syvid ? action.payload?.syvid : [],
                sonority: action.payload?.sonority ? action.payload?.sonority : []
            }

        case 'ADD_SYVID':
            return {
                ...state,
                syvid: action.payload
            }

        case 'REMOVE_SOCIAL_ACCOUNT':
            return {
                ...initialState
            }

        default:
            return state
    }
}