import styled from "styled-components";

export const Regular7Style = styled.div`
width: 100% !important;
height: 100% !important;

.t7-containersaven .t7-img-fluid {
  display: block;
  max-width: 100%;
}

.t7-containersaven {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #00d1cf;
  display: flex;
  align-items: center;
}

.f-cyan {
  color: #00fffc;
}

.f-white {
  color: #fff;
}

.f-blue {
  color: #005596;
}

.t7-containersaven div {
  font-family: "Poppins", sans-serif;
  line-height: 1.3;
  font-weight: 500;
  margin: 0;
}

.t7-containersaven .t7-text-wrappers div {
  font-size: 1.5vw;
  text-align: center;
}

.t7-containersaven .t7-t-colmn-1,
.t7-t-colmn-2 {
  flex: 0 0 45%;
  max-width: 45%;
  height: 100%;
  position: relative;
}

.t7-containersaven .t7-t-colmn.t7-t-colmn-2 {
  display: flex;
  align-items: center;
  padding: 0 4vw 0 4vw;
  flex: 0 0 55%;
  max-width: 50%;
}

.t7-containersaven .t7-woman {
  border: 30px solid #005596;
  border-top-right-radius: 100%;
  overflow: hidden;
  border-bottom-right-radius: 100%;
  transform: scale(1.2);
  left: -150%;
  top: 0;
  height: 100%;
  position: relative;
}

.t7-containersaven .t7-woman img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.t7-containersaven .t7-text-wrappers {
  opacity: 1;
  max-height: 75%;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 1366.98px) {
  .t7-containersaven .t7-t-colmn.t7-t-colmn-2 {
    padding: 0 3.5vw 0 3.5vw;
  }

  .t7-containersaven .t7-woman {
    transform: scale(1.3);
    border: 20px solid #005596;
  }

  .t7-containersaven .t7-text-wrappers p {
    /*font-size: 1.3vw;*/
  }
}

@media screen and (max-width: 991.98px) {
  .t7-containersaven .t7-woman {
    border: 30px solid #005596;
  }

  .t7-containersaven .t7-text-wrappers p {
    /*font-size: 2.3vw;*/
  }
}

@media screen and (max-width: 640.98px) {
  .t7-containersaven .t7-woman {
    border: 10px solid #005596;
  }

  .t7-containersaven .t7-text-wrappers p {
    /*font-size: 3vw;*/
  }

  .t7-containersaven .t7-t-colmn.t7-t-colmn-2 {
    padding: 0 7vw 0 7vw;
  }
}
`;
