import React, {useCallback, useState} from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import axios from "axios";
import {baseURL} from "../../global/global";
import {setAlert} from "../../actions/alert";
import {useDispatch, useSelector} from "react-redux";
import {updateSlideImage, updateSlideVideo} from "../../actions/videoAction";

const FileCrop = ({ selectedIndex ,dimension, setCropModel, image , type }) => {

    let dimArr = dimension.split(':');

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    // const [cropModel, setCropModel] = useState(false);
    const [checkImage, setCheckImage] = useState(false);
    const [currentImage, setCurrentImage] = useState(image);
    const [outputImage, setOutputImage] = useState(false);
    const [cropLoader, setCropLoader] = useState(false);
    const [outputVideo, setOutputVideo] = useState(false);
    const [outputThumb, setOutputThumb] = useState(false);

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)

    const selectCropImage = (image) => {
        let jsonData = {
            file_url: image,
            poster: image,
            index: selectedIndex
        }
        console.log(jsonData);

        dispatch(updateSlideImage(jsonData));
        setCropModel(false);
        setOutputImage(false);
    }

    const selectVideo = (image) => {
        console.log(image,'cropppppppppp');

        let jsonData = {
            file_url: image,
            poster: outputThumb,
            index: selectedIndex,
            type: 'video'
        }

        setCropModel(false);
        setOutputVideo(false);
        dispatch(updateSlideVideo(jsonData))
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        setCropLoader(true);
        try {
            const croppedImage = await getCroppedImg(
                currentImage,
                croppedAreaPixels,
                rotation
            )
            downloadFiles(croppedImage);
            setCroppedImage(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    const closePopup = () => {
        setCropModel(false);
        setOutputImage(false);
    }

    const downloadFiles = (imageData) => {
        let ext = currentImage.match(/\.([^\./\?]+)($|\?)/)[1];

        setCropLoader(true);
        axios({
            method: "POST",
            url: `${baseURL}generate-file`,
            data: JSON.stringify({ data: imageData, format: ext }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setOutputImage(res.data.data);
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setCropLoader(false);
        }).catch(err => {
            console.log(err);
            setCropLoader(false);
        })
    }

    const cropVideoFile = () => {
        setCropLoader(true);

        let formData = new FormData();
        formData.append('width', croppedAreaPixels.width);
        formData.append('height', croppedAreaPixels.height);
        formData.append('videourl', currentImage);
        formData.append('postionx', croppedAreaPixels.x);
        formData.append('postiony', croppedAreaPixels.y);
        formData.append('user_id', auth.user.id);

        axios({
            method: "POST",
            url: `${baseURL}video-crop`,
            data: formData,
            headers: {'Authorization': auth.token}
        }).then(res=>{
            console.log(res);
            if(res.data.status === true){
                setOutputVideo(res.data.video);
                setOutputThumb(res.data.thumbnail);
            }else{
                dispatch(setAlert(res.data.message,'danger'));
            }
            setCropLoader(false);
        }).catch(err=>{
            console.log(err);
            setCropLoader(false);
        })
    }


    return(
        <>
            <div className={`modal show-modal `} style={{ height: "auto" }}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-center">Crop {type}</h5>
                            <button onClick={(e) => closePopup()} type="button" className="close" data-dismiss="modal"
                                    aria-label="Close">
                                <span aria-hidden="true"><i className="fas fa-times-circle" style={{ color: "#ffffff" }} ></i></span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ position: "relative", height: "50vh" }}>
                            {
                                type === "video" ?
                                    <Cropper
                                        video={currentImage}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={ dimArr[0] / dimArr[1]}
                                        onCropChange={setCrop}
                                        onZoomChange={setZoom}
                                        onCropComplete={onCropComplete}
                                    />
                                 : <Cropper
                                        image={currentImage}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={dimArr[0] / dimArr[1]}
                                        onCropChange={setCrop}
                                        onZoomChange={setZoom}
                                        onCropComplete={onCropComplete}
                                        objectFit="contain"
                                    />
                            }
 
                        </div>
                        <div className="modal-footer d-flex justify-content-between">
                            {
                                type === "video" ?
                                    <>
                                        <button className="btn-custom mr-1" type="button" onClick={()=>cropVideoFile()}>
                                            {cropLoader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''}
                                            {cropLoader ? 'Cropping' : 'Crop'}
                                        </button>
                                        <button className="btn-custom" type="button"
                                                disabled={outputVideo===false?true:false}
                                                onClick={() => selectVideo(outputVideo)} >Use</button>
                                    </>
                                 :
                                    <>
                                        <button className="btn-custom mr-1" type="button" onClick={() => showCroppedImage()}>
                                            {cropLoader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}
                                            {cropLoader ? 'Cropping' : 'Crop'}
                                        </button>
                                        <button className={`${outputImage === false ? 'btn-custom-disable' : 'btn-custom'}`} type="button" disabled={outputImage === false ? true : false} onClick={() => selectCropImage(outputImage)}>Use</button>
                                    </>

                            }
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default FileCrop;