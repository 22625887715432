import axios from "axios";
import { baseURL } from "../global/global";
import { setAlert } from "./alert";
import { fetchRebrandData } from "./rebrandAction";
import { fetchSocialAccounts } from "./socialAction";
import { fetchUserCampaigns } from "./campaignAction";
import { addMergeVideos } from "./mergeAction";
import WebFont from "webfontloader";
import { getBrandingDataFromServer } from "./branding";
import { commonAxios } from "../global/CommonAxios";

export const loadUser = () => async (dispatch, getState) => {

    let headers;
    let data = {};

    if (localStorage.state) {
        headers = {
            'Content-Type': 'application/json',
            'Authorization': JSON.parse(localStorage.getItem('state')).auth.token
        }

        data = {
            'type': JSON.parse(localStorage.getItem('state')).auth.user.is_virtual_account
        }
    }

    axios({
        method: 'POST',
        url: `${baseURL}fetch-user`,
        data: data,
        headers: headers
    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'LOAD_USER_DATA', payload: res.data.data });
            dispatch(fetchRebrandData(headers.Authorization))
            dispatch(fetchSocialAccounts(headers.Authorization))
            dispatch(getBrandingDataFromServer({ user_id: res.data.data.user_id }));

            dispatch(fetchRebrandData())
            dispatch(fetchUserCampaigns(headers.Authorization))
            dispatch(addMergeVideos(headers.Authorization))
        }
    }).catch(error => {
        dispatch(setAlert('Getting Internal Server Error', "danger"));
    })


}

export const masterLoginUser = (user, setLoader, history) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${baseURL}master-login`,
        data: user,
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {

        if (res.data.status === true) {
            dispatch({ type: 'LOGIN_USER', payload: res.data.data });

            history.push('/dashboard');

        } else {
            dispatch(setAlert(res.data.message, "danger"));
            setLoader(false)
        }

    }).catch(error => {
        dispatch(setAlert('Getting Internal Server Error', "danger"));
        setLoader(false)
    })
}


export const loginUser = (data, setLoader) => (dispatch) => {

    axios({
        method: 'POST',
        url: `${baseURL}login`,
        data: data,
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {

        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"));
            dispatch({ type: 'LOGIN_USER', payload: res.data.data });
            dispatch(addMergeVideos(res.data.data.token))

        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }

        setLoader(false);

    }).catch(error => {
        dispatch(setAlert('Getting Internal Server Error', "danger"));
        setLoader(false);
    })

}


export const logoutUser = () => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}logout`,
        data: {},
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"));
            dispatch({ type: 'LOGOUT' });
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }
    }).catch(error => {
        console.log(error);
    })
}


export const forgetPassword = (data, setLoader) => (dispatch) => {

    axios({
        method: 'POST',
        url: `${baseURL}forgot-password`,
        data: data,
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {

        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"));
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }

        setLoader(false);

    }).catch(error => {
        setLoader(false);
    })
}

export const resetPassword = (data, setLoader, history) => (dispatch) => {

    axios({
        method: 'POST',
        url: `${baseURL}reset-password`,
        data: data,
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {

        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"));
            history.push('/');
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }

        setLoader(false);

    }).catch(error => {

        setLoader(false);
    })
}


export const changeProfile = (data, setProfileStatus) => (dispatch, getState) => {

    axios({
        method: 'POST',
        url: `${baseURL}change-profile-image`,
        data: data,
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {

        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"));
            dispatch({ type: 'CHANGE_USER_IMAGE', payload: res.data });
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }
        setProfileStatus(false);

    }).catch(error => {
        setProfileStatus(false);
    })
}


export const updateName = (data, setLoader) => (dispatch, getState) => {

    axios({
        method: 'POST',
        url: `${baseURL}profile`,
        data: data,
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {

        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"));
            dispatch({ type: 'CHANGE_USER_NAME', action: data.name });
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }
        setLoader(false);

    }).catch(error => {
        setLoader(false);
    })

}


export const updatePassword = (data, setLoader, history) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}profile`,
        data: data,
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {

        if (res.data.status === true) {
            history.push("/login");
            dispatch(setAlert(res.data.message, "success"));
            dispatch({ type: 'LOGOUT' });
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }
        setLoader(false);

    }).catch(error => {
        setLoader(false);
    })
}


export const changeEmail = (data, setLoader) => (dispatch, getState) => {

    axios({
        method: 'POST',
        url: `${baseURL}change-email`,
        data: data,
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"));
            dispatch({ type: 'CHANGE_USER_EMAIL', action: data.email });
            dispatch(logoutUser())
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }
        setLoader(false);

    }).catch(error => {
        setLoader(false);
    })

}

export const gdprAction = (data, type, setShowDelete) => (dispatch, getState) => {

    axios({
        method: 'POST',
        url: `${baseURL}${type}`,
        data: data,
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {

        if (res.data.status === true) {

            if (type === 'download-user-data') {
                window.location.href = res.data.file;
            } else {
                window.location.reload();
            }

            dispatch(setAlert(res.data.message, "success"));
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }
        setShowDelete(false);

    }).catch(error => {
        dispatch(setAlert('Getting Internal Server Error', "danger"));
        setShowDelete(false);
    })
}
export const onFetchFont = (setFonts, toLoad) => (dispatch) => {
    const fontNotSet = ["Helvetica", "Bebas", "palatino linotype", "Subjectivity", "LEMON MILK", "Gill Sans Ultra Bold", "Gilroy", "MS Reference Sans Serif", "MS Serif"
        , "Microsoft JhengHei", "Times New Roman", "MS UI Gothic", "Bookman Old Style", "Franklin Gothic Book", "Gill Sans MT", "Eras Light ITC", "Microsoft Sans Serif", "Microsoft YaHei UI", "Helvetica Now Display", "Gadugi", "Tw Cen MT"
        , "Ebrima", "Subjectivity Serif", "Clarendon", "Segoe UI Emoji", "Fashion Fetish Heavy", "Bastian Sidney", "Palatino Linotype", "Integral CF", "Steelfish", "Monument Extended", "Metropolis", "SignPainter HouseScript", "ChunkFive", "Franklin Gothic", "Agency FB", "Marchelina Script", "Franklin Gothic Medium", "Nexa Bold", "Spartwell", "Spartwell"
        , "Akira Expanded", "Calibri", "Zeyada", "Droid Serif", "Droid Sans Mono", "Droid Sans"]

    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/fetch-fonts',
        data: {},
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            let arr = res.data.data.filter(curElem => {
                return !fontNotSet.includes(curElem.value)
            }).map((item) => {
                return (
                    item.value
                )
            })
            arr = arr.concat('Inter')

            arr = arr.sort((a, b) => {
                return a > b ? 1 : -1
            })
            if (toLoad) {
                WebFont.load({
                    google: {
                        families: arr
                    }
                })
            } else {
                setFonts(arr);
            }
        }
    }).catch(err => {
        console.log(err)
    })
}

export const onFetchWhiteLabelData = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-whitelabel-data", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: 'ADD_REBRAND', payload: res.data[0] })
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                fetch: false
            })
        })
} 