import styled from "styled-components";

export const Square4Style = styled.div`
  .flex-column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .flex-row-center {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .flex-row-left {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: left;
    justify-content: left;
  }

  .subflex-left {
    width: 100%;
    text-align: left;
  }

  .subflex-right {
    width: 100%;
    text-align: right;
  }

  .subflex-center {
    width: 100%;
    text-align: center;
  }
  .text-wrapper {
    white-space: pre-wrap;
  }
  /*.f-arial {*/
  /*	font-family: Arial;*/
  /*}*/

  /*.f-mvboli {*/
  /*	font-family: mvboli;*/
  /*}*/

  /*.f-nexalight {*/
  /*	font-family: nexalight;*/
  /*}*/

  /*.f-nexabold {*/
  /*	font-family: nexabold;*/
  /*}*/

  .f-cyan {
    color: #00fffc;
  }

  .f-cyan-dark {
    color: #00d1cf;
  }

  .f-blue {
    color: #005596;
  }

  .f-white {
    color: #ffffff;
  }

  .poster {
    position: relative;
    overflow: hidden !important;
  }

  .poster.nine-by-sixteen {
    width: 1080px;
    height: 1920px;
  }

  .poster.one-by-one {
    width: 310px;
    height: 310px;
  }

  .poster.nine-by-sixteen {
    width: 171px;
    height: 304px;
  }

  .poster.one-by-one .bg.four {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .poster.one-by-one .poster-content.four {
    position: absolute;
    bottom: 180px;
    right: 750px;
    width: 0;
    padding: 0;
    overflow: hidden;
  }

  .poster.one-by-one .poster-content.four .text-wrapper {
    width: 1200px;
    background-color: #005596;
    text-align: center;
    padding: 15px 0;
  }

  .poster.one-by-one .poster-content.four .text-wrapper div {
    font-size: 75px;
    line-height: 90px;
    margin: 0;
  }

  .poster.one-by-one .poster-content.four .text-wrapper div {
    font-size: 12px !important;
    line-height: 16px;
    padding: 0 !important;
    margin: 0 !important;
  }

  .poster.one-by-one .bg.four {
    height: 110%;
    width: 110%;
    top: -7%;
    background-position: -105px center;
  }

  .poster.one-by-one .poster-content.four {
    bottom: 35px;
    right: 56px;
  }

  .poster.one-by-one .poster-content.four .text-wrapper {
    width: 195px !important;
    padding: 3px 0;
  }
`;
