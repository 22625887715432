import React from "react";

import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";
import { Portait4Style } from "./css/Portrait4Style";
const square_1_1 = (props) => {
  return (
    <Portait4Style>
      <div className="container_two_four">
        <TemplateLogo logo={props.logo} />
        <TemplateWatermark watermark={props.watermark} />
        <div className="back-layer">
          <TemplateImage slideType={props.slideType} file={props.file} />
        </div>
        <div className="front-layer">
          <div className="f-inside-layer" />
        </div>
        <div className="text-wrappers">
          <TemplateText
            id={props.id}
            text={props.text}
            textStyle={props.textStyle}
            editStatus={props.editStatus}
            onInputSlideText={props.onInputSlideText}
          />
        </div>
      </div>
    </Portait4Style>
  );
};

export default square_1_1;
