import React, { useState } from "react";
import MediaAudio from "./MediaAudio";
import SonorityAudio from "./SonorityAudio";
import UploadAudio from "./UploadAudio";
import TtsAudio from "./TtsAudio";

const Audio = ({ selectedIndex, audioFile }) => {

    const [currentUrl, setCurrentUrl] = useState('');
    const [selectedAudio, setSelectedAudio] = useState(audioFile);

    return (
        <>
            <div className="audio-box-sec">
                <div className="row">
                    <div className="col-md-11 mx-auto ">
                        <div className="audio-box-menu">
                            <ul
                                className="nav mb-3 d-flex justify-content-between align-items-center py-1"
                                id="pills-tab"
                                role="tablist"
                            >
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        id="pills-audioMusic-tab"
                                        data-toggle="pill"
                                        href="#pills-audioMusic"
                                        role="tab"
                                        aria-controls="pills-audioMusic"
                                        aria-selected="true"
                                    >
                                        Library Audio
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        id="pills-tts-tab"
                                        data-toggle="pill"
                                        href="#pills-tts"
                                        role="tab"
                                        aria-controls="pills-tts"
                                        aria-selected="false"
                                    >
                                        Robo Voices
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        id="pills-audioLibrary-tab"
                                        data-toggle="pill"
                                        href="#pills-audioLibrary"
                                        role="tab"
                                        aria-controls="pills-audioLibrary"
                                        aria-selected="false"
                                    >
                                        Upload Audio
                                    </a>
                                </li>
                            </ul>
                            <div className="row mt-4 mx-0">
                                <div className="col-md-12">
                                    <span className="horigental-line" />
                                </div>
                            </div>
                            <div className="tab-content mt-3" id="pills-tabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="pills-audioMusic"
                                    role="tabpanel"
                                    aria-labelledby="pills-audioMusic-tab"
                                >
                                    <ul
                                        className="nav mb-3 d-flex justify-content-between align-items-center py-1"
                                        id="music-library-nav"
                                        role="tablist"
                                    >
                                        <li className="nav-item col-6 p-0">
                                            <a
                                                className="nav-link active"
                                                id="pills-lightwavy-tab"
                                                data-toggle="pill"
                                                href="#pills-lightwavy"
                                                role="tab"
                                                aria-controls="pills-lightwavy"
                                                aria-selected="true"
                                            >
                                                Library
                                            </a>
                                        </li>
                                        <li className="nav-item col-6 p-0">
                                            <a
                                                className="nav-link"
                                                id="pills-sonority-tab"
                                                data-toggle="pill"
                                                href="#pills-sonority"
                                                role="tab"
                                                aria-controls="pills-sonority"
                                                aria-selected="false"
                                            >
                                                Sonority
                                            </a>
                                        </li>
                                    </ul>
                                    <div
                                        className="tab-content music-library"
                                        id="pills-tabContent"
                                    >
                                        <div
                                            className="tab-pane scroll-wrapper lightwavy-wrapper fade show scroll-bar active"
                                            id="pills-lightwavy"
                                            role="tabpanel"
                                            aria-labelledby="pills-lightwavy-tab"
                                            style={{ marginBottom: "80px" }}
                                        >
                                            <MediaAudio currentUrl={currentUrl} setCurrentUrl={setCurrentUrl} selectedAudio={selectedAudio} setSelectedAudio={setSelectedAudio} />
                                        </div>
                                        <div
                                            className="tab-pane scroll-wrapper sonority-wrapper fade"
                                            id="pills-sonority"
                                            role="tabpanel"
                                            aria-labelledby="pills-sonority-tab"
                                        >
                                            <SonorityAudio currentUrl={currentUrl} setCurrentUrl={setCurrentUrl} selectedAudio={selectedAudio} setSelectedAudio={setSelectedAudio} />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="pills-tts"
                                    role="tabpanel"
                                    aria-labelledby="pills-tts-tab"
                                >
                                    <TtsAudio selectedIndex={selectedIndex} />
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="pills-audioLibrary"
                                    role="tabpanel"
                                    aria-labelledby="pills-audioLibrary-tab"
                                >
                                    <UploadAudio currentUrl={currentUrl} setCurrentUrl={setCurrentUrl} selectedIndex={selectedIndex} selectedAudio={selectedAudio} setSelectedAudio={setSelectedAudio} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(Audio);