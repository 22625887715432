import styled from "styled-components";

export const Portait7Style = styled.div`
  width:100%;
  height:100%;

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container4 {
    width:100%;
    height:100%;
    overflow: hidden;
  }

  .f-peach {
    color: #f98365;
  }

  .f-white {
    color: #fff;
  }

  .f-blue {
    color: #005596;
  }

  .front-layer {
    position: absolute;
    width:100%;
    height:100%;
    background: #fff;
    z-index: 1;
    transform: scale(2);
    transform-origin: center;
    clip-path: polygon(
            0 0,
            0 0,
            0 50%,
            0 100%,
            0 100%,
            50% 100%,
            100% 100%,
            100% 100%,
            100% 50%,
            100% 0,
            100% 0,
            50% 0
    );
  }

  .back-layer {
    width:100%;
    height:100%;
  }

  .back-layer img,
  .back-layer video {
    width:100%;
    height:100%;
    object-fit: cover;
    transform: scale(1.1);
    transform-origin: center;
  }

  .text-wrapper {
    width:100%;
    height:100%;
    top: 0;
    position: absolute;
    overflow: hidden;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text-wrapper > div {
    width:100%;
  }

`;
