import styled from "styled-components";

export const Regular7Style = styled.div`
  width: 100%;
  height: 100%;
  
  .container_two_saven .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container_two_saven {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #fbc5d0;
  }

  .f-orange {
    color: #fb4c1f;
  }

  .f-white {
    color: #fff;
  }

  .f-pink {
    color: #fbc5d0;
  }

  .container_two_saven div {
    font-family: "Poppins", sans-serif;
    line-height: 1.2;
    font-weight: 600;
    margin: 0;
  }

  .container_two_saven div:not(:last-child) {
    margin-bottom: 10px;
    background: #fb4c1f;
  }

  .container_two_saven .text-wrappers {
    position: absolute;
    bottom: 40px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction:column;
    align-items: center;
    max-width: 560px;
    padding: 0 20px;
    height: max-content;
    /* opacity: 0; */
  }

  .container_two_saven .text-wrappers div {
    font-size: 14px;
    display: block;
    padding: 2px 5px;
    left: -30%;
  }

  .container_two_saven .text-wrappers div span {
    left: -10%;
    opacity: 0;
  }

  .container_two_saven .text-wrappers div:last-child {
    font-size: 10px;
    width: 100%;
  }

  .container_two_saven .back-layer {
    width: 100%;
    height: 100%;
    /* background: url(../images/table.jpg);
  background-size: 105%;
  background-repeat: no-repeat;
  background-position: 100%; */
    /* transition: 8s ease; */
    opacity: 0.6;
  }

  .container_two_saven .back-layer img,
  .container_two_saven .back-layer video {
    width: 100%;
    object-fit: cover;
    transform: scale(1.2);
    left: 0;
  }
`;
