import styled from "styled-components";

export const Square7Style = styled.div`
  .flex-column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .flex-row-center {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .flex-row-left {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: left;
    justify-content: left;
  }

  .subflex-left {
    width: 100%;
    text-align: left;
  }

  .subflex-right {
    width: 100%;
    text-align: right;
  }

  .subflex-center {
    width: 100%;
    text-align: center;
  }
  .text-wrapper {
    white-space: pre-wrap;
  }
  /*.f-arial {*/
  /*	font-family: Arial;*/
  /*}*/

  /*.f-mvboli {*/
  /*	font-family: mvboli;*/
  /*}*/

  /*.f-nexalight {*/
  /*	font-family: nexalight;*/
  /*}*/

  /*.f-nexabold {*/
  /*	font-family: nexabold;*/
  /*}*/

  .f-cyan {
    color: #00fffc;
  }

  .f-cyan-dark {
    color: #00d1cf;
  }

  .f-blue {
    color: #005596;
  }

  .f-white {
    color: #ffffff;
  }

  .poster {
    position: relative;
    overflow: hidden !important;
  }

  .poster.nine-by-sixteen {
    width: 1080px;
    height: 1920px;
  }

  .poster.one-by-one {
    width: 310px;
    height: 310px;
  }

  .poster.nine-by-sixteen {
    width: 171px;
    height: 304px;
  }

  .poster.seven {
    background-color: #00d1cf;
  }

  .poster.one-by-one.seven .photo-container {
    position: absolute;
    left: -3800px;
    top: 0;
    height: 100%;
    width: 2100px;
  }

  .poster.one-by-one.seven .photo-outer {
    position: absolute;
    height: 2100px;
    width: 2100px;
    border-radius: 50%;
    border: 80px solid #005596;
    overflow: hidden;
    box-sizing: border-box;
  }

  .poster.one-by-one.seven .photo-inner {
    position: absolute;
    height: 2100px;
    width: 2100px;
    top: -90px;
    border-radius: 50%;
  }

  .poster.one-by-one.seven .text-wrapper {
    position: absolute;
    top: 10%;
    right: 0;
    height: 100%;
    width: calc(100% - 550px);
    padding-right: 125px;
  }

  .poster.one-by-one.seven .ta {
    font-size: 92px;
    line-height: 102px;
    margin: 0;
    opacity: 0;
  }

  .poster.one-by-one.seven .photo-container {
    left: -350px;
    top: 0;
    height: 100%;
    width: 100px;
  }

  .poster.one-by-one.seven .photo-outer {
    height: 350px;
    width: 350px;
    border: 15px solid #005596;
  }

  .poster.one-by-one.seven .photo-inner {
    height: 350px;
    width: 350px;
    top: -10px;
    background-position: 30px center;
  }

  .poster.one-by-one.seven .text-wrapper {
    width: calc(100% - 182px);
    padding-right: 15px;
  }

  .poster.one-by-one.seven .ta {
    font-size: 16px;
    line-height: 18px;
    /* font-weight: bold; */
  }

`;
