import styled from "styled-components";

export const Regular8Style = styled.div`
  width: 100%;
  height: 100%;
  
  .container_two_eight .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container_two_eight {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #fbc5d0;
  }

  .f-orange {
    color: #ff631d;
  }

  .f-white {
    color: #fff;
  }

  .f-pink {
    color: #fbc5d0;
  }
  .container_two_eight .back-layer{
    width: 100%;
    height: 100%;
  }

  .container_two_eight div {
    font-family: "Poppins", sans-serif;
    line-height: 1.2;
    font-weight: 600;
    margin: 0;
  
  }
 
  .container_two_eight .text-wrappers {
    position: absolute;
    bottom: 0;
    margin: auto;
    width: 100%;
    padding: 0 65px;
    height: max-content;
    top: 0;
  }

  .container_two_eight .text-wrappers .row {
    width: 100%;
  }

  .container_two_eight .text-wrappers .row {
    clip-path: polygon(50% 0%, 50% 0%, 50% 100%, 50% 100%);
    transition: 1s ease-in-out all;
  }

  .container_two_eight .text-wrappers .row-1 {
    background: #fb4c1f;
  }

  .container_two_eight .text-wrappers .row-2 {
    background: #ff95a9;
    max-width: max-content;
    margin: 10px auto 0;
  }

  .container_two_eight .text-wrappers .row-2 div {
    font-size: 12px;
  }

  .container_two_eight .text-wrappers div {
    font-size: 14;
    padding: 3px 5px;
    opacity: 1;
    text-align: center;
  }

  .container_two_eight .back-layer img,
  .container_two_eight .back-layer video {
    width: 100%;
    object-fit: cover;
    transform: scale(1.1);
  }
`;
