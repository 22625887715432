import React from "react";
import { Portait1Style } from "./css/Portrait1Style";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";

const Template = (props) => {
  return (
    <Portait1Style>
      <div className="container5">
        <TemplateLogo logo={props.logo} />
        <TemplateWatermark watermark={props.watermark} />
        <div className="back-layer">
          <TemplateImage slideType={props.slideType} file={props.file} />
        </div>

        <div className="text-wrapper">
          <TemplateText
              id={props.id}
              text={props.text}
              textStyle={props.textStyle}
              editStatus={props.editStatus}
              onInputSlideText={props.onInputSlideText}
          />
        </div>
      </div>
    </Portait1Style>
  );
};

export default Template;
