import styled from "styled-components";

export const Portait7Style = styled.div`
.flex-column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.flex-row-center {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.flex-row-left {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: left;
  justify-content: left;
}

.subflex-left {
  width: 100%;
  text-align: left;
}

.subflex-right {
  width: 100%;
  text-align: right;
}

.subflex-center {
  width: 100%;
  text-align: center;
}
.text-wrapper {
  white-space: pre-wrap;
}
/*.f-arial {*/
/*	font-family: Arial;*/
/*}*/

/*.f-mvboli {*/
/*	font-family: mvboli;*/
/*}*/

/*.f-nexalight {*/
/*	font-family: nexalight;*/
/*}*/

/*.f-nexabold {*/
/*	font-family: nexabold;*/
/*}*/

.f-cyan {
  color: #00fffc;
}

.f-cyan-dark {
  color: #00d1cf;
}

.f-blue {
  color: #005596;
}

.f-white {
  color: #ffffff;
}

.poster {
  position: relative;
  overflow: hidden !important;
}

.poster.nine-by-sixteen {
  width: 1080px;
  height: 1920px;
}

.poster.one-by-one {
  width: 310px;
  height: 310px;
}

.poster.nine-by-sixteen {
  width: 171px;
  height: 304px;
}

.poster.seven {
  background-color: #00d1cf;
}

.poster.nine-by-sixteen.seven .photo-container {
  position: absolute;
  left: -1400px;
  top: 0;
  height: 100%;
  width: 1200px;
}

.poster.nine-by-sixteen.seven .photo-outer {
  position: relative;
  height: 1200px;
  width: 1200px;
  border-radius: 50%;
  border: 45px solid #005596;
  overflow: hidden;
  box-sizing: border-box;
}

.poster.nine-by-sixteen.seven .photo-inner {
  position: absolute;
  height: 1200px;
  width: 1200px;
  top: -40px;
  border-radius: 50%;
}

.poster.nine-by-sixteen.seven .ta {
  font-size: 76px;
  line-height: 92px;
  margin: 0;
  opacity: 0;
  font-weight: bold;
}

.poster.nine-by-sixteen.seven .photo-container {
  left: -220px;
  top: 0;
  height: 100%;
  width: 180px;
}

.poster.nine-by-sixteen.seven .photo-outer {
  position: relative;
  height: 180px;
  width: 180px;
  border: 6px solid #005596;
}

.poster.nine-by-sixteen.seven .photo-inner {
  height: 200px;
  width: 200px;
  top: -10px;
  background-position: 8px center;
}

.poster.nine-by-sixteen.seven .text-wrapper {
  position: absolute;
  top: 0;
  left: 80px !important;
  height: 80%;
  width: calc(100% - 85px);
  bottom: 0;
  margin: auto;
}

.poster.nine-by-sixteen.seven .ta {
  font-size: 12px;
  line-height: 15px;
}
`;
