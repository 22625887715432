import styled from "styled-components";

export const Portait8Style = styled.div`
  width: 100%;
  height: 100%;

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container5{
    width: 100%;
    height: 100%;
    overflow: hidden;
  }



  .text-wrapper div {
    font-family: "Poppins", sans-serif;
    line-height: 1em;
    font-weight: 500;
    margin: 0;
  }

  .back-layer {
    width: 100%;
    height: 100%;
    background: #002a29;
  }

  .back-layer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
    transform: scale(1.1);
    transform-origin: center;
    top: 5%;
  }

  .text-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 5%;
    background: #007a7995;
  }

  .text-wrapper > div {
    
  }

`;
