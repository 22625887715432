import styled from "styled-components";

export const Regular1Style = styled.div`
  width:100%;
  height:100%;

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container {
    width:100%;
    height:100%;
    overflow: hidden;
  }

  .f-blue-haze {
    color: #c7cedf;
  }

  .f-white {
    color: #fff;
  }

  .text-wrapper div {
    font-family: "Poppins", sans-serif;
    line-height: 1em;
    font-weight: 500;
    margin: 0;
  }

  .back-layer {
    width:100%;
    height:100%;
  }

  .back-layer img {
    width:100%;
    height:100%;
    object-fit: cover;
  }

  

  .text-wrapper {
    top: 0;
    position: absolute;
    width:100%;
    height:100%;
    overflow: hidden;
    padding: 5% 5% 5% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text-wrapper > div {
    width:100%;
  }

  
`;
