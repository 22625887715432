import styled from "styled-components";

export const Regular5Style = styled.div`
  width: 100%;
  height: 100%;
  
  .container_two_five .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container_two_five {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .f-orange {
    color: #ff7b3f;
  }

  .f-white {
    color: #fff;
  }

  .f-pink {
    color: #fbc5d0;
  }

  .container_two_five div {
    font-family: "Poppins", sans-serif;
    line-height: 1.2;
    font-weight: 600;
    margin: 0;
  }

  .container_two_five .back-layer {
    width: 100%;
    height: 100%;
    background: #fe793d;
  }

  .container_two_five .back-layer img,
  .container_two_five .back-layer video {
    opacity: 0.5;
    width: 100%;
    object-fit: cover;
  }

  .container_two_five .text-wrappers {
    position: absolute;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    left: 0;
    padding: 0 20px;
    height: max-content;
    opacity: 0;
    bottom: 5%;
  }

  .container_two_five .text-wrappers div {
    font-size: 18px;
    flex: 0 0 100%;
    text-align: right;
  }

  .container_two_five .text-wrappers div:last-child {
    font-size: 12px;
    margin-top: 7px;
  }

  .text-wrappers {
    position:absolute;
    width:100%;
    display: flex;
    align-items: baseline;
    justify-content: end;
  }
  .text-wrappers > div {
    width:100%;
    overflow: hidden;
  }
`;
