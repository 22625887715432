import React from "react";
import { Portait5Style } from "./css/Portrait5Style";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";

const Template = (props) => {
  return (
    <Portait5Style>
      <div className="template-wrapper">
        <div className="poster five nine-by-sixteen">
          <TemplateLogo logo={props.logo} />
          <TemplateWatermark watermark={props.watermark} />
          <div className="sidepanel five flex-row-left">
            <div className="photo-container">
              <div className="photo">
                <TemplateImage slideType={props.slideType} file={props.file} />
              </div>
            </div>
          </div>
          <div className="text-wrapper flex-row-left flex-column">
            <TemplateText
              id={props.id}
              text={props.text}
              textStyle={props.textStyle}
              editStatus={props.editStatus}
              onInputSlideText={props.onInputSlideText}
            />
          </div>
        </div>
      </div>
    </Portait5Style>
  );
};

export default Template;
