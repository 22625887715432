import {init} from "./common";
import {gsap,Power2,Power3,Power4,Linear} from "gsap";
export const regular_3_1 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0}, {autoAlpha: 1}, 1);
    localTimeline.to('.back-layer', { duration: 5, scale:1.3,ease: Linear.easeNone},1);
    return localTimeline;
}
export const regular_3_2 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.back-layer', { duration: 0.5, clipPath:"ellipse(55% 50% at 50% 17%)"},1);
    localTimeline.to('.back-layer img', {duration: 0.5, css:{objectPosition:"50% 120%"}},1)
    localTimeline.to('.back-layer img', {duration: 5, scale:1.2,ease: Linear.easeNone},1)
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0},{autoAlpha:1, duration: 1, stagger: 0.6}, +2);
    return localTimeline;
}

export const regular_3_3 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.back-layer', { duration: 5, scale:1.2},1);
    localTimeline.fromTo('.text-wrapper div', {marginRight:'200%'}, { stagger:0.2, duration: 2, marginRight:0},1);
    return localTimeline;
}

export const regular_3_4 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.back-layer', { duration: 1, clipPath:"ellipse(55% 80% at 50% 100%)"},1);
    localTimeline.to('.back-layer img', {duration: 5, css:{objectPosition:"50% 0%"}},1)
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0}, {duration: 1, autoAlpha:1, stagger: 0.2},+2)


    return localTimeline;
}

export const regular_3_5 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0},{autoAlpha:1,duration: 1, stagger: 0.6}, 1);

    localTimeline.to('.circle', { duration: 1, clipPath:"circle(36% at 50% 50%)"},1);
    localTimeline.to('.circle img', {duration: 5, css:{objectPosition:"45% 50%"}},1)
    return localTimeline;
}

export const regular_3_6 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.back-layer', { duration: 3, scale:1.2,},1);
    localTimeline.to('.front-layer', { duration: 1, top: 0},1);
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0},{autoAlpha:1,duration: 1, stagger: 0.6},+2);
    return localTimeline;
}

export const regular_3_7 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.back-layer', { duration: 3, scale:1.2,ease: Linear.easeNone},1);
    localTimeline.to('.front-layer', { duration: 1.5, top: 0},1);
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0},{autoAlpha:1,duration: 1, stagger: 0.6},+2);
    return localTimeline;
}
export const regular_3_8 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0},{duration: 1, autoAlpha:1, stagger: 0.6}, 1);
    localTimeline.to('.circle', { duration: 1, clipPath:"circle(36% at 50% 50%)"},1);
    localTimeline.to('.circle img', {duration: 5, css:{objectPosition:"55% 50%"}},1)
    return localTimeline;
}

export const square_3_1 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0}, {autoAlpha: 1}, 1);
    localTimeline.to('.back-layer', { duration: 5, scale:1.3,ease: Linear.easeNone},1);
    return localTimeline;
}
export const square_3_2 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.back-layer', { duration: 0.5, clipPath:"ellipse(83% 70% at 50% 20%)"},1);
    localTimeline.to('.back-layer img', {duration: 0.5, css:{objectPosition:"50% 120%"}},1)
    localTimeline.to('.back-layer img', {duration: 5, scale:1.2,ease: Linear.easeNone},1)
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0},{autoAlpha:1, duration: 1, stagger: 0.6}, +2);
    return localTimeline;
}

export const square_3_3 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.back-layer', { duration: 5, scale:1.2},1);
    localTimeline.fromTo('.text-wrapper div', {marginRight:'200%'}, { stagger:0.2, duration: 2, marginRight:0},1);
    return localTimeline;
}

export const square_3_4 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.back-layer', { duration: 1, clipPath:"ellipse(83% 90% at 50% 100%)"},1);
    localTimeline.to('.back-layer img', {duration: 5, css:{objectPosition:"50% 0%"}},1)
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0}, {duration: 1, autoAlpha:1, stagger: 0.2},+2)
    return localTimeline;
}

export const square_3_5 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0},{autoAlpha:1,duration: 1, stagger: 0.6}, 1);

    localTimeline.to('.circle', { duration: 1, clipPath:"circle(36% at 50% 50%)"},1);
    localTimeline.to('.circle img', {duration: 5, css:{objectPosition:"45% 50%"}},1)
    return localTimeline;
}

export const square_3_6 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.back-layer', { duration: 3, scale:1.2,},1);
    localTimeline.to('.front-layer', { duration: 1, top: 0},1);
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0},{autoAlpha:1,duration: 1, stagger: 0.6},+2);
    return localTimeline;
}

export const square_3_7 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.back-layer', { duration: 3, scale:1.2,ease: Linear.easeNone},1);
    localTimeline.to('.front-layer', { duration: 1.5, top: 0},1);
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0},{autoAlpha:1,duration: 1, stagger: 0.6},+2);
    return localTimeline;
}
export const square_3_8 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0},{duration: 1, autoAlpha:1, stagger: 0.6}, 1);
    localTimeline.to('.circle', { duration: 1, clipPath:"circle(36% at 50% 50%)"},1);
    localTimeline.to('.circle img', {duration: 5, css:{objectPosition:"55% 50%"}},1)
    return localTimeline;
}

export const story_3_1 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.bg', {scale: 1.2, ease:Power3.easeOut, duration: 4}, 1)
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0}, {autoAlpha:1, ease:Power3.easeOut, duration: 1},+2)


    return localTimeline;
}
export const story_3_2 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.back-layer', { duration: 0.5, clipPath:"ellipse(100% 67% at 50% 20%)"},1);
    localTimeline.to('.back-layer img', {duration: 0.5, css:{objectPosition:"50% 120%"}},1)
    localTimeline.to('.back-layer img', {duration: 3, scale:1.2,ease: Linear.easeNone},1)
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0},{autoAlpha:1, duration: 1, stagger: 0.6}, +2);
    return localTimeline;
}

export const story_3_3 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.back-layer', { duration: 5, scale:1.2},1);
    localTimeline.fromTo('.text-wrapper div', {marginRight:'200%'}, { stagger:0.2, duration: 2, marginRight:0},1);
    return localTimeline;
}

export const story_3_4 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.fromTo('.back-layer',{clipPath:"ellipse(95% 0% at 50% 100%)"}, { duration: 1, clipPath:"ellipse(95% 95% at 50% 100%)"},1);
    localTimeline.to('.back-layer img', {duration: 5, css:{objectPosition:"50% 0%"}},1)
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0}, {duration: 1, autoAlpha:1, stagger: 0.2},+2)

    return localTimeline;
}

export const story_3_5 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0},{autoAlpha:1,duration: 1, stagger: 0.6}, 1);

    localTimeline.to('.circle', { duration: 1, clipPath:"circle(48% at 50% 50%)"},1);
    localTimeline.to('.circle img', {duration: 5, css:{objectPosition:"45% 50%"}},1)
    return localTimeline;
}

export const story_3_6 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.back-layer', { duration: 3, scale:1.2,},+1);
    localTimeline.to('.front-layer', { duration: 1, top: 0},+1);
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0},{autoAlpha:1,duration: 1, stagger: 0.6},+2);
    return localTimeline;
}

export const story_3_7 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.back-layer', { duration: 3, scale:1.2,ease: Linear.easeNone},1);
    localTimeline.to('.front-layer', { duration: 1.5, top: 0},1);
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0},{autoAlpha:1,duration: 1, stagger: 0.6},+2);
    return localTimeline;
}
export const story_3_8 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0},{duration: 1, autoAlpha:1, stagger: 0.6}, 1);
    localTimeline.to('.circle', { duration: 1, clipPath:"circle(36% at 50% 50%)"},1);
    localTimeline.to('.circle img', {duration: 5, css:{objectPosition:"55% 50%"}},1)
    return localTimeline;
}