import styled from "styled-components";

export const Portait5Style = styled.div`
width:100%;
height:100%;

.img-fluid {
  display: block;
  max-width: 100%;
}

.container9{
  width:100%;
height:100%;
  overflow: hidden;
}


.text-wrapper div {
  width:100%;
  text-align:center !important;
}

.back-layer {
  width:100%;
  height:100%;
}

.yellow-bar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.yellow-wrap {
  width: 78%;
  height: 5vh;
  position: absolute;
  z-index: 1;
}

.yellow-bar .top {
  top: 0%;
  left: 0;
}

.yellow-bar .bottom {
  bottom: 0%;
  right: 0;
}

.yellow-bar .bar {
  height: 0.7vh;
}

.yellow-bar .yellow {
  background: #FFF000;
  width: 50%;
}

.yellow-bar .corn {
  background: #DFD200;
  width:87%;
}

.yellow-bar .bottom .yellow {
  float: right;
  width:87%;
}

#banner-vid {
  width:100%;
height:100%;
  object-fit: cover;
  object-position: center;    
}

.text-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  overflow: hidden;
  width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
}

.text-wrapper .wrap {
  position:absolute;
  top: 4%;
  bottom: 0;
  background: #00000080;    
  width: 100%;
  height:81%;
  padding: 2% 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  clip-path: polygon(0% 50%, 100% 50%, 100% 50%, 0% 50%);
}

.inner-wrap {
  transform: scale(2.5);
}

.text-wrapper p.desc {
  font-size: 4vw;    
}

.text-wrapper p.desc:not(:last-child) {
  margin-bottom: 1.5vh;
}

.text-wrapper p.presented {
  font-size: 3vw;
  margin-top: 3vh;
}
`;
