import React, { useEffect, useState } from "react";
import icon from "../../images/ICON.png"
import icon2 from "../../images/ICON2.png"
import icon3 from "../../images/ICON3.png"
import group56 from "../../images/Group 56.png"
import image3 from "../../images/Mask-Group-3.png"
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { addScrapText } from "../../actions/videoAction";
import { removeAlert } from "../../actions/alert";
import { addCampaignUrl } from "../../actions/scriptAction";
import closeModalx from "../../images/closeModal.svg"
import { appName } from "../../global/global";

const DashboardHead = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand);

    const [rebrand, setRebrand] = useState(rebrandData);
    const [loader, setLoader] = useState(false);
    const [frameUrl, setFrameUrl] = useState('');
    const [showModal, setShowModal] = useState(false);

    const [blogUrl, setBlogUrl] = useState('');
    const [eComUrl, setEcomUrl] = useState('');

    const [blog, setBlog] = useState(false);
    const [ecom, setEcom] = useState(false);

    const showBlog = () => {
        setBlog(true);
    }
    const showEcom = () => {
        setEcom(true);
    }


    const addFrameUrl = () => {
        setShowModal(true);
        setFrameUrl('https://player.vimeo.com/video/608769128');
    }

    const closePopUp = () => {
        setFrameUrl('');
        setShowModal(false);
    }



    const onFormSubmit = (e, type) => {
        e.preventDefault();
        if (eComUrl || blogUrl) {


            setLoader(true);

            // let url = type === 'ecom' ? "http://170.187.237.167:5002/ecommerce-scraper" : 'http://170.187.237.167:5002/website-scraper';
            let data = {
                url: type === 'ecom' ? eComUrl : blogUrl,
                id: auth.user.id,
                type: type,
                proxy: ""
            }

            /** save blog or ecom url */
            if (blogUrl !== '') {
                dispatch(addCampaignUrl(blogUrl));
            }
            if (eComUrl !== '') {
                dispatch(addCampaignUrl(eComUrl));
            }

            axios({
                method: 'POST',
                url: "https://backend.clipsreel.io/website-scrapper",
                headers: { 'Content-Type': 'application/json', 'Authorization': auth.token },
                data: data

            }).then((res) => {
                if (res.data.status) {
                    dispatch(addScrapText(res.data.data));
                    history.push(`add-script?type=${type}`);
                    // }
                    setBlog(false);
                    setEcom(false);
                    setLoader(false);
                }
            }).catch(err => {
                setLoader(false);
            })
        }
    }

    useEffect(() => {
        setRebrand(rebrandData);
        dispatch(removeAlert());
    }, [rebrandData])

    return (
        <>
            <section className="bashboard-banner" >
                <div id="bg-video" >
                    {/* <iframe src="https://player.vimeo.com/video/603946529?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0"
                        frameBorder="0" title="course"></iframe> */}
                    <video style={{ width: "100%", height: "100%", objectFit: "cover" }} autoPlay muted loop>
                        <source src="https://reeelapps-app.s3.us-west-2.amazonaws.com/clipsreel2_dashboard_bg_video1+(1080p).mp4" />
                    </video>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-7">
                            <div className="banner-content text-white" style={{ paddingBottom: "30px" }}>
                                <h4>WELCOME TO </h4>
                                <h1>   {rebrandData?.data?.name ? rebrandData?.data?.name : appName}!</h1>
                                {/* {
                                    rebrand.data === false ? <h1><span>CLIPS</span>REEL</h1> : <h1>{rebrand.data.name}</h1>
                                } */}
                                <p>Create beautifully animated videos in seconds. See how {rebrand.data === false ? appName : rebrand.data.name} uses its powerful AI
                                    to convert any keyword or URL to a profitable animated video in any niche.</p>
                                <div className="watch-demo-btn mt-5">
                                    <button onClick={(e) => addFrameUrl()} type="button" data-toggle="modal" data-target="#myModal"><span>
                                        <i className="fas fa-play"></i></span> Watch Demo Video
                                    </button>
                                </div>

                                <div className={`commonModal modal ${showModal ? 'show-modal' : ''} `}>
                                    <div className="modal-dialog modal-dialog-centered mx-auto" role="document">
                                        <div className="modal-content">

                                            {/* <h5 className="modal-title"></h5> */}
                                            <button onClick={(e) => closePopUp('')} type="button" className="close" data-dismiss="modal"
                                                aria-label="Close">
                                                <img src={closeModalx} />
                                            </button>

                                            <div className="modal-body px-0 mx-4">
                                                <div className="embed-responsive embed-responsive-16by9">
                                                    <iframe id="frameSrc" className="embed-responsive-item" src={frameUrl} allowFullScreen=""></iframe>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="col-md-12 col-lg-4 offset-lg-1 p-lg-0">
                            {
                                auth.user.is_client_account == "0" ?
                                    <div className="banner-items" style={{ paddingBottom: 30 }}>
                                        <Link to="/add-script?type=content">
                                            <div className="banner-items-list">
                                                <div className="row">
                                                    <div className="col-md-3 col-sm-3 col-3 d-flex align-items-center">
                                                        <img src={icon} alt="icon" />
                                                    </div>
                                                    <div className="col-md-7 col-sm-7 col-7 d-flex align-items-center">
                                                        <p className="m-0">COPY & PASTE YOUR CONTENT</p>
                                                    </div>
                                                    <div className="col-md-2 col-sm-2 col-2 d-flex align-items-center">
                                                        <span><img src={group56} alt="right-icon" /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                        <Link onClick={(e) => showBlog()} data-toggle="modal" data-target="#MyBlog">
                                            <div className="banner-items-list bg2 mt-4">
                                                <div className="row">
                                                    <div className="col-md-3 col-sm-3 col-3 d-flex align-items-center">
                                                        <img src={icon2} alt="icon" />
                                                    </div>
                                                    <div className="col-md-7 col-sm-7 col-7 d-flex align-items-center">
                                                        <p className="m-0">BLOG POST OR ARTICLES</p>
                                                    </div>
                                                    <div className="col-md-2 col-sm-2  col-2 d-flex align-items-center">
                                                        <span><img src={group56} alt="right-icon" /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                        <Link onClick={(e) => showEcom()} data-toggle="modal" data-target="#MyEcommercepop">
                                            <div className="banner-items-list bg3 mt-4">
                                                <div className="row">
                                                    <div className="col-md-3 col-sm-3 col-3 d-flex align-items-center">
                                                        <img src={icon3} alt="icon" />
                                                    </div>
                                                    <div className="col-md-7 col-sm-7 col-7 d-flex align-items-center">
                                                        <p className="m-0">E-COMMERCE DETAILS PAGE</p>
                                                    </div>
                                                    <div className="col-md-2 col-sm-2 col-2 d-flex align-items-center">
                                                        <span><img src={group56} alt="right-icon" /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                    </div>
                                    : ''
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-10 offset-lg-1">
                            <a href="https://clipsreel.io/sales/" target="_blank" className="my-img-wrapper">
                                <img src="https://app.clipsreel.io/img/clipsreel3.jpg" alt="clipsReel Coupon" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <div className={`modal ${blog ? 'show-modal' : ''}`} >
                <div className="modal-dialog modal-dialog-centered  ecommercebox " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-center mx-auto ">Blog Post</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                {/* <span aria-hidden="true" style={{color:"#fff"}}>
                                    <i className="fas fa-times-circle"></i></span> */}
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="modal-scrollbar">

                                <form className="form-group text-center" method="post" onSubmit={(e) => onFormSubmit(e, 'blog')}>

                                    <input type="url" onChange={(e) => setBlogUrl(e.target.value)} className="form-control" placeholder={`Enter Blog Post URL`} required />
                                    <button type="button" onClick={(e) => setBlog(false)} className="mt-4 mr-5 btn-change7" disabled={loader}>
                                        Cancel
                                    </button>
                                    <button type="submit" className="mt-4 btn-change7" disabled={loader}>
                                        {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Create
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal ${ecom ? 'show-modal' : ''}`} >
                <div className="modal-dialog  modal-dialog-centered ecommercebox " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-center mx-auto ">E-COMMERCE</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                {/* <span aria-hidden="true" style={{color:"#fff"}}>
                                    <i className="fas fa-times-circle"></i></span> */}
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="modal-scrollbar">

                                <form method="post" className="form-group text-center" onSubmit={(e) => onFormSubmit(e, 'ecom')}>

                                    <input type="url" onChange={(e) => setEcomUrl(e.target.value)} className="form-control" placeholder={`Enter Product URL`} required />
                                    <button type="button" onClick={(e) => setEcom(false)} className="mt-4 mr-5 btn-change7" disabled={loader}>
                                        Cancel
                                    </button>
                                    <button type="submit" className="mt-4 btn-change7" disabled={loader}>
                                        {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Create
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardHead;