import styled from "styled-components";

export const Regular4Style = styled.div`

width: 100%;
height: 100%;

  .img-fluid {
    display: block;
    max-width: 100%;
}

.container7 {
  width: 100%;
  height: 100%;
    overflow: hidden;
}

.back-layer {
  width: 100%;
  height: 100%;
}

.back-layer img,
 .back-layer video {
  width: 100%;
  height: 100%;
    object-fit: cover;
    object-position: center;
}

.text-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: #fff;
    clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
}

.inside-wrap {
    clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
}

.text-wrapper > div {
  width:100%;
  color:#eb4f43;
}

`;
