import { init } from "./common";
import { gsap, Power1, Power2, Power3, Power4, Linear } from "gsap";

export const regular_6_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 5, left: "-5%" }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginTop: "-20%" },
    { duration: 1, autoAlpha: 1, marginTop: "0" },
    +2
  );
  return localTimeline;
};
export const regular_6_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".two-layer-bg .col-1",
    { duration: 0.8, maxWidth: "50%" },
    1
  );
  localTimeline.fromTo(
    ".front-layer .img-wrapper",
    { left: "200%" },
    { duration: 1, left: "0%" },
    1.5
  );
  localTimeline.fromTo(
    ".front-layer img",
    { left: "-100%" },
    { duration: 5, left: "0%" },
    2
  );
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0, marginRight: "-250px" },
    { marginRight: "0", duration: 1.7, autoAlpha: 1 },
    2
  );

  return localTimeline;
};

export const regular_6_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".two-layer-bg .col-1", { duration: 0.8, maxWidth: "50%" });
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginLeft: "-200px" },
    { marginLeft: "0", autoAlpha: 1, stagger: 0.1 },
    0.4
  );
  localTimeline.fromTo(
    ".col-2 .wrap",
    { autoAlpha: 0 },
    {
      autoAlpha: 1,
      duration: 0.8,
      left: "0vw",
      onComplete() {
        localTimeline.to(".col-2 .wrap img-wrappper img", {
          duration: 5,
          objectPosition: "70% 50%",
        });
      },
    }
  );
  return localTimeline;
};

export const regular_6_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".two-layer-bg .col-1", { duration: 0.8, maxWidth: "50%" });
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginRight: "-200px" },
    { marginRight: "0vw", autoAlpha: 1, stagger: 0.1 },
    0.7
  );
  localTimeline.fromTo(
    ".col-1 .wrap",
    { autoAlpha: 0 },
    {
      duration: 0.8,
      left: "0vw",
      autoAlpha: 1,
      onComplete() {
        localTimeline.to(".col-1 .wrap .img-wrapper img", {
          duration: 5,
          objectPosition: "70% 50%",
        });
      },
    }
  );
  return localTimeline;
};

export const regular_6_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".two-layer-bg .col-1", { duration: 0.8, maxWidth: "20%" });
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { autoAlpha: 1, stagger: 0.7 },
    0.4
  );
  localTimeline.to(".col-1 .wrap", {
    duration: 0.8,
    left: "0vw",
    onComplete() {
      localTimeline.to(".col-1 .wrap .img-wrapper img", {
        duration: 5,
        objectPosition: "70% 50%",
      });
    },
  });
  return localTimeline;
};

export const regular_6_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".two-layer-bg .col-1", { duration: 0.8, maxWidth: "80%" });
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { autoAlpha: 1, stagger: 0.7 },
    0.4
  );
  // localTimeline.to(".col-2 .wrap", { opacity: 1 });
  localTimeline.fromTo(
    ".col-2 .wrap",
    { autoAlpha: 0 },
    {
      duration: 1,
      left: "0vw",
      autoAlpha: 1,
      onComplete() {
        localTimeline.to(".col-2 .wrap .img-wrapper img", {
          duration: 5,
          objectPosition: "70% 50%",
        });
      },
    }
  );
  return localTimeline;
};

export const regular_6_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 0.5, left: "-5%" });
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginTop: "-200px" },
    {
      duration: 1,
      autoAlpha: 1,
      top: 0,
      marginTop: 0,
      onComplete() {
        localTimeline.to(".text-wrapper div", {
          duration: 0.8,
          top: 0,
          onComplete() {
            localTimeline.to(".text-wrapper div", {
              duration: 0.8,
              autoAlpha: 1,
              top: 0,
            });
          },
        });
      },
    }
  );
  return localTimeline;
};
export const regular_6_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".back-layer img", { duration: 0.5, left: "-5%" });
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginTop: "-200px" },
    {
      duration: 1,
      autoAlpha: 1,
      top: 0,
      marginTop: 0,
      onComplete() {
        localTimeline.to(".text-wrapper div", {
          duration: 0.8,
          top: 0,
          onComplete() {
            localTimeline.to(".text-wrapper div", {
              duration: 0.8,
              autoAlpha: 1,
              top: 0,
            });
          },
        });
      },
    }
  );
  return localTimeline;
};

export const square_6_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 5, left: "-5%" }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginTop: "-20%" },
    { duration: 1, autoAlpha: 1, marginTop: "0" },
    +2
  );
  return localTimeline;
};

export const square_6_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".two-layer-bg .col-1",
    { duration: 0.8, maxWidth: "50%" },
    1
  );
  localTimeline.fromTo(
    ".front-layer .img-wrapper",
    { left: "200%" },
    { duration: 1, left: "0%" },
    1.5
  );
  localTimeline.fromTo(
    ".front-layer img",
    { left: "-100%" },
    { duration: 5, left: "0%" },
    2
  );
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0, marginRight: "-250px" },
    { marginRight: "0", duration: 1, autoAlpha: 1 },
    2
  );

  return localTimeline;
};

export const square_6_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".two-layer-bg .col-1", { duration: 0.8, maxWidth: "50%" });
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginLeft: "-200px" },
    { marginLeft: "0", autoAlpha: 1, stagger: 0.1 },
    0.4
  );
  localTimeline.fromTo(
    ".col-2 .wrap",
    { autoAlpha: 0 },
    {
      autoAlpha: 1,
      duration: 0.8,
      left: "0vw",
      onComplete() {
        localTimeline.to(".col-2 .wrap img-wrappper img", {
          duration: 5,
          objectPosition: "70% 50%",
        });
      },
    }
  );
  return localTimeline;
};

export const square_6_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".two-layer-bg .col-1", { duration: 0.8, maxWidth: "50%" });
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginRight: "-200px" },
    { marginRight: "0vw", autoAlpha: 1, stagger: 0.1 },
    0.7
  );
  localTimeline.fromTo(
    ".col-1 .wrap",
    { autoAlpha: 0 },
    {
      duration: 0.8,
      left: "0vw",
      autoAlpha: 1,
      onComplete() {
        localTimeline.to(".col-1 .wrap .img-wrapper img", {
          duration: 5,
          objectPosition: "70% 50%",
        });
      },
    }
  );
  return localTimeline;
};

export const square_6_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".two-layer-bg .col-1", { duration: 0.8, maxWidth: "20%" });
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { autoAlpha: 1, stagger: 0.7 },
    0.4
  );
  localTimeline.to(".col-1 .wrap", {
    duration: 0.8,
    left: "0vw",
    onComplete() {
      localTimeline.to(".col-1 .wrap .img-wrapper img", {
        duration: 5,
        objectPosition: "70% 50%",
      });
    },
  });
  return localTimeline;
};

export const square_6_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".two-layer-bg .col-1", { duration: 0.8, maxWidth: "80%" });
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { autoAlpha: 1, stagger: 0.7 },
    0.4
  );
  // localTimeline.to(".col-2 .wrap", { opacity: 1 });
  localTimeline.fromTo(
    ".col-2 .wrap",
    { autoAlpha: 0 },
    {
      duration: 1,
      left: "0vw",
      autoAlpha: 1,
      onComplete() {
        localTimeline.to(".col-2 .wrap .img-wrapper img", {
          duration: 5,
          objectPosition: "70% 50%",
        });
      },
    }
  );
  return localTimeline;
};

export const square_6_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".back-layer img", { duration: 3, left: "-5%" }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginTop: "-20%" },
    { duration: 1, autoAlpha: 1, marginTop: "0" },
    +2
  );
  return localTimeline;
};
export const square_6_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".front-layer img", { duration: 5, left: "0%" }, +1);
  localTimeline.fromTo(
    ".text-wrapper",
    { marginTop: "-200px", autoAlpha: 0 },
    { duration: 2, autoAlpha: 1, marginTop: "0" },
    +1.5
  );
  return localTimeline;
};

export const story_6_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 3, left: "-5%" }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginTop: "-20%" },
    { duration: 1, autoAlpha: 1, marginTop: "0" },
    +2
  );
  return localTimeline;
};
export const story_6_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".two-layer-bg .col-1",
    { duration: 0.8, maxWidth: "50%" },
    1
  );
  localTimeline.fromTo(
    ".front-layer .img-wrapper",
    { left: "200%" },
    { duration: 1, left: "0%" },
    1.5
  );
  localTimeline.fromTo(
    ".front-layer img",
    { left: "-100%" },
    { duration: 5, left: "0%" },
    2
  );
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0, marginRight: "-250px" },
    { marginRight: "0", duration: 1, autoAlpha: 1 },
    2
  );

  return localTimeline;
};

export const story_6_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".two-layer-bg .col-1", { duration: 0.8, maxWidth: "50%" });
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginLeft: "-200px" },
    { marginLeft: "0", autoAlpha: 1, stagger: 0.1 },
    0.4
  );
  localTimeline.fromTo(
    ".col-2 .wrap",
    { autoAlpha: 0 },
    {
      autoAlpha: 1,
      duration: 0.8,
      left: "0vw",
      onComplete() {
        localTimeline.to(".col-2 .wrap img-wrappper img", {
          duration: 5,
          objectPosition: "70% 50%",
        });
      },
    }
  );
  return localTimeline;
};

export const story_6_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".two-layer-bg .col-1", { duration: 0.8, maxWidth: "50%" });
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginRight: "-200px" },
    { marginRight: "0vw", autoAlpha: 1, stagger: 0.1 },
    0.7
  );
  localTimeline.fromTo(
    ".col-1 .wrap",
    { autoAlpha: 0 },
    {
      duration: 0.8,
      left: "0vw",
      autoAlpha: 1,
      onComplete() {
        localTimeline.to(".col-1 .wrap .img-wrapper img", {
          duration: 5,
          objectPosition: "70% 50%",
        });
      },
    }
  );
  return localTimeline;
};

export const story_6_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".two-layer-bg .col-1", { duration: 0.8, maxWidth: "20%" });
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { autoAlpha: 1, stagger: 0.7 },
    0.4
  );
  localTimeline.to(".col-1 .wrap", {
    duration: 0.8,
    left: "0vw",
    onComplete() {
      localTimeline.to(".col-1 .wrap .img-wrapper img", {
        duration: 5,
        objectPosition: "70% 50%",
      });
    },
  });
  return localTimeline;
};

export const story_6_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".two-layer-bg .col-1", { duration: 0.8, maxWidth: "80%" });
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { autoAlpha: 1, stagger: 0.7 },
    0.4
  );
  // localTimeline.to(".col-2 .wrap", { opacity: 1 });
  localTimeline.fromTo(
    ".col-2 .wrap",
    { autoAlpha: 0 },
    {
      duration: 1,
      left: "0vw",
      autoAlpha: 1,
      onComplete() {
        localTimeline.to(".col-2 .wrap .img-wrapper img", {
          duration: 5,
          objectPosition: "70% 50%",
        });
      },
    }
  );
  return localTimeline;
};

export const story_6_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 3, left: "-5%" }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginTop: "-20%" },
    { duration: 1, autoAlpha: 1, marginTop: "0" },
    +2
  );
  return localTimeline;
};
export const story_6_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".front-layer img", { duration: 5, left: "0%" }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginTop: "-200px" },
    {
      duration: 0.8,
      autoAlpha: 1,
      left: 0,
      marginTop: "0",
    },
    1
  );

  localTimeline.to(
    ".text-wrapper div",
    {
      duration: 0.4,
      opacity: 1,
      top: "0vw",
    },
    +2
  );

  localTimeline.to(
    "text-wrapper",
    {
      duration: 0.5,
      opacity: 1,
      top: "0vw",
    },
    +3
  );
  return localTimeline;
};
