import React, { useEffect, useState } from "react";
import group24 from "../../images/Group 24.png";
import axios from "axios";
import { baseURL } from "../../global/global";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../Alert";
import { setAlert } from "../../actions/alert";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import { addVideoData } from "../../actions/videoAction";
import SocialIcons from "./SocialIcons";
import syvidIcon from "../../images/logo-syvideo.svg";
import facebookIcon from "../../images/logo-facebook.svg";
import youtubeIcon from "../../images/YT-DEFAULT.png";
import scriptIcon from "../../images/logo-scriptreel.svg";
import trimIcon from "../../images/logo-trim.svg";
import liveIcon from "../../images/logo-livereel.png";

let intVideo;
let intVideoQueue;
const MergeCard = ({ item, fetchCampaigns }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const auth = useSelector(state => state.auth);
    const socials = useSelector(state => state.social);

    const [loader, setLoader] = useState(false);
    const [loadEdit, setLoadEdit] = useState(false)
    const [showModel, setShowModel] = useState(false);
    const [socialData, setSocialData] = useState(socials);
    const [currSocial, setCurrSocial] = useState([]);
    const [postData, setPostData] = useState({
        title: '',
        description: '',
        video_url: item.url,
        integration_id: '',
        type: '',
        network: ''
    })

    const [buttonText, setButtonText] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(false);
    const [selectIcon, setSelectIcon] = useState(false);
    const [progressVideo, setProgressVideo] = useState(false);

    const openDeletePopUp = (id) => {
        setDeleteId(id);
        setShowDelete(true);
    }

    const onConfirm = () => {
        deleteCampaign();
    }

    const deleteCampaign = () => {
        setButtonText('Deleting...');
        axios({
            method: "POST",
            url: `${baseURL}delete-merge`,
            data: { id: deleteId, user_id: auth.user.id },
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                fetchCampaigns();
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);
            setButtonText('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButtonText('Delete');
        })
    }


    const currentSocial = (social, type, icon) => {
        setCurrSocial(social);
        setSelectIcon(icon);

        setPostData({ ...postData, type: type, integration_id: social[0].id, network: icon });
    }

    const onInputChange = (e) => {
        setPostData({ ...postData, [e.target.name]: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        axios({
            method: 'POST',
            url: `${baseURL}${postData.type}`,
            data: postData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'))
            } else {
                dispatch(setAlert(res.data.message, 'danger'))
            }
            setShowModel(false);
            setLoader(false);
        }).catch(err => {
            setShowModel(false);
            setLoader(false);
        })
    }



    return (
        <>
            <Alert />
            <div className="col-lg-3 col-md-6 mb-4">
                <div className="video-card">
                    <div className="video-img">

                        {
                            progressVideo !== false ?
                                <div className="randring-card">
                                    <div className="row">
                                        <div className="col-7 d-flex align-items-center  pr-0">
                                            <div className="progress-content text-white">
                                                <span>Your Progress</span>
                                                <h5>{progressVideo.progress_status}</h5>
                                            </div>
                                        </div>
                                        <div className="col-5  d-flex   justify-content-end ">
                                            <div className="progress-status text-white">
                                                <span>{Number((parseInt(progressVideo.progress > 100 ? 100 : progressVideo.progress)).toFixed(0))}%</span>
                                                <h5>Completed</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <div className="progress-bar-status">
                                                <div className="progress">
                                                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={`${progressVideo.progress}`} aria-valuemin="0" aria-valuemax="100" style={{ width: progressVideo.progress + '%' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : <img src={item?.preview_image} alt="" className="img-fluid" />
                        }
                        {
                            item.output_video === "" ?
                                <div className="render-overlay">{item.status}</div>
                                : ''
                        }

                        <div className="ClipsReel-overlay d-flex align-items-center justify-content-center flex-column">

                            <p className="mt-4 text-center text-white">{item.created}</p>

                            <ul className="list-inline m-0 px-2 d-flex justify-content-center gap-2">
                                {/*{*/}
                                {/*    auth.user.is_client_account == "0" ?*/}
                                {/*        <li className="text-center">*/}
                                {/*            <a style={{ color: "#fff" }} className="cursor-pointer" onClick={() => editCampaign(item.id)}>*/}
                                {/*                <span><i className="far fa-edit"></i></span>Edit*/}
                                {/*            </a>*/}
                                {/*        </li>*/}
                                {/*        : ''*/}
                                {/*}*/}
                                {
                                    item.output_video !== "" ?
                                        <li className="text-center">
                                            <a href={`${baseURL}download-file?url=${item.output_video}`} style={{ color: "#fff" }} className="cursor-pointer">
                                                <span><i className="far fa-arrow-alt-circle-down"></i></span>
                                                Download
                                            </a>
                                        </li>
                                        : ''
                                }
                                {
                                    auth.user.is_client_account == "0" ?
                                        <>
                                            {
                                                item.output_video !== "" ?
                                                    <li className="text-center">
                                                        <a onClick={() => setShowModel(true)} style={{ color: "#fff" }} className="cursor-pointer">
                                                            <span><i className="fas fa-cloud-upload-alt"></i></span>
                                                            Publish
                                                        </a>
                                                    </li>
                                                    : ''
                                            }
                                            <li className="text-center">
                                                <a style={{ color: "#fff" }} className="cursor-pointer" onClick={() => openDeletePopUp(item.id)}>
                                                    <span><i className="far fa-trash-alt"></i></span>
                                                    Delete
                                                </a>
                                            </li>
                                        </>
                                        : ''
                                }
                            </ul>
                        </div>
                    </div>


                    <div className="video-title">
                        <div className="row">
                            {/*<div className="col-md-2 col-2">*/}
                            {/*    <div className="vidoe-play-icon">*/}
                            {/*        <span><img src={group24} alt="play-icon" /></span>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="col-md-12 col-12 d-flex align-items-center">
                                <div className="video-name  text-white">
                                    <p className="m-0">{item.name}</p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div className={`modal ${showModel ? 'show-modal' : ''} `}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header text-center">
                                <h5 className="modal-title" style={{ width: "100%" }}>Publish This Video</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={() => setShowModel(false)}
                                    aria-label="Close">
                                    <span aria-hidden="true">
                                        <i className="fas fa-times-circle" style={{ color: "#ffffff" }} ></i></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="icon-response">
                                    {socialData.facebook.length > 0 ? <span className="icon-response-span" onClick={() => currentSocial(socialData.facebook, 'upload-to-facebook', 'facebook')}><SocialIcons icon={facebookIcon} name="Facebook" checkName="facebook" selectIcon={selectIcon} /></span> : ''}
                                    {socialData.youtube.length > 0 ? <span className="icon-response-span" onClick={() => currentSocial(socialData.youtube, 'upload-to-youtube', 'youtube')}><SocialIcons icon={youtubeIcon} name="YouTube" checkName="youtube" selectIcon={selectIcon} /></span> : ''}
                                    {socialData.syvid.length > 0 ? <span className="icon-response-span" onClick={() => currentSocial(socialData.syvid, 'push-to-app', 'syvid')}><SocialIcons icon={syvidIcon} name="SyVid" checkName="SyVid" selectIcon={selectIcon} /></span> : ''}
                                    {socialData.scriptreel.length > 0 ? <span className="icon-response-span" onClick={() => currentSocial(socialData.scriptreel, 'push-to-app', 'scriptreel')}><SocialIcons icon={scriptIcon} name="ScriptReel" checkName="scriptreel" selectIcon={selectIcon} /></span> : ''}
                                    {socialData.livereel.length > 0 ? <span className="icon-response-span" onClick={() => currentSocial(socialData.livereel, 'push-to-app', 'livereel')}><SocialIcons icon={liveIcon} name="LiveReel" checkName="livereel" selectIcon={selectIcon} /></span> : ''}
                                    {socialData.trimreel.length > 0 ? <span className="icon-response-span" onClick={() => currentSocial(socialData.trimreel, 'push-to-app', 'trimreel')}><SocialIcons icon={trimIcon} name="TrimReel" checkName="trimreel" selectIcon={selectIcon} /></span> : ''}
                                </div>

                                <div className="form-body">
                                    <form className="gdpr-update-email" method="post" onSubmit={(e) => onFormSubmit(e)}>
                                        <div className="form-group">
                                            <small className="form-text text-muted">Title</small>
                                            <input type="text" className="form-control" required name="title" onChange={(e) => onInputChange(e)} placeholder="Enter Title" />
                                        </div>
                                        <div className="form-group">
                                            <small className="form-text text-muted">Description</small>
                                            <textarea className="form-control" required name="description" onChange={(e) => onInputChange(e)} placeholder="Enter Description" style={{boxShadow:"none"}}/>
                                        </div>
                                        <div className="form-group">
                                            <small className="form-text text-muted">Account Type</small>
                                            <select className="form-control" required name="integration_id" onChange={(e) => onInputChange(e)} style={{boxShadow:"none"}}>
                                                <option disabled selected>select account</option>
                                                {
                                                    currSocial.length > 0 ?
                                                        currSocial.map((item, index) => {
                                                            return (
                                                                <option key={index} style={{ backgroundColor: "#000" }} value={item.id}>{item?.username}</option>
                                                            )
                                                        })
                                                        : ''
                                                }
                                            </select>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn-custom btn-change7" disabled={loader}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Publish
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={buttonText}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="light"
                    title="Are you sure?"
                    onConfirm={() => onConfirm()}
                    onCancel={() => setShowDelete(false)}
                    focusCancelBtn
                    show={showDelete}
                >
                    You want to delete this campaign
                </SweetAlert>

                {
                    loadEdit ?
                        <div className="loader-sec">
                            <div className="loader">
                            </div>
                        </div>
                        : ''
                }

            </div>


        </>
    )
}

export default MergeCard;