import styled from "styled-components";

export const Square1Style = styled.div`
width:100%;
height:100%;
  
.img-fluid {
  display: block;
  max-width: 100%;
}

.container2{
  width:100%;
  height:100%;
  overflow: hidden;
}
  

.front-layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -50%;
  right: 0;
}

.back-layer {
  position: absolute;
}

.back-layer:after {
  content: '';
  width: 50%;
  height: 100%;
  background: #f5bfcf;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.text-wrapper {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  max-width: 50%;
  right: 0;
  padding:5%;
  height: 100%;
}

.container_two_one{
  width:100%;
  height:100%;
}
.back-layer{
  width: 100%;
  height: 100%;
}

.front-layer img, .front-layer video{
  width:100%;
}

.text-wrappers {
  position:absolute;
  top:0;
  left:50%;
  width:50%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;
  overflow: hidden;
}
.text-wrappers > div {
  width:100%;
}
`;