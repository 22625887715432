import React from "react";
import { Regular7Style } from "./css/Regular7Style";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";

const regular_1_7 = (props) => {
  return (
    <Regular7Style>
      <div className="template-wrapper">
        <TemplateLogo logo={props.logo} />
        <TemplateWatermark watermark={props.watermark} />
        <div className="t7-containersaven">
          <div className="t7-t-colmn t7-t-colmn-1">
            <div className="t7-img-wrapper t7-woman">
              <TemplateImage slideType={props.slideType} file={props.file} />
            </div>
          </div>
          <div className="t7-t-colmn t7-t-colmn-2">
            <div className="t7-text-wrappers">
              <TemplateText
                id={props.id}
                text={props.text}
                textStyle={props.textStyle}
                editStatus={props.editStatus}
                onInputSlideText={props.onInputSlideText}
              />
            </div>
          </div>
        </div>
      </div>
    </Regular7Style>
  );
};

export default regular_1_7;
