import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateSlideImage} from "../../actions/videoAction";
import FileCrop from "./FileCrop";


const ScrapImages = ({selectedIndex, dimension}) => {

    const dispatch = useDispatch();

    const [cropModel, setCropModel] = useState(false);
    const [cropImg, setCropImg] = useState('');
    const [cropIndex, setCropIndex] = useState('');

    const scrapImages = useSelector(state=> state.videos.scrap_images);

    const [images, setImages] = useState(scrapImages);
    const [checkImage, setCheckImage] = useState(false);

    const handleCrop = (image, index) => {
        setCropModel(true);
        setCropImg(image);
        setCropIndex(index);
    }

    const selectImage = (image, index) => {

        setCheckImage(index);

        let jsonData = {
            file_url: image,
            poster: image,
            index: selectedIndex

        }

        dispatch(updateSlideImage(jsonData))
    }

    useEffect(()=>{
        setImages(scrapImages);
    },[scrapImages])



    return(
        <>
            <div className="search-load-box mt-4" style={{marginBottom:"80px"}}>
                <div className="row" id="scrollableMeme" style={{ overflowX: "hidden" }}>

                    {
                        images.length > 0 ?
                            images.map((image,index)=>{
                                return(
                                    <div className="colmn col-6" key={index}>
                                        <div className="search-load d-flex justify-content-center align-items-center cursor-pointer">
                                            <div className="search-load-wrapper-blur-bg">
                                                <img src={image} alt={'scrap'} width="100%" height="100%" onClick={() => selectImage(image, index)}   style={{ objectFit: "contain" }} />
                                            </div>

                                            <img src={image} alt={'scrap'} width="100%" height="100%" onClick={() => selectImage(image, index)}  style={{ objectFit: "contain" }} />
                                            {
                                                checkImage === index ?
                                                    <div className="slide-active">
                                                        <i className="fa fa-check" aria-hidden="true"></i>
                                                    </div>
                                                    : ''
                                            }
                                            {/*<div className="crop-icon" onClick={()=>handleCrop(image, index)}>*/}
                                            {/*    <i className="fa fa-crop"></i>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                )
                            })
                        :   <div className="alert alert-primary alert-height" role="alert">
                                No Webpage Images Added
                            </div>
                    }

                </div>
            </div>

            {
                cropModel ? <FileCrop selectedIndex={selectedIndex} dimension={dimension} setCropModel={setCropModel} image={cropImg}  /> : ''
            }
        </>
    )
}

export default ScrapImages;