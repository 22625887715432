import styled from "styled-components";

export const Regular5Style = styled.div`
  width: 100%;
  height: 100%;

  .flex-column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
  
  .flex-row-center {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
  
  .flex-row-left {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: left;
    justify-content: left;
  }
  
  .subflex-left {
    width: 100%;
    text-align: left;
  }
  
  .subflex-right {
    width: 100%;
    text-align: right;
  }
  
  .subflex-center {
    width: 100%;
    text-align: center;
  }
  
  .f-arial {
    font-family: Arial;
  }
  
  .f-intro {
    font-family: IntroCond;
  }
  
  .f-introbold {
    font-family: IntroCondBold;
  }
  
  .f-black {
    color: #000;
  }
  
  .f-white {
    color: #FFFFFF;
  }
  
  .f-orange {
    color: #FF7D01; 
  }
  
  .f-blue {
    color: #0076FE;
  }
  
  .f-blue-light {
    color: #2DB6E3;
  }
  
  .poster {
    position: relative;
    overflow: hidden !important;
  }
  
  .poster.sixteen-by-nine.lg {
    width: 100%;
    height: 100%;
  }
  
  .poster.sixteen-by-nine.sm {
    width: 560px;
    height: 315px;
  }
  
  .poster.nine-by-sixteen.lg {
    width: 1080px;
    height: 1920px;
  }
  
  .poster.nine-by-sixteen.sm {
    width: 171px;
    height: 304px;
  }
  
  .poster.one-by-one.lg {
    width: 1920px;
    height: 1920px;
  }
  
  .poster.one-by-one.sm {
    width: 310px;
    height: 310px;
  }



  .video-container {
    height: 100%;
    width: 100%;
  }
  
  .video-bg {
    position: absolute;
    z-index: 1;
    height: 100%;
  }
  
  .text-wrapper {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:5%;
  }
  .text-wrapper > div {
    width:100%;
    color:#fff;
  }
  /* FOR TESTING ONLY */
  
  .f-lg {
    font-size: 102px;
  }
  
  .poster.sm .f-lg {
    font-size: 26px;
  }


  
  
`;
