import React, { useState } from 'react'
import { FiTrash2 } from 'react-icons/fi'
import { LuFileEdit } from 'react-icons/lu'
import { useDispatch } from 'react-redux'
import EditUser from './EditUser'
import CommonAlert from '../Common/CommonAlert'
import { onDeleteCampaign } from '../../actions/CommonActions'

const UserRows = ({ curElem, fetchUsers, users, setUsers }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            id: curElem.id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-wl-users", data, users, setUsers, setSweet))
    }
    return (
        <tr>
            <td>{curElem.name}</td>
            <td className="text-end">
                <span className="white-table-icon"><LuFileEdit onClick={handleShow} /></span>
                <span className="white-table-icon"><FiTrash2 onClick={onDelete} /></span>
            </td>


            <EditUser
                show={show}
                handleClose={handleClose}
                curElem={curElem}
                fetchUsers={fetchUsers}
            />

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this user?"}
                confirmButtonColor={""}
                icon={"delete"}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </tr>
    )
}

export default UserRows