import styled from "styled-components";

export const Square1Style = styled.div`
  width:100%;
  height:100%;

  .container3 {
    width:100%;
    height:100%;
    overflow: hidden;
    background: #a1dffb;
  }

  .f-red {
    color: #c33124;
  }

  .f-peach {
    color: #f98365;
  }

  .f-white {
    color: #fff;
  }

  .f-blue {
    color: #005596;
  }

  .container3 div {
    font-family: "Poppins", sans-serif;
    line-height: 1.2;
    font-weight: 600;
    margin: 0;
  }

  .back-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.6;
  }

  .text-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 560px;
    padding: 0 20px 0;
    width: 100%;
    height:100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column  }

  .text-wrapper div:first-child {
    //font-size: 2.4%;
    //margin-bottom: 4%;
    //top: -6%;
  }

  .text-wrapper div:last-child {
    //opacity: 0;
  }

  .text-wrapper div {
    width:100%;
  }
`;
