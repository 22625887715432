import { baseURL } from "../global/global";
import axios from "axios";
import { setAlert } from "./alert";

export const fetchRebrandData = (token) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${baseURL}fetch-wl-rebranding`,
        data: {},
        headers: { 'Content-Type': 'application/json', 'Authorization': token }
    }).then(res => {
        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, 'success'));
            dispatch({ type: 'ADD_REBRAND', payload: res.data.data });
        } else {
            // dispatch(setAlert(res.data.message,'danger'));
        }
    }).catch(error => {
        // dispatch(setAlert('Getting Internal Server Error','danger'));
    })
}

export const addRebrandData = (data, setLoader) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}wl-rebranding`,
        data: data,
        headers: { 'Authorization': getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, 'success'));
            dispatch(fetchRebrandData(getState().auth.token));
        } else {
            dispatch(setAlert(res.data.message, 'danger'));
        }
        setLoader(false);
    }).catch(error => {
        dispatch(setAlert('Getting Internal Server Error', 'danger'));
        setLoader(false);
    })
}

export const removeRebrandData = (token) => (dispatch) => {
    dispatch({ type: 'REMOVE_ADD_REBRAND' });
}