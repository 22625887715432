import styled from "styled-components";

export const Portait1Style = styled.div`
width:100%;
height:100%;

.img-fluid {
  display: block;
  max-width: 100%;
}

.container7 {
  width:100%;
  height:100%;
  overflow: hidden;
}


.text-wrapper div {
  width: 100%;
}

.back-layer {
  width:100%;
  height:100%;
  background: #ec4f43;
}

.back-layer img,
.back-layer video {
  width:100%;
  height:100%;
  object-fit: cover;
  object-position: center;
  opacity: 0.7;
}

.text-wrapper {
  position: absolute;
  top: 0;
  width:100%;
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  
}

.text-wrapper div {
 width: 100%;
}

`;
