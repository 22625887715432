import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import AddAccount from "./AddAccount";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { appName } from "../../global/global";

const AccountManagement = () => {

    const history = useHistory();
    const auth = useSelector(state => state.auth);
    const brandName = useSelector(state => state.rebrand.data);


    useEffect(() => {
        if (auth.user.membership !== '') {

            let memberships = auth.user.membership.split('__');
            if (!memberships.includes('agency')) {
                history.push('/dashboard')
            }

        } else {
            history.push('/dashboard')
        }
    }, [])

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Account Management</title>
            </Helmet>
            <Navbar />

            <section className="add-account-sec">
                <div className="container">
                    <AddAccount />
                </div>
            </section>

            <Footer />
        </>
    )
}

export default AccountManagement;