import styled from "styled-components";

export const Regular2Style = styled.div`
  .container_two_two .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container_two_two {
    width: 560px;
    height: 315px;
    overflow: hidden;
  }

  .f-orange {
    color: #fb4b1f;
  }

  .f-light-orange {
    color: #fe783b;
  }

  .f-white {
    color: #fff;
  }

  .f-blue {
    color: #005596;
  }

  .container_two_two div {
    font-family: 'Poppins', sans-serif;
    line-height: 1.2;
    font-weight: 600;
    margin: 0;
  }

  .container_two_two .front-layer {
    position: relative;
    top: 0;
    bottom: 0;
    right: -260px;
  }

  .front-layer img, .front-layer video{
    position: absolute;
  }

  .container_two_two .front-layer img {
    width: 560px;
    height: 315px;
    transform: rotateY(180deg);
    /* position: absolute; */
  }

  .container_two_two .back-layer {
    width: 560px;
    height: 315px;
    position: absolute;
    background-size: cover;
    /* transform: rotateY(180deg); */
  }

  .container_two_two .back-layer:after {
    content: '';
    width: 280px;
    height: 315px;
    background: #f5bfcf;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .container_two_two .text-wrappers {
    position: absolute;
    top: 50%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    width: 300px;
    left: 0;
    padding: 0 15px;
    height: 300px;
    /* transform: rotateY(180deg); */
  }

  .container_two_two .text-wrappers div {
    bottom: -1500px;
    font-size: 16px;
    // flex: 0 0 100%;
  }

  .container_two_one{
    width:100%;
    height:100%;
  }
  .back-layer{
    width: 100%;
    height: 100%;
  }

  .front-layer img, .front-layer video{
    width:100%;
  }

  .text-wrappers {
    position:absolute;
    top:0;
    left:50%;
    width:50%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
  }
  .text-wrappers div {
    width:100%;
    overflow: hidden;
  }
`;