import React, { useState } from "react";
import LoginFooter from "./LoginFooter";
import { Link, useHistory } from "react-router-dom";
import logo from "../../images/LOGO.png";
import loginBg from "../../images/BG.png";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { resetPassword } from "../../actions/authAction";
import Alert from "../Alert";
import { Helmet } from "react-helmet";
import { appName } from "../../global/global";

const ResetPassword = ({ location }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const brandName = useSelector(state => state.rebrand.data);
    const rebrandData = useSelector(state => state.rebrand.data);

    const [loader, setLoader] = useState(false);
    const [password, setPassword] = useState({
        password: "",
        confirm_password: "",
        password_token: queryString.parse(location.search).token,
    });
    const [error, setError] = useState("");
    const [passwordMsg, setPasswordMsg] = useState({ msg: "", validation: false });

    const onInputChange = (e) => {
        setPassword({ ...password, [e.target.name]: e.target.value });
        setError("");
        setPasswordMsg({ msg: "", validation: false });
    };

    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        if (password.length < minLength) return "Password must be at least 8 characters long.";
        if (!hasUpperCase) return "Password must contain at least one uppercase letter.";
        if (!hasLowerCase) return "Password must contain at least one lowercase letter.";
        if (!hasNumbers) return "Password must contain at least one number.";
        if (!hasSpecialChars) return "Password must contain at least one special character.";

        return "";
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        const validationError = validatePassword(password.password);

        if (validationError) {
            setPasswordMsg({ msg: validationError, validation: true });
            return;
        }

        if (password.password === password.confirm_password) {
            setLoader(true);
            dispatch(resetPassword(password, setLoader, history));
        } else {
            setPasswordMsg({ msg: "Passwords do not match!", validation: true });
        }
    };

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Reset Password </title>
            </Helmet>
            <Alert />
            <section className="logoimg" style={{ backgroundImage: "url(" + loginBg + ")" }}>
                <div className="container">
                    <div className="row pt-4">
                        <div className="col-md-4">
                            <div className="logo">
                                <img src={logo} alt="logo" />
                            </div>
                        </div>
                    </div>

                    <div className="login-box">
                        <div className="row">
                            <div className="col-lg-6 d-flex align-items-center">
                                <div className="login-content text-white text-center">
                                    <h1><span>Welcome To </span> {rebrandData?.name ? rebrandData?.name : appName}!</h1>
                                    <p>Instantly Turn Any Webpage Into a Traffic-Getting Video At The Push of a Button!</p>
                                    <h5 className="mt-3">…It’s Super Easy-To-Use</h5>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <form className="login-form text-white" method="post" onSubmit={onFormSubmit}>
                                    <h4><span>Reset</span> Password</h4>
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    <div className="form-group mt-4">
                                        <label>New Password</label>
                                        <input type="password" name="password" className="form-control"
                                            placeholder="************" required onChange={onInputChange} />
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm Password</label>
                                        <input type="password" name="confirm_password" className="form-control"
                                            placeholder="************" required onChange={onInputChange} />
                                    </div>
                                    <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>
                                        {passwordMsg.validation ? passwordMsg.msg : ""}
                                    </p>
                                    <button type="submit" className="form-control btn-change6 mt-4" disabled={loader}>
                                        {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Reset Password
                                    </button>
                                    <div className="forgot text-center mt-4">
                                        <Link to="/login" className="text-center ">
                                            Login? <span>Click here</span>
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <LoginFooter />
            </section>
        </>
    );
};

export default ResetPassword;
