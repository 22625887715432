import React from "react";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";
import { Regular6Style } from "./css/Regular6Style";

const regular_2_6 = (props) => {
  return (
    <Regular6Style>
      <div className="container_two_six">
        <TemplateLogo logo={props.logo} />
        <TemplateWatermark watermark={props.watermark} />
        <div className="back-layer">
          <TemplateImage slideType={props.slideType} file={props.file} />
        </div>
        <div className="front-layer">
          <div className="col">
            <div className="fw" />
          </div>
          <div className="col">
            <div className="fw" />
          </div>
          <div className="col">
            <div className="fw" />
          </div>
          <div className="col">
            <div className="fw" />
          </div>
          <div className="col">
            <div className="fw" />
          </div>
          <div className="col">
            <div className="fw" />
          </div>
          <div className="col">
            <div className="fw" />
          </div>
          <div className="col">
            <div className="fw" />
          </div>
          <div className="col">
            <div className="fw" />
          </div>
          <div className="col">
            <div className="fw" />
          </div>
        </div>
        <div className="text-wrappers">
          <TemplateText
            id={props.id}
            text={props.text}
            textStyle={props.textStyle}
            editStatus={props.editStatus}
            onInputSlideText={props.onInputSlideText}
          />
        </div>
      </div>
    </Regular6Style>
  );
};

export default regular_2_6;
