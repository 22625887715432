import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar";
import TrainingTitles from "./TrainingTitles";
import TrainingContents from "./TrainingContents";
import Footer from "../Footer";
import { useSelector } from "react-redux";
import TrainingBreadcrumbs from "./TrainingBreadcrumbs";
import { appName } from "../../global/global";

const Training = ({ location }) => {
    const brandName = useSelector(state => state.rebrand.data);

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Training</title>
            </Helmet>
            <Navbar />

            <section className="traning-sec">
                <div className="container">
                    <div className="row">
                        <TrainingBreadcrumbs location={location} name={false} />
                    </div>
                    <div className="row">

                        <TrainingTitles location={location} name={false} />
                        <TrainingContents location={location} />

                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Training;