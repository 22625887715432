import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import UpgradesMenu from "./UpgradesMenu";
import UpgradesContent from "./UpgradesContent";
import { useSelector } from "react-redux";
import { appName } from "../../global/global";

const Upgrades = () => {
    const brandName = useSelector(state => state.rebrand.data);

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Upgrades</title>
            </Helmet>
            <Navbar />

            <section className="upgrads-sec">
                <div className="container">
                    <div className="row">
                        <UpgradesMenu />
                        <UpgradesContent />
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Upgrades;