import React, { useEffect, useState } from 'react'
import webimg from '../../images/webimg.png'
import { useSelector } from "react-redux";
import { appName } from '../../global/global';

function Webinar() {
  const rebrandData = useSelector(state => state.rebrand);
  const [rebrand, setRebrand] = useState(rebrandData);

  useEffect(() => {
    setRebrand(rebrandData);
  }, [])

  return (
    <>
      <section className="webinar-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 d-flex align-items-center ">
              <div className="webiner-content">
                <span>{rebrand.data === false ? appName : rebrand.data.name} LESSON #1</span>
                <h3 className="mt-3">
                  <span>Join Us</span> for our LIVE Webinar.
                </h3>
                <p className="mt-3">
                  Join us for a private training that will walk you through how to use {rebrand.data === false ? appName : rebrand.data.name} to help you create and sell videos for maximum engagement,
                  views and profits. This training or upgrade will not be made
                  available afterwards. Sign up and make sure to show up on this live
                  training with all your questions.
                </p>
                {/*<a href="https://abhineos.com/training" target="_blanck">*/}
                {/*  <button type="button" className="mt-4 medium-btn">*/}
                {/*    Sign Up Now*/}
                {/*  </button>*/}
                {/*</a>*/}
              </div>
            </div>
            <div className="col-md-6">
              <div className="webinar-img">
                <img src={webimg} alt className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="webinar-sec afterLaunch d-none">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 d-flex align-items-center ">
              <div className="webiner-content">
                {/* <span>{ rebrand.data === false ? 'ClipsReel' : rebrand.data.name} LESSON #1</span> */}
                <h3 className="mt-3"><span>Got questions?</span> <br /> We’re here for you.</h3>
                <p className="mt-3">
                  If you need any help or support using {rebrand.data === false ? appName : rebrand.data.name}, our easy-to-follow tutorials are available for you 24/7. These step-by-step video tutorials will show you the ropes in setting up {rebrand.data === false ? 'ClipsReel' : rebrand.data.name} to give you maximum engagement, views, and profits.
                </p>
                <a href="/help-and-support">
                  <button type="button" className="mt-4 medium-btn">
                    Get Started Here
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Webinar
