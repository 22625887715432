import styled from "styled-components";

export const Portait3Style = styled.div`
width:100%;
height:100%;
  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container9 {
    width:100%;
    height:100%;
    overflow: hidden;
  }


  .text-wrapper div {
    width:70%;
    text-align:center;
  }

  .back-layer {
    width:100%;
    height:100%;
  }

  .front-layer {
    position: absolute;
    top: -100%;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 1) 0%,
      rgba(255, 255, 255, 0) 60%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 1) 0%,
      rgba(255, 255, 255, 0) 60%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 0%,
      rgba(255, 255, 255, 0) 60%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00ffffff',GradientType=0 );
  }

  .yellow-bar {
    position: absolute;
    top: 13%;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 75%;
  }

  .yellow-bar .yellow.right {
    right: -20%;
  }

  .yellow-bar .yellow.left {
    left: -20%;
  }

  .yellow-bar .yellow {
    background: #dfd200;
    width: 17%;
    position: absolute;
    height: 100%;
    opacity: 0.7;
  }

  .back-layer img,
  .back-layer video {
    width:100%;
    height:100%;
    object-fit: cover;
    object-position: center;
  }

  .text-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
  }`;
