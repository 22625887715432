import styled from "styled-components";

export const Regular8Style = styled.div`
width: 100% !important;
height: 100% !important;


.t8-containereight .img-fluid {
  display: block;
  max-width: 100%;
}

.t8-containereight {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* .container {
  background: #005596;
} */

/* .t8-img-wrapper .img-fluid {
  opacity: 0.5;
} */

.f-cyan {
  color: #00fffc;
}

.f-white {
  color: #fff;
}

.f-blue {
  color: #005596;
}

.t8-containereight .t8-woman {
  width: 100%;
  height: 100%;
}

.t8-containereight div {
  font-family: "Poppins", sans-serif;
  line-height: 1.3;
  font-weight: 500;
  margin: 0;
}

.t8-containereight .t8-text-wrappers div {
  font-size: 1.4vw;
  text-align: left;
  line-height: 1.4;
  margin-bottom: 0.5vw;
  display: block;
}

.t8-containereight .t8-text-wrappers {
  position: absolute;
  z-index: 1;
  bottom: 5%;
  margin: auto;
  height: 25%;
  left: 5%;
  max-width: 55%;
  overflow: hidden;
  transform-origin: left;
  clip-path: inset(0% 100% 0% 0%);
}

.t8-containereight .t8-woman img {
  transform: scale(1.2);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1366.98px) {
}

@media screen and (max-width: 991.98px) {
  .t8-containereight .t8-text-wrappers p {
    /*font-size: 2vw;*/
  }
}

@media screen and (max-width: 640.98px) {
  .t8-containereight .t8-text-wrappers p {
    /*font-size: 2.8vw;*/
  }
}
`;
