import React from "react";
import { Square1Style } from "./css/Square1Style";
import OutroLogo from "../../components/editor/Templates/OutroLogo";
import OutroText from "../../components/editor/Templates/OutroText";

const square_1 = (props) => {
  return (
    <Square1Style>
      <div className="poster flex-column">
        <div className="logo-container flex-column">
          <OutroLogo logo={props.file} />
        </div>
        <div className="text-wrapper flex-column">
          <OutroText
            id={props.id}
            text={props.text}
            textStyle={props.textStyle}
            editStatus={props.editStatus}
            onInputSlideText={props.onInputSlideText}
          />
        </div>
      </div>
    </Square1Style>
  );
};

export default square_1;
