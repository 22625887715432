
export const addScriptText = (data,setStatusDim) => (dispatch) => {
    dispatch({type: 'ADD_SCRIPT', payload: data});
    setStatusDim(true);
}


export const addScriptDimension = (data) => (dispatch) => {
    dispatch({type: 'ADD_DIMENSION', payload: data});
}


export const removeScriptData = () => (dispatch) => {
    dispatch({type: 'REMOVE_SCRIPT_DATA'})
    dispatch({type: 'REMOVE_SCRAP_DATA'})
}

export const addCampaignType = (data) => (dispatch) => {
    dispatch({type: 'ADD_CAMPAIGN_TYPE', payload: data})
}

export const addCampaignUrl = (data) => (dispatch) => {
    dispatch({type: 'ADD_URL', payload: data})
}

export const removeCampStatus = () => (dispatch) => {
    dispatch({type: 'REMOVE_CAMP_DATA'})
}