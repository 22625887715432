import { init } from "./common";
import { gsap, Power1, Power2, Power3, Power4, Linear } from "gsap";

export const regular_8_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginLeft: "-200px" },
    {
      duration: 1,
      autoAlpha: 1,
      marginLeft: "0",
      onComplete() {
        localTimeline.to(".text-wrapper div", {
          duration: 1,
          opacity: 1,
          left: 0,
          onComplete() {
            localTimeline.to(".text-wrapper div", { duration: 1, opacity: 1 });
          },
        });
      },
    },
    +2
  );

  return localTimeline;
};
export const regular_8_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "-200px", autoAlpha: 0 },
    {
      duration: 1,
      autoAlpha: 1,
      marginLeft: "0",
      onComplete() {
        localTimeline.to(".text-wrapper div", { duration: 1, opacity: 1 });
      },
    },
    +2,
    1
  );
  return localTimeline;
};

export const regular_8_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".front-layer", {
    left: "-40%",
    scale: 1,
    ease: "expo.out",
  });

  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "-200px", autoAlpha: 0 },
    { autoAlpha: 1, marginLeft: "0", stagger: 0.1 },
    +1
  );
  return localTimeline;
};

export const regular_8_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".front-layer", { left: "40%", scale: 1, ease: "expo.out" });

  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "200px", autoAlpha: 0 },
    { right: 0, stagger: 0.1, autoAlpha: 1, marginLeft: "0" },
    +1
  );
  return localTimeline;
};

export const regular_8_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "200px" },
    { right: 0, stagger: 0.05, marginLeft: "0" }
  );

  localTimeline.to(".vid-wrapper", {
    duration: 0.6,
    clipPath: "polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)",
  });
  return localTimeline;
};

export const regular_8_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "-200px" },
    { left: 0, stagger: 0.05, marginLeft: "0" }
  );
  localTimeline.to(".vid-wrapper", {
    duration: 0.6,
    clipPath: "polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)",
  });
  return localTimeline;
};

export const regular_8_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".text-wrapper", {
    duration: 1,
    bottom: 0,
  });

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 1,
    },
    +1
  );

  return localTimeline;
};
export const regular_8_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".text-wrapper",
    {
      duration: 0.5,
      clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
    },
    0.5
  );

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 1,
    }
  );

  return localTimeline;
};

export const square_8_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginLeft: "-200px" },
    {
      duration: 1,
      autoAlpha: 1,
      marginLeft: "0",
      onComplete() {
        localTimeline.to(".text-wrapper div", {
          duration: 1,
          opacity: 1,
          left: 0,
          onComplete() {
            localTimeline.to(".text-wrapper div", { duration: 1, opacity: 1 });
          },
        });
      },
    },
    +2
  );

  return localTimeline;
};
export const square_8_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "-200px", autoAlpha: 0 },
    {
      duration: 1,
      autoAlpha: 1,
      marginLeft: "0",
      onComplete() {
        localTimeline.to(".text-wrapper div", { duration: 1, opacity: 1 });
      },
    },
    +2,
    1
  );
  return localTimeline;
};

export const square_8_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".front-layer", {
    left: "-40%",
    scale: 1,
    ease: "expo.out",
  });

  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "-200px", autoAlpha: 0 },
    { autoAlpha: 1, marginLeft: "0", stagger: 0.1 },
    +1
  );
  return localTimeline;
};

export const square_8_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".front-layer", { left: "40%", scale: 1, ease: "expo.out" });

  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "200px", autoAlpha: 0 },
    { right: 0, stagger: 0.1, autoAlpha: 1, marginLeft: "0" },
    +1
  );
  return localTimeline;
};

export const square_8_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "200px" },
    { right: 0, stagger: 0.05, marginLeft: "0" }
  );

  localTimeline.to(".vid-wrapper", {
    duration: 0.6,
    clipPath: "polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)",
  });
  return localTimeline;
};

export const square_8_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "-200px" },
    { left: 0, stagger: 0.05, marginLeft: "0" }
  );
  localTimeline.to(".vid-wrapper", {
    duration: 0.6,
    clipPath: "polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)",
  });
  return localTimeline;
};

export const square_8_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".text-wrapper", {
    duration: 1,
    bottom: 0,
  });

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 1,
    },
    +1
  );

  return localTimeline;
};
export const square_8_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".text-wrapper",
    {
      duration: 0.5,
      clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
    },
    0.5
  );

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 1,
    }
  );

  return localTimeline;
};

export const story_8_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginLeft: "-200px" },
    {
      duration: 1,
      autoAlpha: 1,
      marginLeft: "0",
      onComplete() {
        localTimeline.to(".text-wrapper div", {
          duration: 1,
          opacity: 1,
          left: 0,
          onComplete() {
            localTimeline.to(".text-wrapper div", { duration: 1, opacity: 1 });
          },
        });
      },
    },
    +2
  );

  return localTimeline;
};
export const story_8_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "-200px", autoAlpha: 0 },
    {
      duration: 1,
      autoAlpha: 1,
      marginLeft: "0",
      onComplete() {
        localTimeline.to(".text-wrapper div", { duration: 1, opacity: 1 });
      },
    },
    +2,
    1
  );
  return localTimeline;
};

export const story_8_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".front-layer", {
    left: "-25%",
    scale: 1,
    ease: "expo.out",
  });

  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "-200px", autoAlpha: 0 },
    { autoAlpha: 1, marginLeft: "0", stagger: 0.1 },
    +1
  );
  return localTimeline;
};

export const story_8_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".front-layer", { left: "25%", scale: 1, ease: "expo.out" });

  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "200px", autoAlpha: 0 },
    { right: 0, stagger: 0.1, autoAlpha: 1, marginLeft: "0" },
    +1
  );
  return localTimeline;
};

export const story_8_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "200px" },
    { right: 0, stagger: 0.05, marginLeft: "0" }
  );

  localTimeline.to(".vid-wrapper", {
    duration: 0.6,
    clipPath: "polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)",
  });
  return localTimeline;
};

export const story_8_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  
  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "-200px" },
    { left: '0', stagger: 0.05, marginLeft: "0" }
  );
  localTimeline.to(".vid-wrapper", {
    duration: 0.6,
    clipPath: "polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)",
  });
  return localTimeline;
};

export const story_8_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".text-wrapper", {
    duration: 1,
    bottom: 0,
  });

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 1,
    },
    +1
  );

  return localTimeline;
};

export const story_8_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".text-wrapper",
    {
      duration: 0.5,
      clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
    },
    0.5
  );

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 1,
    }
  );

  return localTimeline;
};
