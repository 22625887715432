import styled from "styled-components";

export const Square1Style = styled.div`
width:100%;
height:100%;

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container6 {
    width:100%;
    height:100%;
    overflow: hidden;
  }
  
  

  .back-layer {
    width:100%;
    height:100%;
    background: #f4c88c;
  }

  .back-layer img {
    width:100%;
    height:100%;
    position: absolute;
    object-fit: cover;
    opacity: 0.7;
    transform: scale(1.1);
    left: 5%;
  }

  .text-wrapper {
    position: absolute;
    top: 0;
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:5%;
  }

  .text-wrapper > div {
    width:100%;
  }
`;
