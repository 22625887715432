import React, { useEffect, useMemo, useState } from "react";
import sample from "../../images/sample.png";
import axios from "axios";
import { baseURL } from "../../global/global";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import swal from "sweetalert";
import {
  addSlideDuration,
  removeLogoWatermark,
  updateDimmerStatus,
  updateLogoWatermark,
  updateTempVariation,
} from "../../actions/videoAction";
import HoverVideoPlayer from "react-hover-video-player";

const LogoWatermark = ({
  logoData,
  watermarkData,
  template_id,
  selectedIndex,
  selectedLayer,
}) => {
  const logo = useMemo(() => {
    return logoData;
  }, [logoData]);

  const watermark = useMemo(() => {
    return watermarkData;
  }, [watermarkData]);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const userDuration = [
    "carla4001@gmail.com",
    "guen.schmitt@yahoo.de",
    "normahnasir@yahoo.com",
    "sisawoyusupha@yahoo.com",
    "doc@iplas.com",
    "leonardjffrsn@yahoo.com",
    "tom@pearmantrainnovations.co.uk",
    "Albaracin38@gmail.com",
    "albaracin38@gmail.com",
    "darlan@adsolver.com.br",
    "james@salesfunnelexpert.net",
    "sumit@vega6.com",
    "dwdvids@gmail.com",
    "enzo.giovinazzo77@gmail.com",
  ];
  const [membership, setMembership] = useState([]);
  const [variants, setVariants] = useState([]);

  const [loadLogo, setLoadLogo] = useState(false);
  const [loadWatermark, setLoadWatermark] = useState(false);

  const [uploadLogo, setUploadLogo] = useState({ data: [] });
  const [uploadWatermark, setUploadWatermark] = useState({ data: [] });
  const [removeLogo, setRemoveLogo] = useState(1);
  const [removeWatermark, setRemoveWatermark] = useState(1);
  const [removeDimmer, setRemoveDimmer] = useState(1);

  const [logoStatus, setLogoStatus] = useState({
    position: "top-left",
    url: "",
  });

  const [watermarkStatus, setWatermarkStatus] = useState({
    position: "bottom-right",
    url: "",
  });

  const addDuration = (e, index) => {
    let time = 40;
    if (membership.includes("unlimited")) {
      time = 40;
    }
    if (userDuration.includes(auth.user.email)) {
      time = 40;
    }

    if (e.target.value <= time && e.target.value >= 1) {
      dispatch(addSlideDuration(parseInt(e.target.value), index));
    }
  };

  const fileUpload = (file, type) => {
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      if (file.size < 5000000) {
        if (type === "logo") {
          setLoadLogo(true);
        } else {
          setLoadWatermark(true);
        }

        let formData = new FormData();
        formData.append("file", file);
        formData.append("file_type", type);

        axios({
          method: "POST",
          url: `${baseURL}upload-file`,
          data: formData,
          headers: {
            "Content-Type": "application/json",
            Authorization: auth.token,
          },
        })
          .then((res) => {
            if (res.data.status) {
              if (type === "logo") {
                setUploadLogo({
                  ...uploadLogo,
                  data: uploadLogo.data.concat(res.data.data),
                });
                setLoadLogo(false);
              } else if (type === "watermark") {
                setUploadWatermark({
                  ...uploadWatermark,
                  data: uploadWatermark.data.concat(res.data.data),
                });
                setLoadWatermark(false);
              }
              selectImage(res.data.data.file_url, type);
            } else {
              dispatch(setAlert(res.data.message, "danger"));
            }
          })
          .catch((error) => {
            setLoadLogo(false);
            setLoadWatermark(false);
          });
      } else {
        swal("Oops!", "Max allowed size 5MB");
      }
    } else {
      swal("Oops!", "You have Selected Invalid File Type");
    }
  };

  const logoWaterPos = (position, type) => {
    let fileType = "UPDATE_LOGO_POSITION";
    if (type === "watermark") {
      fileType = "UPDATE_WATERMARK_POSITION";
    }

    dispatch(updateLogoWatermark(fileType, position));
  };

  const onLogoUpload = (e) => {
    fileUpload(e.target.files[0], "logo");
  };

  const onWatermarkUpload = (e) => {
    fileUpload(e.target.files[0], "watermark");
  };

  const selectImage = (url, type) => {
    let data = {
      status: true,
      url: url,
      position:
        type === "logo" ? logoStatus.position : watermarkStatus.position,
    };

    let uploadType = "ADD_LOGO";
    if (type === "watermark") {
      uploadType = "ADD_WATERMARK";
    }

    dispatch(updateLogoWatermark(uploadType, data));
  };

  const handleRemoveLogo = (e) => {
    if (removeLogo === 1) {
      setRemoveLogo(1);
      dispatch(removeLogoWatermark("REMOVE_LOGO_LAYER"));
    } else {
      setRemoveLogo(0);
    }
  };

  const handleRemoveWatermark = (e) => {
    if (removeWatermark === 1) {
      setRemoveWatermark(1);
      dispatch(removeLogoWatermark("REMOVE_WATERMARK_LAYER"));
    } else {
      setRemoveWatermark(0);
    }
  };

  const handleRemoveDimmer = (e) => {
    if (removeDimmer === 1) {
      setRemoveDimmer(0);
      dispatch(updateDimmerStatus(true));
    } else {
      setRemoveDimmer(1);
      dispatch(updateDimmerStatus(false));
    }
  };

  const changeTempVariation = (id) => {
    if (selectedLayer.is_outro === false) {
      dispatch(updateTempVariation(id, selectedIndex));
    }
  };

  useEffect(() => {
    if (auth.user.membership !== "") {
      setMembership(auth.user.membership.split("__"));
    }
  }, []);

  useEffect(() => {
    axios({
      method: "POST",
      url: `${baseURL}template-variations`,
      data: JSON.stringify({ id: template_id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: auth.token,
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          setVariants(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="style-part-sec pt-4">
        <div className="row m-0 justify-content-between">
          <div className="col-md-12 mx-auto p-0">
            <div className="style-box-wrapper">
              <div className="row align-items-stretch m-0 justify-content-between">
                <div className="colmn w-colmn col-md-8 col-12">
                  <div className="style-upload-part style-logo">
                    <h6>Upload Logo</h6>
                    <div className="style-upload-box">
                      <h6 className="text-center">
                        <span className="mr-1">
                          <i className="fas fa-cloud-upload-alt" />
                        </span>{" "}
                        Upload Your Logo <br /> (JPG, PNG, JPEG up to 5MB is
                        allowed)
                      </h6>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="inputGroupFile01"
                          onChange={(e) => onLogoUpload(e)}
                        />
                      </div>
                    </div>
                    <div className="style-remove-logo mt-2">
                      <div className="row m-0">
                        <div className="col-3 p-0 d-flex ">
                          <div className="style-remove-cricle">
                            {loadLogo ? (
                              <i className="fa fa-spinner fa-spin" />
                            ) : (
                              <img
                                src={logo?.url ? logo.url : sample}
                                alt
                                className="img-fluid"
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-9 p-0 d-flex align-items-center">
                          <div className="remove-logo-switch-btn ">
                            <div className="form-check form-switch p-0">
                              <label
                                className="form-check-label"
                                htmlFor="flexSwitchCheckChecked"
                              >
                                Remove Logo
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckChecked"
                                value={removeLogo}
                                checked={logo === "" ? true : false}
                                disabled={logo === "" ? true : false}
                                onChange={(e) => handleRemoveLogo(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="colmn col-md-4">
                  <div className="position-text-box">
                    <div className="text-position-dropdown">
                      <label>Position</label>
                      <div
                        className={`box-wrapper ${logo === "" ? "disable-click" : ""
                          }`}
                      >
                        <div
                          className={`box ${logo?.position === "top-left" ? "box-active" : ""
                            }`}
                          onClick={(e) => logoWaterPos("top-left", "logo")}
                        >
                          <span />
                        </div>
                        <div
                          className={`box ${logo?.position === "top-center" ? "box-active" : ""
                            }`}
                          onClick={(e) => logoWaterPos("top-center", "logo")}
                        >
                          <span />
                        </div>
                        <div
                          className={`box ${logo?.position === "top-right" ? "box-active" : ""
                            }`}
                          onClick={(e) => logoWaterPos("top-right", "logo")}
                        >
                          <span />
                        </div>
                        <div
                          className={`box ${logo?.position === "center-left" ? "box-active" : ""
                            }`}
                          onClick={(e) => logoWaterPos("center-left", "logo")}
                        >
                          <span />
                        </div>
                        <div
                          className={`box ${logo?.position === "center" ? "box-active" : ""
                            }`}
                          onClick={(e) => logoWaterPos("center", "logo")}
                        >
                          <span />
                        </div>
                        <div
                          className={`box ${logo?.position === "center-right"
                            ? "box-active"
                            : ""
                            }`}
                          onClick={(e) => logoWaterPos("center-right", "logo")}
                        >
                          <span />
                        </div>
                        <div
                          className={`box ${logo?.position === "bottom-left" ? "box-active" : ""
                            }`}
                          onClick={(e) => logoWaterPos("bottom-left", "logo")}
                        >
                          <span />
                        </div>
                        <div
                          className={`box ${logo?.position === "bottom-center"
                            ? "box-active"
                            : ""
                            }`}
                          onClick={(e) => logoWaterPos("bottom-center", "logo")}
                        >
                          <span />
                        </div>
                        <div
                          className={`box ${logo?.position === "bottom-right"
                            ? "box-active"
                            : ""
                            }`}
                          onClick={(e) => logoWaterPos("bottom-right", "logo")}
                        >
                          <span />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 mx-auto">
            <div className="style-box-wrapper">
              <div className="row align-items-stretch m-0 justify-content-between">
                <div className="colmn w-colmn col-md-8 col-12">
                  <div className="style-upload-part style-watermark">
                    <h6>Upload Watermark</h6>
                    <div className="style-upload-box">
                      <h6 className="text-center">
                        <span className="mr-1">
                          <i className="fas fa-cloud-upload-alt" />
                        </span>{" "}
                        Upload Your Watermark <br /> (JPG, PNG, JPEG up to 5MB
                        is allowed)
                      </h6>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="inputGroupFile01"
                          onChange={(e) => onWatermarkUpload(e)}
                        />
                      </div>
                    </div>
                    <div className="style-remove-logo mt-2">
                      <div className="row">
                        <div className="col-3 pr-lg-0 d-flex ">
                          <div className="style-remove-cricle">
                            {loadWatermark ? (
                              <i className="fa fa-spinner fa-spin" />
                            ) : (
                              <img
                                src={watermark?.url ? watermark.url : sample}
                                alt
                                className="img-fluid"
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-9 d-flex align-items-center">
                          <div className="remove-logo-switch-btn ">
                            <div className="form-check form-switch p-0">
                              <label
                                className="form-check-label"
                                htmlFor="flexSwitchCheckChecked"
                              >
                                Remove Watermark
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckChecked"
                                value={removeWatermark}
                                onClick={(e) => handleRemoveWatermark(e)}
                                checked={watermark === "" ? true : false}
                                disabled={watermark === "" ? true : false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="colmn col-md-4 col-12">
                  <div className="position-text-box">
                    <div className="text-position-dropdown">
                      <label>Position</label>
                      <div
                        className={`box-wrapper ${watermark === "" ? "disable-click" : ""
                          }`}
                      >
                        <div
                          className={`box ${watermark?.position === "top-left"
                            ? "box-active"
                            : ""
                            }`}
                          onClick={(e) => logoWaterPos("top-left", "watermark")}
                        >
                          <span />
                        </div>
                        <div
                          className={`box ${watermark?.position === "top-center"
                            ? "box-active"
                            : ""
                            }`}
                          onClick={(e) =>
                            logoWaterPos("top-center", "watermark")
                          }
                        >
                          <span />
                        </div>
                        <div
                          className={`box ${watermark?.position === "top-right"
                            ? "box-active"
                            : ""
                            }`}
                          onClick={(e) =>
                            logoWaterPos("top-right", "watermark")
                          }
                        >
                          <span />
                        </div>
                        <div
                          className={`box ${watermark?.position === "center-left"
                            ? "box-active"
                            : ""
                            }`}
                          onClick={(e) =>
                            logoWaterPos("center-left", "watermark")
                          }
                        >
                          <span />
                        </div>
                        <div
                          className={`box ${watermark?.position === "center" ? "box-active" : ""
                            }`}
                          onClick={(e) => logoWaterPos("center", "watermark")}
                        >
                          <span />
                        </div>
                        <div
                          className={`box ${watermark?.position === "center-right"
                            ? "box-active"
                            : ""
                            }`}
                          onClick={(e) =>
                            logoWaterPos("center-right", "watermark")
                          }
                        >
                          <span />
                        </div>
                        <div
                          className={`box ${watermark?.position === "bottom-left"
                            ? "box-active"
                            : ""
                            }`}
                          onClick={(e) =>
                            logoWaterPos("bottom-left", "watermark")
                          }
                        >
                          <span />
                        </div>
                        <div
                          className={`box ${watermark?.position === "bottom-center"
                            ? "box-active"
                            : ""
                            }`}
                          onClick={(e) =>
                            logoWaterPos("bottom-center", "watermark")
                          }
                        >
                          <span />
                        </div>
                        <div
                          className={`box ${watermark?.position === "bottom-right"
                            ? "box-active"
                            : ""
                            }`}
                          onClick={(e) =>
                            logoWaterPos("bottom-right", "watermark")
                          }
                        >
                          <span />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dimmer-light-box mt-4">
          <div className="row m-0">
            <div className="col-md-12 mx-auto p-0">
              <div className="dimmer-wrapper">
                <div className="row align-items-center">
                  <div className="col-6">
                    <div className="dimmer-light d-flex align-items-center">
                      <h6 className="mb-0">Dimmer Light</h6>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="dimmer-switch-btn">
                      <div className="remove-logo-switch-btn ">
                        <div className="form-check form-switch d-flex justify-content-end  p-0 align-items-center">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            value={removeDimmer}
                            onChange={(e) => handleRemoveDimmer(e)}
                            checked={removeDimmer === 1 ? false : true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="dimmer-light-box mt-4">
          <div className="row m-0">
            <div className="col-md-12 mx-auto p-0">
              <div className="dimmer-wrapper">
                <div className="row align-items-center">
                  <div className="col-6">
                    <div className="dimmer-light d-flex align-items-center">
                      <h6 className="mb-0">Duration</h6>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="dimmer-switch-btn">
                      <div className="remove-logo-switch-btn ">
                        <div className="form-check form-switch d-flex justify-content-end  p-0">
                          <input
                            style={{ width: "100%" }}
                            className="form-duration"
                            type="number"
                            min="1"
                            max={`${userDuration.includes(auth.user.email)
                              ? "40"
                              : membership.includes("unlimited")
                                ? "40"
                                : "40"
                              }`}
                            value={selectedLayer.duration}
                            onChange={(e) => addDuration(e, selectedIndex)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="swap-template-desgin mt-4">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="swap-template-wrapper">
                <h6>Swap Templates Design</h6>
                <div className="row justify-content-center gap-2 mt-4">
                  {variants.length > 0
                    ? variants.map((item, index) => {
                      return (
                        <div
                          className="col-sm-5 colmn p-0"
                          key={index}
                          style={{ overflow: "hidden" }}
                        >
                          <div
                            className="swap-template-box d-flex justify-content-center align-items-center cursor-pointer"
                            onClick={() =>
                              changeTempVariation(item.variation_no)
                            }
                          >
                            <div className="search-load-wrapper-blur-bg">
                              <img
                                src={item.thumbnail}
                                alt={item?.name}
                                className="img-fluid"
                              />
                              {index + 1 == selectedLayer.variation_id &&
                                selectedLayer.is_outro === false ? (
                                <div className="slide-active">
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <HoverVideoPlayer
                              videoSrc={item.preview_video}
                              pausedOverlay={
                                <img
                                  src={item.thumbnail}
                                  alt={item?.name}
                                  className="img-fluid"
                                />
                              }
                              loadingOverlay={
                                <div className="loader-center">
                                  <i
                                    style={{ color: "#16d489" }}
                                    className="fa fa-spinner fa-spin mr-2"
                                  />
                                </div>
                              }
                            />
                          </div>
                        </div>
                      );
                    })
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogoWatermark;
