import styled from "styled-components";

export const Portait4Style = styled.div`
width: 100%;
height: 100%;
  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container7 {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .f-white {
    color: #fff;
  }

  .f-pippin {
    color: #ffe0db;
  }

  .f-cinnabar {
    color: #ec4f43;
  }

  .f-cinnabar-transparency {
    color: #f3928b;
  }

  .text-wrapper div {
    font-family: "Nexa", sans-serif;
    line-height: 1em;
    font-weight: 600;
    margin: 0;
    
  }

  .back-layer {
    width: 100%;
    height: 100%;
  }

  .back-layer img,
  .back-layer video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .text-wrapper {
    background: #fff;
    clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
    position: absolute;
    top:26%;
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
  }

  .text-wrapper div {
    width:100%;
    color:#e94f43;
  }
`;
