import styled from "styled-components";

export const Portait5Style = styled.div`
.flex-column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.flex-row-center {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.flex-row-left {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: left;
  justify-content: left;
}

.subflex-left {
  width: 100%;
  text-align: left;
}

.subflex-right {
  width: 100%;
  text-align: right;
}

.subflex-center {
  width: 100%;
  text-align: center;
}
.text-wrapper {
  white-space: pre-wrap;
}
/*.f-arial {*/
/*	font-family: Arial;*/
/*}*/

/*.f-mvboli {*/
/*	font-family: mvboli;*/
/*}*/

/*.f-nexalight {*/
/*	font-family: nexalight;*/
/*}*/

/*.f-nexabold {*/
/*	font-family: nexabold;*/
/*}*/

.f-cyan {
  color: #00fffc;
}

.f-cyan-dark {
  color: #00d1cf;
}

.f-blue {
  color: #005596;
}

.f-white {
  color: #ffffff;
}

.poster {
  position: relative;
  overflow: hidden !important;
}

.poster.nine-by-sixteen {
  width: 1080px;
  height: 1920px;
}

.poster.one-by-one {
  width: 310px;
  height: 310px;
}

.poster.nine-by-sixteen {
  width: 171px;
  height: 304px;
}

.poster.five {
  background-color: #00d1cf;
}

.poster.nine-by-sixteen .sidepanel.five {
  float: left;
  height: 100%;
  width: 0;
  background-color: #005596;
}

.poster.nine-by-sixteen .sidepanel.five .photo-container {
  height: 500px;
  margin-left: 25px;
  width: 0;
  /* overflow: hidden; */
}

.poster.nine-by-sixteen .sidepanel.five .photo {
  position: relative;
  height: 520px;
  width: 520px;
  top: -5px;
}

.poster.nine-by-sixteen.five .text-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  height: 80%;
  /*width: calc(100% - 585px);*/
  box-sizing: border-box;
  overflow: hidden;
  padding: 0;
  bottom: 0;
  margin: auto;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.poster.nine-by-sixteen.five .ta {
  font-size: 80px;
  line-height: 90px;
  margin: 0 0 0 -100%;
  font-weight: bold;
}

.poster.nine-by-sixteen.five .sub-ta {
  font-size: 38px;
  line-height: 50px;
  margin: 0 0 0 -100%;
}

.poster.nine-by-sixteen .sidepanel.five .photo-container {
  height: 68px;
  margin-left: 4px;
}

.poster.nine-by-sixteen .sidepanel.five .photo {
  height: 72px;
  width: 72px;
  top: -1px;
  background-position: -18px center;
}

.poster.nine-by-sixteen.five .text-wrapper {
  width: calc(100% - 90px);
}

.poster.nine-by-sixteen.five .ta {
  font-size: 12px;
  line-height: 14px;
  margin: 0 0 0 -100%;
}

.poster.nine-by-sixteen.five .sub-ta {
  font-size: 7px;
  line-height: 9px;
  margin: 0 0 0 -100%;
}

  .text-wrappers {
    position:absolute;
    width:50%;
    height:100%
  }
  .text-wrappers > div {
    width:100%;
    overflow: hidden;
    margin-bottom: 5%;
  }
`;
