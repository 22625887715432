import styled from "styled-components";

export const Square5Style = styled.div`
width: 100%;
height: 100%;

.container_two_five .img-fluid {
  display: block;
  max-width: 100%;
}

.container_two_five {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
  
  

.container_two_five .back-layer {
  width: 100%;
  height: 100%;
  background: #fe793d;
}

.container_two_five .back-layer img,
.container_two_five .back-layer video {
  opacity: 0.5;
  width: 100%;
  object-fit: cover;
}

.container_two_five .text-wrappers {
  position: absolute;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  left: 0;
  padding: 0 20px;
  height: max-content;
  bottom: 5%;
}


.text-wrappers {
  position:absolute;
  width:100%;
  display: flex;
  align-items: baseline;
  justify-content: end;
}
.text-wrappers > div {
  width:100%;
  overflow: hidden;
}


`;