import styled from "styled-components";

export const Square4Style = styled.div`
width: 100%;
height: 100%;
  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .f-white {
    color: #fff;
  }

  .text-wrapper>div {
   
    width:100%;
  }

  .container6 {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #cc883e;
  }

  .two-layer-bg {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
  }

  .two-layer-bg .col-1 {
    background: #f4c88c;
    flex: 0 0 100%;
    max-width: 0%;
    display: flex;
    align-items: center;
  }

  .two-layer-bg .col-2 {
    background: #cc883e;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 2.5%;
  }

  .col-1 .wrap {
    background: #fff;
    width: 90%;
    height: 90%;
    left: -100%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
  }

  .col-1 .wrap .img-wrapper {
    width: 90%;
    height: 90%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    overflow: hidden;
  }

  .col-1 .wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }

  .text-wrapper {
    position: absolute;
    // left: 0;
    // right: 0;
    top: 0;
    // bottom: 0;
    width: 100%;
    height: 100%;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  


  @media screen and (max-width: 991px) {
    .text-wrapper div {
      line-height: 0.6em;
      width:100%;
    }
  }

  @media screen and (max-width: 640px) {
    .text-wrapper div {
      line-height: 0.4em;
      width:100%;
    }
  }

  @media screen and (max-width: 440px) {
    .text-wrapper div {
      line-height: 0.1em;
      width:100%;
    }
}
`;
