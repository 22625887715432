import React from "react";
import { Regular2Style } from "./css/Regular2Style";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";

const regular_10_2 = (props) => {
  return (
    <Regular2Style>
      <div className="poster sixteen-by-nine lg flex-row-center">
        <TemplateLogo logo={props.logo} />
        <TemplateWatermark watermark={props.watermark} />
        <div className="video-outer">
          <div className="video-container">
            <TemplateImage slideType={props.slideType} file={props.file} />
          </div>
        </div>
        <div className="text-outer flex-column">
          <div className="text-wrapper subflex-right">
            <TemplateText
              id={props.id}
              text={props.text}
              textStyle={props.textStyle}
              editStatus={props.editStatus}
              onInputSlideText={props.onInputSlideText}
            />
          </div>
        </div>
      </div>
    </Regular2Style>
  );
};

export default regular_10_2;
