import styled from "styled-components";

export const Regular3Style = styled.div`
width: 100% !important;
height: 100% !important;

.t3-containerThree .t3-img-fluid {
  display: block;
  max-width: 100%;
}

.t3-containerThree {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.t3-containerThree {
  /* background: #005596; */
  display: flex;
}

.t3-containerThree .d-flex {
  display: flex;
}

/* .img-wrapper .t3-img-fluid {
  opacity: 0.5;
} */

.f-cyan {
  color: #00fffc;
}

.f-white {
  color: #fff;
}

.t3-containerThree div {
  font-family: "Poppins", sans-serif;
  line-height: 1.3;
  font-weight: 500;
  margin: 0;
}

.t3-containerThree .t3-text-wrappers div {
  font-size: 1.8vw;
  line-height: 1.2em;
  text-align: right;
  //left: 100%;
}

.t3-containerThree .t3-text-wrappers {
  width: 100%;
  padding-right: 2vw;
  padding-left: 1vw;
  overflow: hidden;
  max-height: 80%;
}

.t3-containerThree .t3-t-colmn-2 .t3-woman {
  width: 100%;
  height: 100%;
}

.t3-containerThree .t3-t-colmn-1 {
  flex: 0 0 100%;
  max-width: 60%;
  display: flex;
  align-items: center;
  background: #005596;
  z-index: 1;
  /* overflow: hidden; */
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
}

.t3-containerThree .t3-t-colmn-1:after {
  content: "";
  height: 2.5vh;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #00fffc;
}

/* .t3-containerThree .colmn-2  .t3-woman {
  height: 100vh;
  width: 100vw;
  background: url(../images/t3-woman.jpg);
  background-position: calc(100% - 410px);
  background-size: cover;
  background-repeat: no-repeat;
} */

.t3-containerThree .t3-t-colmn-2 .t3-woman img {
  height: 100%;
  width: 100vw;
  object-fit: cover;
  object-position: 40% 0%;
}

.t3-containerThree .t3-t-colmn-2 {
  flex: 0 0 40%;
  max-width: 40%;
  overflow: hidden;
}

@media screen and (max-width: 1366.98px) {
  .t3-containerThree .t3-text-wrappers p {
    /*font-size: 1.4vw;*/
  }
}

@media screen and (max-width: 991.98px) {
  .t3-containerThree .t3-text-wrappers p {
    /*font-size: 2vw;*/
  }
}

@media screen and (max-width: 640.98px) {
  .t3-containerThree .t3-text-wrappers p {
    /*font-size: 3vw;*/
  }
}
`;
