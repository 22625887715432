import React, { useEffect, useState } from "react";
import sample from "../../images/sample.png";
import { slideObject } from "../../global/global"
import axios from "axios";
import { baseURL } from "../../global/global";
import { setAlert } from "../../actions/alert";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { addNewTiles, addOutroData, addOutroImage, deleteOutroLogo, updateOutroData } from "../../actions/videoAction";
import HoverVideoPlayer from "react-hover-video-player";

const Credits = ({ dimension, tilesData }) => {

    const outroSlide = tilesData.find(({ is_outro }) => is_outro === true);
    console.log(outroSlide, 'outor');

    if (outroSlide !== undefined) {
        console.log('has')
    } else {
        console.log('no outro')
    }

    const allowedFiles = ['image/png', 'image/jpg', 'image/jpeg']

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [outroText, setOutroText] = useState('');
    const [outroLogo, setOutroLogo] = useState(false);
    const [outroTemplates, setOutroTemplates] = useState([]);
    const [selectedOutro, setSelectedOutro] = useState(false);
    const [outroUrl, setOutroUrl] = useState(false);
    const [outroThumb, setOutroThumb] = useState(false);
    const [removeOutroLogo, setRemoveOutroLogo] = useState(1);

    const onInputText = (e) => {
        setOutroText(e.target.value);

        selectImage(outroLogo, e.target.value, false);
    }

    const onInputLogo = (e) => {
        let checkOutro = tilesData.findIndex(({ is_outro }) => is_outro === true);

        if (checkOutro >= 0) {
            if (allowedFiles.includes(e.target.files[0].type)) {

                if (e.target.files[0].size < 5000000) {
                    setLoader(true);

                    let formData = new FormData();
                    formData.append('file', e.target.files[0]);
                    formData.append('file_type', 'logo');

                    axios({
                        method: 'POST',
                        url: `${baseURL}upload-file`,
                        data: formData,
                        headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
                    }).then(res => {
                        if (res.data.status) {
                            console.log(res.data, "res.datares.data")
                            setOutroLogo(res.data.data.file_url);
                            selectImage(res.data.data.file_url, outroText);
                        } else {
                            dispatch(setAlert(res.data.message, 'danger'));
                        }
                        setLoader(false);

                    }).catch(error => {
                        setLoader(false);
                    })
                } else {
                    swal("Oops!", "Max allowed size 5MB");
                }
            } else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        } else {
            swal("Oops!", "Please select outro design template");
        }
    }

    const selectImage = (url, outroText) => {
        let data = {
            text: outroText,
            logo: url,
            outro: outroUrl,
            thumbnail: outroThumb
        }

        dispatch(addOutroImage(url));
        dispatch(addOutroData(data));
    }

    const addOutroVideo = (item, id, template_no) => {

        const checkOutro = tilesData.findIndex(({ is_outro }) => is_outro === true);

        setSelectedOutro(id);
        setOutroUrl(item.preview_video);
        setOutroThumb(item.thumbnail);

        if (checkOutro >= 0) {
            let data = {
                variation: template_no
            }
            dispatch(updateOutroData(data));

        } else {
            slideObject.file_url = 'https://backend.clipsreel.io/assets/defualt/logo_default.png';
            slideObject.poster = item.thumbnail;
            slideObject.variation_id = template_no;

            dispatch(addNewTiles(slideObject));
        }


    }

    const handleRemoveLogo = () => {
        if (removeOutroLogo === 1) {
            setRemoveOutroLogo(0)
            dispatch(deleteOutroLogo());
            setOutroLogo(false);
        } else {
            setRemoveOutroLogo(1)
        }
    }

    useEffect(() => {
        axios({
            method: "POST",
            url: `${baseURL}outro-templates`,
            data: { dimension: dimension },
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setOutroTemplates(res.data.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }, [])

    useEffect(() => {
        if (outroSlide !== undefined) {
            if (outroSlide.file_url !== false || outroSlide.file_url !== "") {
                setOutroLogo(true);
            }
        }
    }, [])

    return (
        <>
            <div className="credits-box-sec">
                <div className="row">
                    <div className="col-md-11 mx-auto">
                        <div className="credites-wrapper">
                            {/*<div className="row">*/}
                            {/*    <div className="col-md-12">*/}
                            {/*        <div className="form-group">*/}
                            {/*            <label>Add An Outro Text</label>*/}
                            {/*            <textarea*/}
                            {/*                className="form-control"*/}
                            {/*                placeholder="Add text here"*/}
                            {/*                rows={6}*/}
                            {/*                value={outroText}*/}
                            {/*                onChange={(e) => onInputText(e)}*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="credites-upload-box">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6>Upload Logo</h6>
                                        <div className="credites-uplaod">
                                            <h6 className="text-center">
                                                <span className="mr-1">
                                                    <i className="fas fa-cloud-upload-alt" />
                                                </span>
                                                Upload An Outer Logo <br /> (JPG, PNG, JPEG up to 5MB is allowed)
                                            </h6>
                                            <div className="custom-file">
                                                <input
                                                    type="file"
                                                    className="custom-file-input"
                                                    accept="image/*"
                                                    onChange={(e) => onInputLogo(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-4">
                                        <div className="outer-images">
                                            {
                                                loader ? <i className="fa fa-spinner fa-spin" />
                                                    : <img
                                                        src={outroSlide !== undefined ? outroSlide.file_url !== false ? outroSlide.file_url : sample : sample}
                                                        alt
                                                        className="img-fluid"
                                                    />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-8 d-flex align-items-center justify-content-center">
                                        <div className="remove-outer-logo">
                                            <div className="form-check form-switch p-0 d-flex justify-content-around align-items-center">
                                                <h6 className="m-0">
                                                    <label className="form-check-label">
                                                        Remove Logo
                                                    </label>
                                                </h6>
                                                <h6 className="m-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={outroLogo === false ? true : false}
                                                        disabled={outroLogo === false ? true : false}
                                                        onChange={(e) => handleRemoveLogo(e)}
                                                    />
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="outro-template-box mt-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6>Outro Template Design</h6>
                                        <div className="outer-template-wrapper">
                                            <div className="row mt-3">
                                                {
                                                    outroTemplates.length > 0 ?
                                                        outroTemplates.slice(0, 4).map((item, index) => {
                                                            return (
                                                                <div className="col-sm-6 colmn" key={index}>
                                                                    <div className="outer-images-box d-flex justify-content-center align-items-center cursor-pointer" onClick={() => addOutroVideo(item, item.id, item.template_no)}>
                                                                        <HoverVideoPlayer
                                                                            videoSrc={item.preview_video}
                                                                            pausedOverlay={
                                                                                <img src={item.thumbnail} width="100%" height="100%" />
                                                                            }
                                                                            loadingOverlay={
                                                                                <div className="loader-center"><i style={{ color: "#16d489" }} className="fa fa-spinner fa-spin mr-2" /></div>
                                                                            }
                                                                        />
                                                                        {
                                                                            outroSlide !== undefined ?
                                                                                outroSlide.variation_id == item.template_no ?
                                                                                    <div className="slide-active">
                                                                                        <i className="fa fa-check" aria-hidden="true"></i>
                                                                                    </div>
                                                                                    : ''
                                                                                : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(Credits);