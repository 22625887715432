import './responsive.css'
import './App.css';
import './WhiteLabel.css';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/auth/Login";
import Dashboard from "./components/dashboard/Dashboard";
import { PrivateRoute } from "./components/PrivateRoute";
import ForgetPassword from "./components/auth/ForgetPassword";
import ResetPassword from "./components/auth/ResetPassword";
import Profile from "./components/user/Profile";
import Projects from "./components/project/Projects";
import Privacy from "./components/user/Privacy";
import AccountManagement from "./components/user/AccountManagement";
import Upgrades from "./components/user/Upgrades";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { loadUser, onFetchFont } from "./actions/authAction";
import HelpSupport from "./components/support/HelpSupport";
import Training from "./components/support/Training";
import TrainingArticles from "./components/support/TrainingArticles";
import MasterLogin from "./components/MasterLogin";
import Integration from "./components/integration/Integration";
import Reseller from "./components/user/Reseller";
import AddScript from "./components/script/AddScript";
import Editor from "./components/editor/Editor";
import Templates from "./components/script/Templates";
import ReelMerge from "./components/reel/ReelMerge";
import WhiteLabelTabs from './components/WhiteLabel/WhiteLabelTabs';
import { onFetchWhiteLabel } from './actions/WhiteLabelActions';



function App() {

    const dispatch = useDispatch();
    const fetchUser = () => {
        dispatch(loadUser())
    }
    const rebrand = useSelector(state => state.rebrand.data)

    const [loader, setLoader] = useState({
        fetch: true
    })

    useEffect(() => {
        dispatch(loadUser())
    }, [])
    const fetchFonts = () => {
        dispatch(onFetchFont(false, true))
    }
    const fetchWhiteLabel = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let obj = { domain: window.location.host }
        dispatch(onFetchWhiteLabel(obj, false, loader, setLoader))
    }
    useEffect(() => {
        if (rebrand && rebrand.color?.first && rebrand.color?.second) {
            document.documentElement.style.setProperty('--primary', rebrand.color.first);
            document.documentElement.style.setProperty('--secondary', rebrand.color.second);
        }
    }, [rebrand])
    useEffect(() => {
        fetchUser()
        fetchFonts()
        fetchWhiteLabel()
    }, [])

    return (
        <div className="App">
            {loader.fetch ? <div className="loader-sec">
                <div className="loader" />
            </div> :
                <Router>
                    <Switch>
                        <Route exact path="/" component={Login} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/forgot-password" component={ForgetPassword} />
                        <Route exact path="/reset-password" component={ResetPassword} />
                        <Route exact path="/master-login" component={MasterLogin} />

                        <PrivateRoute exact path="/dashboard" component={Dashboard} />
                        <PrivateRoute exact path="/profile" component={Profile} />
                        <PrivateRoute exact path="/projects" component={Projects} />
                        <PrivateRoute exact path="/privacy" component={Privacy} />
                        <PrivateRoute exact path="/account-management" component={AccountManagement} />
                        <PrivateRoute exact path="/reseller" component={Reseller} />
                        <PrivateRoute exact path="/upgrades" component={Upgrades} />
                        <PrivateRoute exact path="/help-and-support" component={HelpSupport} />
                        <PrivateRoute exact path="/training" component={Training} />
                        <PrivateRoute exact path="/support-article" component={TrainingArticles} />
                        <PrivateRoute exact path="/integration" component={Integration} />
                        <PrivateRoute exact path="/templates" component={Templates} />

                        <PrivateRoute exact path="/add-script" component={AddScript} />
                        <PrivateRoute exact path="/editor" component={Editor} />
                        <PrivateRoute exact path="/reel-merge" component={ReelMerge} />
                        <PrivateRoute exact path="/white-label" component={WhiteLabelTabs} />
                        {/* <Route exact path="/white-label" element={<PrivateRoute><WhiteLabelTabs /> </PrivateRoute>} /> */}



                    </Switch>
                </Router>}
        </div>
    );
}

export default App;
