import { init } from "./common";
import { gsap, Power1, Power2, Power3, Power4, Linear } from "gsap";

export const regular_5_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 3, scale: 1.2 },1);
  localTimeline.fromTo(".text-wrapper div", {marginLeft:'-200%',autoAlpha:0}, {marginLeft:0, autoAlpha: 1,duration: 1,stagger:0.4},+2);
  return localTimeline;
};
export const regular_5_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", {duration: 3, objectPosition: "50% 45%", ease: "none",},1);

  localTimeline.fromTo(".text-wrapper div", {
    marginTop:'-200%',
    autoAlpha:0
  },{
    duration: 1.5,
    marginTop: 0,
    autoAlpha: 1,
  });
  return localTimeline;
};

export const regular_5_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", {
    duration: 3,
    objectPosition: "50% 60%",
    ease: "none",
  },1);

  localTimeline.to(".text-wrapper", {
    duration: 1,
    clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
    ease: "none"
  },1);
  return localTimeline;
};

export const regular_5_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 3, scale: 1.2 },1);
  localTimeline.fromTo(".text-wrapper", {left:'-100%'},{
    duration: 1,
    left:'20%',
  },1);
  localTimeline.fromTo(".text-wrapper div", {autoAlpha:0,marginLeft:'-200%'},{
    autoAlpha:1,
    marginLeft:0,
    duration: 0.5,
    opacity: 1,
    stagger: 0.2
  });
  return localTimeline;
};

export const regular_5_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 3, scale: 1.1 },1);
  localTimeline.to(".front-layer", { duration: 1, width: "50%" },1);
  localTimeline.to(".text-wrappers", {duration: 1, left: 0},1);
  localTimeline.fromTo(".text-wrappers div", {marginLeft:'-200%',autoAlpha:0},{autoAlpha:1,marginLeft:'0',duration: 1,},1);
  return localTimeline;
};

export const regular_5_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".back-layer img", { duration: 3, scale: 1.2 },1);
  localTimeline.fromTo(".text-wrapper div", {marginLeft:'-200%',autoAlpha:0},{duration: 1, autoAlpha:1, marginLeft:0}, 1.5);
  return localTimeline;
};

export const regular_5_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".back-layer img", { duration: 3, scale: 1.2 },1);
  localTimeline.fromTo(".text-wrapper", {bottom:'-100%'},{duration: 1, bottom: 0},+2);
  localTimeline.fromTo(".text-wrapper div", {autoAlpha:0},{autoAlpha:1,duration: 1},+3);
  return localTimeline;
};
export const regular_5_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".back-layer img", { duration: 3, scale: 1.2 },1);
  localTimeline.fromTo(".text-wrapper", {top:'-200%'},{duration: 1, top: 0},+2);
  localTimeline.fromTo(".text-wrapper div", {autoAlpha:0},{autoAlpha:1,duration: 1},+3);
  return localTimeline;
};

export const square_5_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 3, scale: 1.2 },1);
  localTimeline.fromTo(".text-wrapper div", {marginLeft:'-200%',autoAlpha:0}, {marginLeft:0, autoAlpha: 1,duration: 1,stagger:0.4},+2);
  return localTimeline;
};
export const square_5_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", {duration: 3, objectPosition: "50% 45%", ease: "none",},1);

  localTimeline.fromTo(".text-wrapper div", {
    marginTop:'-200%',
    autoAlpha:0
  },{
    duration: 1.5,
    marginTop: 0,
    autoAlpha: 1,
  });
  return localTimeline;
};

export const square_5_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", {
    duration: 3,
    objectPosition: "50% 60%",
    ease: "none",
  },1);

  localTimeline.to(".text-wrapper", {
    duration: 1,
    clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
    ease: "none"
  },1);
  return localTimeline;
};

export const square_5_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 3, scale: 1.2 },1);
  localTimeline.fromTo(".text-wrapper", {left:'-100%'},{
    duration: 1,
    left:'20%',
  },1);
  localTimeline.fromTo(".text-wrapper div", {autoAlpha:0,marginLeft:'-200%'},{
    autoAlpha:1,
    marginLeft:0,
    duration: 0.5,
    opacity: 1,
    stagger: 0.2
  });
  return localTimeline;
};

export const square_5_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 3, scale: 1.1 },1);
  localTimeline.to(".front-layer", { duration: 1, width: "50%" },1);
  localTimeline.to(".text-wrappers", {duration: 1, left: 0},1);
  localTimeline.fromTo(".text-wrappers div", {marginLeft:'-200%',autoAlpha:0},{autoAlpha:1,marginLeft:'0',duration: 1,},1);
  return localTimeline;
};

export const square_5_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 3, scale: 1.2 },1);
  localTimeline.fromTo(".text-wrapper div", {marginLeft:'-200%',autoAlpha:0},{duration: 1, autoAlpha:1, marginLeft:0}, 1.5);
  return localTimeline;
};

export const square_5_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".back-layer img", { duration: 3, scale: 1.2 },1);
  localTimeline.fromTo(".text-wrapper", {bottom:'-100%'},{duration: 1, bottom: 0},+2);
  localTimeline.fromTo(".text-wrapper div", {autoAlpha:0},{autoAlpha:1,duration: 1,stagger:0.4},+3);
  return localTimeline;
};
export const square_5_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".back-layer img", { duration: 3, scale: 1.2 },1);
  localTimeline.fromTo(".text-wrapper", {top:'-200%'},{duration: 1, top: 0},+2);
  localTimeline.fromTo(".text-wrapper div", {autoAlpha:0},{autoAlpha:1,duration: 1,stagger:0.4},+3);
  return localTimeline;
};

export const story_5_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 3, scale: 1.2 },1);
  localTimeline.fromTo(".text-wrapper div", {marginLeft:'-200%',autoAlpha:0}, {marginLeft:0, autoAlpha: 1,duration: 1,stagger:0.4},+2);
  return localTimeline;
};
export const story_5_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".back-layer img", {duration: 3, objectPosition: "50% 45%", ease: "none",},1);

  localTimeline.fromTo(".text-wrapper div", {
    marginTop:'-200%',
    autoAlpha:0
  },{
    duration: 1.5,
    marginTop: 0,
    autoAlpha: 1,
  });

  return localTimeline;
};

export const story_5_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".back-layer img", {
    duration: 3,
    objectPosition: "50% 60%",
    ease: "none",
  },1);

  localTimeline.fromTo(".text-wrapper",{ marginTop:'200px'}, {
    duration: 1,
    clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
    ease: "none",
    marginTop:'0'
  },1);
  return localTimeline;
};

export const story_5_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".back-layer img", { duration: 3, scale: 1.2 },1);
  localTimeline.fromTo(".text-wrapper", {left:'-100%'},{
    duration: 1,
    left:'5%',
  },1);
  localTimeline.fromTo(".text-wrapper div", {autoAlpha:0,marginLeft:'-200%'},{
    autoAlpha:1,
    marginLeft:0,
    duration: 0.5,
    opacity: 1,
    stagger: 0.2
  });
  return localTimeline;
};

export const story_5_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".back-layer img", { duration: 3, scale: 1.1 },1);
  localTimeline.to(".front-layer", { duration: 1, width: "50%" },1);
  localTimeline.to(".text-wrappers", {duration: 1, left: 0},1);
  localTimeline.fromTo(".text-wrappers", {marginLeft:'-200%',autoAlpha:0},{autoAlpha:1,marginLeft:'0',duration: 1,},+1);
  localTimeline.fromTo(".text-wrappers div", {marginLeft:'-200%',autoAlpha:0},{autoAlpha:1,marginLeft:'0',duration: 1,},+7);
  return localTimeline;
};

export const story_5_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 3, scale: 1.2 },1);
  localTimeline.fromTo(".text-wrapper div", {marginLeft:'-200%',autoAlpha:0},{duration: 1, autoAlpha:1, marginLeft:0}, 1.5);
  return localTimeline;
};

export const story_5_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".back-layer img", { duration: 3, scale: 1.2 },1);
  localTimeline.fromTo(".text-wrapper", {bottom:'-100%'},{duration: 1, bottom: 0},+2);
  localTimeline.fromTo(".text-wrapper div", {autoAlpha:0},{autoAlpha:1,duration: 1, stagger:0.4},+3);
  return localTimeline;
};
export const story_5_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".back-layer img", { duration: 3, scale: 1.2 },1);
  localTimeline.fromTo(".text-wrapper", {top:'-200%'},{duration: 1, top: 0},+2);
  localTimeline.fromTo(".text-wrapper div", {autoAlpha:0},{autoAlpha:1,duration: 1, stagger:0.4},+3);
  return localTimeline;
};
