import styled from "styled-components";

export const Square8Style = styled.div`
  width:100%;
  height:100%;
  .container3 {
    width:100%;
    height:100%;
    overflow: hidden;
    background: #a1dffb;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .f-red {
    color: #c33124;
  }

  .f-peach {
    color: #f98365;
  }

  .f-white {
    color: #fff;
  }

  .f-blue {
    color: #005596;
  }

  .circle {
    clip-path: circle(0);
    width: 100%;
    height: 100%;
  }

  .circle img,
  .circle video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 45% 50%;
  }

  .container3 .col {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .text-wrapper {
    width:100%;
    height:100%;
  }
  .col{
    padding:0
  }
`;
