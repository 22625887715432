import styled from "styled-components";

export const Portait2Style = styled.div`
  width:100%;
  height:100%;
  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container4 {
    width:100%;
    height:100%;
    overflow: hidden;
  }

  .f-peach {
    color: #f98365;
  }

  .f-white {
    color: #fff;
  }

  .f-blue {
    color: #005596;
  }

  .back-layer {
    width:100%;
    height:100%;
    clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
    transition: 1.5s ease;
  }

  .back-layer img {
    transform: scale(1.1);
    object-fit: cover;
  }

  .text-wrapper {
    top: 0;
    position: absolute;
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
    //-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
    //clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
    //transition: 0.5s ease;
  }

  .text-wrapper > div {
    width:100%
  }
`;
