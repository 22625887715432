import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import UserRows from "./UserRows";
import { FaSpinner } from "react-icons/fa";
import { fetchData } from "../../actions/CommonActions";

const WhiteLabelUser = () => {
    const dispatch = useDispatch()
    const [users, setUsers] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })

    const fetchUsers = () => {
        let obj = {}
        dispatch(fetchData("fetch-wl-users", obj, setUsers, loader, setLoader))
    }

    useEffect(() => {
        fetchUsers()
    }, [])
    return (
        <>
            <div className="White_label_wrap">

                <div className="white-titleBar-left">
                    <h2>Users</h2>
                    <p>Create and Manage User</p>
                </div>

                <div className="white-block">

                    <table className="table table-bordered white-table">
                        <tr>
                            <th>Name</th>
                            <th className="text-end">Action</th>
                        </tr>
                        {users.length > 0 ?
                            users.map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <UserRows
                                            curElem={curElem}
                                            fetchUsers={fetchUsers}
                                            users={users}
                                            setUsers={setUsers}
                                        />
                                    </React.Fragment>
                                )
                            })
                            :
                            <tr className="text-center mt-2">
                                <td colSpan={2}>
                                    {loader.fetch ?
                                        <FaSpinner size={25} className="spin p-0" /> : "You do not have users created yet!"}
                                </td>
                            </tr>
                        }
                    </table>

                </div>

            </div >

        </>
    )
}

export default WhiteLabelUser;