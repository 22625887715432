import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import {useHistory} from "react-router-dom";
import axios from "axios";
import Alert from "../Alert";
import {setAlert} from "../../actions/alert";
import AudioFiles from "./AudioFiles";

const SonorityAudio = ({currentUrl, setCurrentUrl, selectedAudio, setSelectedAudio}) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const socialData = useSelector(state => state.social.sonority);

    const [loader, setLoader] = useState(false);
    const [showDelete ,setShowDelete] = useState(false);
    const [musicData, setMusicData] = useState(false);

    const connectSonority = () => {
        setShowDelete(true);
    }

    const onConfirm = () => {
        history.push('/integration')
    }

    const fetchSonorityMedia = () => {
        setLoader(true);

        axios({
            method: 'POST',
            url: 'https://regis12.amzitrage.com/fetch-all',
            data: {},
            headers: {'Content-Type': 'application/json','Authorization': socialData[0].token}
        }).then(res=>{
            if(res.data.status === true){
                setMusicData(res.data.data);
            }else{
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        }).catch(err=>{
            setLoader(false);
        })
    }

    useEffect(()=>{
        if(socialData.length > 0) {
            fetchSonorityMedia();
        }

        return () => {
            setMusicData(false);
            console.log('unmount sonority');
        }
    },[])

    return(
        <>
            <Alert/>
            <div className="row m-0" style={{height: "auto"}}>
                {
                    socialData.length > 0 ? <div className="alert alert-primary alert-height" role="alert">
                            This music will apply to all slides
                        </div>
                    : <div className="alert alert-primary alert-height" role="alert">
                            You haven't connected your Sonority account yet. Click <a className="custom-anchor" onClick={()=>connectSonority()}>Here</a> to connect and use those audios
                        </div>
                }
                {
                    loader ?
                        <div className="col-md-12">
                            <p className="text-center"><i className={`fa fa-spinner fa-spin`} /> fetching music, please wait </p>
                        </div> :''
                }
                {
                    loader ? '' : musicData !== false ? <h6>Audio Tracks</h6> : ''
                }
                {
                    musicData !== false ?
                        musicData.merge.map((item,index)=>{
                            return(
                                <AudioFiles
                                    title={item.name}
                                    url={item.output_audio}
                                    currentUrl={currentUrl}
                                    setCurrentUrl={setCurrentUrl}
                                    selectedAudio={selectedAudio}
                                    setSelectedAudio={setSelectedAudio}
                                    key={index} />
                            )
                        })
                    : ''
                }
                {
                    loader ? '' : musicData !== false ? <h6>Music Tracks</h6> : ''
                }
                {
                    musicData !== false ?
                        musicData.music.map((item,index)=>{
                            return(
                                <AudioFiles
                                    title={item.title}
                                    url={item.output_audio}
                                    currentUrl={currentUrl}
                                    setCurrentUrl={setCurrentUrl}
                                    selectedAudio={selectedAudio}
                                    setSelectedAudio={setSelectedAudio}
                                    key={index} />
                            )
                        })
                        : ''
                }
                {
                    loader ? '' : musicData !== false ? <h6>Voice Tracks</h6> : ''
                }
                {
                    musicData !== false ?
                        musicData.tts.map((item,index)=>{
                            return(
                                <AudioFiles
                                    title={item.title}
                                    url={item.output_audio}
                                    currentUrl={currentUrl}
                                    setCurrentUrl={setCurrentUrl}
                                    selectedAudio={selectedAudio}
                                    setSelectedAudio={setSelectedAudio}
                                    key={index} />
                            )
                        })
                        : ''
                }

            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText="Connect"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={()=>onConfirm()}
                onCancel={()=>setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                Please note that navigating from this page will discard all your customizations. Make sure you have saved the customizations.
            </SweetAlert>
        </>
    )
}

export default SonorityAudio;