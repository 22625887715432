import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import axios from "axios";
import { baseURL } from "../../global/global";
import { useDispatch, useSelector } from "react-redux";
import HoverVideoPlayer from "react-hover-video-player";
import { updateSlideImage, updateSlideVideo } from "../../actions/videoAction";
import { setAlert } from "../../actions/alert";
import Alert from "../Alert";
import SweetAlert from "react-bootstrap-sweetalert";
import FileCrop from "./FileCrop";

const UserLibrary = ({ selectedIndex, dimension }) => {

    let allowedExt = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4'];

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const [loader, setLoader] = useState(false);
    const [percent, setPercent] = useState(0);

    const [cropModel, setCropModel] = useState(false);
    const [cropImg, setCropImg] = useState('');
    const [cropIndex, setCropIndex] = useState('');

    const [uploadedImage, setUploadedImage] = useState([]);
    const [uploadedVideo, setUploadedVideo] = useState([]);

    const [checkImage, setCheckImage] = useState(false);
    const [checkVideo, setCheckVideo] = useState(false);

    const [showDelete, setShowDelete] = useState(false);
    const [mediaId, setMediaId] = useState(false);

    const openDeletePopUp = (id) => {
        setMediaId(id);
        setShowDelete(true);
    }

    const onConfirm = () => {
        removeMedia(mediaId)
    }

    const handleCrop = (image, index) => {
        console.log(image);
        setCropModel(true);
        setCropImg(image);
        setCropIndex(index);
    }

    const fetchUserLibrary = () => {
        axios({
            method: 'POST',
            url: `${baseURL}user-library`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setUploadedImage(res.data.data.images);

                setUploadedVideo(res.data.data.videos);
            }
        }).catch(err => {

        })
    }

    const selectImage = (image, index) => {
        setCheckImage(index);

        let jsonData = {
            file_url: `${baseURL}${image.file}`,
            poster: `${baseURL}${image.file}`,
            index: selectedIndex

        }

        dispatch(updateSlideImage(jsonData))


    }

    const selectVideo = (image, index) => {
        setCheckImage(index);

        let jsonData = {
            file_url: `${baseURL}${image.file}`,
            poster: `${baseURL}${image.video_screenshot}`,
            index: selectedIndex,
            type: 'video'
        }

        dispatch(updateSlideVideo(jsonData))
    }

    const removeMedia = (id) => {
        let formData = new FormData();
        formData.append('id', id);

        axios({
            method: 'POST',
            url: `${baseURL}delete-media-file`,
            data: formData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            setShowDelete(false);
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                fetchUserLibrary();
            } else {
                dispatch(setAlert(res.data.message, 'dnger'));
            }
        }).catch(err => {
            dispatch(setAlert('Getting server error', 'dnger'));
            setShowDelete(false);
        })
    }

    const onInputFile = (e) => {

        if (e.target.files[0] !== undefined) {
            if (allowedExt.includes(e.target.files[0].type)) {

                let allowedSize = 5000000;
                let type = "image";
                if (e.target.files[0].type === "video/mp4") {
                    allowedSize = 20000000;
                    type = "video";
                }

                if (e.target.files[0].size < allowedSize) {
                    setLoader(true);

                    const formData = new FormData();
                    formData.append('file_type', type);
                    formData.append('file', e.target.files[0])

                    const options = {
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            let percent = Math.floor((loaded * 100) / total);

                            setPercent(percent);
                        },
                        headers: {
                            'Authorization': auth.token
                        }
                    }

                    axios.post(`${baseURL}upload-file`, formData, options).then(res => {
                        if (res.data.status) {
                            fetchUserLibrary();
                        }
                        setLoader(false);
                        setPercent(0);
                    })
                } else {
                    swal("Oops!", "Max allowed size for image 5MB for video 20MB");
                }

            } else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }

    useEffect(() => {
        fetchUserLibrary();
    }, [])

    return (
        <>
            <Alert />
            <div className="row mt-2">
                <div className="col-md-12">
                    <div className="media-upload-box">
                        <button type="button" className="form-control">
                            <span className="mr-1">
                                <i className="fas fa-cloud-upload-alt" />
                            </span>{" "}
                            Upload Media
                        </button>
                        <div className="custom-file">
                            <input type="file" className="custom-file-input" id="inputGroupFile01"
                                accept="image/*, video/*"
                                onChange={(e) => onInputFile(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                loader ?
                    <div className="progress mt-4">
                        <div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, backgroundColor: "#10ca9c" }} aria-valuenow={`${percent}`}
                            aria-valuemin="0" aria-valuemax="100">{percent}%
                        </div>
                    </div>
                    : ''
            }
            <div className="search-load-box mt-4" style={{ marginBottom: "80px" }}>
                <div className="row gap-3 justify-content-center">
                    {
                        uploadedImage.length > 0 ?
                            uploadedImage.map((image, index) => {
                                return (
                                    <div className="colmn col-5 mb-0" key={index}>
                                        <div className="search-load d-flex justify-content-center align-items-center cursor-pointer" style={{ position: "relative" }}>
                                            <div className="search-load-wrapper-blur-bg">
                                                <img src={`${baseURL}${image.file}`} alt="uploaded image" width="100%" height="100%" onClick={() => selectImage(image, image.id)} />
                                            </div>
                                            <div className="search-load-wrapper">
                                                <img src={`${baseURL}${image.file}`} alt="uploaded image" width="100%" height="100%" onClick={() => selectImage(image, image.id)} />
                                                {
                                                    checkImage === image.id ?
                                                        <div className="slide-active">
                                                            <i className="fa fa-check" aria-hidden="true"></i>
                                                        </div>
                                                        : ''
                                                }
                                                <div className="delete-media" onClick={() => openDeletePopUp(image.id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg>
                                                </div>
                                                {/*<div className="delete-media" style={{left: "0", width: "40px"}} onClick={()=>handleCrop(`${baseURL}${image.file}`, selectedIndex)}>*/}
                                                {/*    <i className="fa fa-crop"></i>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            : ''
                    }

                    {
                        uploadedVideo.length > 0 ?
                            uploadedVideo.map((image, index) => {
                                return (
                                    <div className="colmn col-5 mb-0" key={index}>
                                        <div className="search-load d-flex justify-content-center align-items-center cursor-pointer" onClick={() => selectVideo(image, image.id)}>
                                            <div className="search-load-wrapper-blur-bg">
                                                <img src={`${baseURL}${image.video_screenshot}`} alt="uploaded image" width="100%" height="100%" onClick={() => selectVideo(image, image.id)} style={{ objectFit: "contain" }} />
                                            </div>
                                            <HoverVideoPlayer
                                                videoSrc={`${baseURL}${image.file}`}
                                                pausedOverlay={
                                                    <img src={`${baseURL}${image.video_screenshot}`} alt="uploaded image" width="100%" height="100%" onClick={() => selectVideo(image, image.id)} style={{ objectFit: "cover" }} />
                                                }
                                                loadingOverlay={
                                                    <div className="loader-center">
                                                        <i className="fa fa-spinner fa-spin mr-2" />
                                                    </div>
                                                }
                                            />
                                            {
                                                checkImage === image.id ?
                                                    <div className="slide-active">
                                                        <i className="fa fa-check" aria-hidden="true"></i>
                                                    </div>
                                                    : ''
                                            }
                                            <div className="delete-media" onClick={() => openDeletePopUp(image.id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            : ''
                    }
                </div>

                {
                    cropModel ? <FileCrop selectedIndex={cropIndex} dimension={dimension} setCropModel={setCropModel} image={cropImg} /> : ''
                }

            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText="Delete"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={() => onConfirm()}
                onCancel={() => setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                You want to delete this file
            </SweetAlert>
        </>
    )
}

export default React.memo(UserLibrary);