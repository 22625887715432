import { init } from "./common";
import { gsap, Power1, Power2, Power3, Power4, Linear } from "gsap";

export const regular_7_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".back-layer",
    {
      duration: 3,
      scale: 1.2,
    },
    1
  );
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 0.4,
    },
    +2
  );
  return localTimeline;
};
export const regular_7_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 1,
    }
  );
  localTimeline.to(
    ".shape-wrap",
    { duration: 2, left: "-65%", ease: "expo.out" },
    1.5
  );
  return localTimeline;
};

export const regular_7_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 1,
    }
  );
  localTimeline.to(
    ".shape-wrap",
    { duration: 2, right: "-65%", ease: "expo.out" },
    1.5
  );
  return localTimeline;
};

export const regular_7_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".text-wrapper",
    { duration: 1, clipPath: "polygon(0 100%, 100% 100%, 100% 0%, 0 0%)" },
    0.5
  );
  localTimeline.fromTo(
    ".text-wrapper > div",
    { autoAlpha: 0 },
    {
      autoAlpha: 1,
      duration: 1,
      clipPath: "polygon(0 100%, 100% 100%, 100% 0%, 0 0%)",
    },
    1.1
  );
  return localTimeline;
};

export const regular_7_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".ellipse",
    { marginLeft: "-200%" },
    { duration: 0.5, marginLeft: "-100%" },
    1
  );
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: "0" },
    { autoAlpha: 1, stagger: 1 },
    0.5
  );
  localTimeline.fromTo(
    ".vid-wrapper",
    { marginLeft: "-200px" },
    {
      duration: 0.5,
      marginLeft: "50px",
      ease: "none",
      onComplete() {
        localTimeline.to(
          ".top-right-to-bottom-right",
          {
            duration: 0.3,
            height: "100%",
            ease: "none",
            onComplete() {
              localTimeline.to(".bottom-right-to-bottom-left", {
                duration: 0.3,
                width: "100%",
                ease: "none",
                onComplete() {
                  localTimeline.to(".bottom-left-to-top-left", {
                    duration: 0.3,
                    height: "100%",
                    ease: "none",
                    onComplete() {
                      localTimeline.to(".top-left-to-top-right", {
                        duration: 0.3,
                        width: "100%",
                        ease: "expo.out",
                      });
                    },
                  });
                },
              });
            },
          },
          2
        );
      },
    },
    0.3
  );
  return localTimeline;
};

export const regular_7_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".ellipse",
    { marginRight: "-200%" },
    { duration: 0.5, marginRight: "-100%" }
  );
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { autoAlpha: 1, stagger: 1 },
    0.5
  );
  localTimeline.fromTo(
    ".vid-wrapper",
    { marginLeft: "200px" },
    {
      duration: 0.5,
      marginLeft: "-10px",
      ease: "none",
      onComplete() {
        localTimeline.to(
          ".top-right-to-bottom-right",
          {
            duration: 0.3,
            height: "100%",
            ease: "none",
            onComplete() {
              localTimeline.to(".bottom-right-to-bottom-left", {
                duration: 0.3,
                width: "100%",
                ease: "none",
                onComplete() {
                  localTimeline.to(".bottom-left-to-top-left", {
                    duration: 0.3,
                    height: "100%",
                    ease: "none",
                    onComplete() {
                      localTimeline.to(".top-left-to-top-right", {
                        duration: 1,
                        width: "100%",
                        ease: "expo.out",
                      });
                    },
                  });
                },
              });
            },
          },
          1
        );
      },
    },
    0.3
  );
  return localTimeline;
};

export const regular_7_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "-200px" },
    { duration: 1, stagger: 0.2, marginLeft: 0 },
    0.2
  );
  localTimeline.to(".shape-wrap", {
    duration: 1.5,
    left: "-20%",
    ease: "expo.out",
  });
  return localTimeline;
};

export const regular_7_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.from(
    ".text-wrapper div",
    { marginRight: "-200px" },
    { duration: 1, stagger: 0.2, right: 0, marginRight: "0" },
    0.2
  );
  localTimeline.to(".shape-wrap", {
    duration: 1.5,
    left: "15%",
    ease: "expo.out",
  });
  return localTimeline;
};

export const square_7_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".back-layer",
    {
      duration: 3,
      scale: 1.2,
    },
    1
  );
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 0.4,
    },
    +2
  );
  return localTimeline;
};
export const square_7_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 1,
    }
  );
  localTimeline.fromTo(
    ".shape-wrap",{left:'12%'},
    { duration: 2, left: "-60%", ease: "expo.out" },
    1.5
  );
  return localTimeline;
};

export const square_7_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 1,
    }
  );
  localTimeline.to(
    ".shape-wrap",
    { duration: 2, right: "-65%", ease: "expo.out" },
    1.5
  );
  return localTimeline;
};

export const square_7_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".text-wrapper",
    { duration: 1, clipPath: "polygon(0 100%, 100% 100%, 100% 0%, 0 0%)" },
    0.5
  );
  localTimeline.to(
    ".text-wrapper div",
    { duration: 1, clipPath: "polygon(0 100%, 100% 100%, 100% 0%, 0 0%)" },
    1.1
  );
  return localTimeline;
};

export const square_7_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".ellipse",
    { marginLeft: "-500%" },
    { duration: 0.5, marginLeft: "-170%" },
    1
  );
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: "0" },
    { autoAlpha: 1, stagger: 1 },
    0.5
  );
  localTimeline.fromTo(
    ".vid-wrapper",
    { marginLeft: "-200px" },
    {
      duration: 0.5,
      marginLeft: "35px",
      ease: "none",
      onComplete() {
        localTimeline.to(
          ".top-right-to-bottom-right",
          {
            duration: 0.3,
            height: "100%",
            ease: "none",
            onComplete() {
              localTimeline.to(".bottom-right-to-bottom-left", {
                duration: 0.3,
                width: "100%",
                ease: "none",
                onComplete() {
                  localTimeline.to(".bottom-left-to-top-left", {
                    duration: 0.3,
                    height: "100%",
                    ease: "none",
                    onComplete() {
                      localTimeline.to(".top-left-to-top-right", {
                        duration: 0.3,
                        width: "100%",
                        ease: "expo.out",
                      });
                    },
                  });
                },
              });
            },
          },
          2
        );
      },
    },
    0.3
  );
  return localTimeline;
};

export const square_7_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".ellipse",
    { marginRight: "-500%" },
    { duration: 0.5, marginRight: "-170%" }
  );
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { autoAlpha: 1, stagger: 1 },
    0.5
  );
  localTimeline.fromTo(
    ".vid-wrapper",
    { marginLeft: "200px" },
    {
      duration: 0.5,
      marginLeft: "25px",
      ease: "none",
      onComplete() {
        localTimeline.to(
          ".top-right-to-bottom-right",
          {
            duration: 0.3,
            height: "100%",
            ease: "none",
            onComplete() {
              localTimeline.to(".bottom-right-to-bottom-left", {
                duration: 0.3,
                width: "100%",
                ease: "none",
                onComplete() {
                  localTimeline.to(".bottom-left-to-top-left", {
                    duration: 0.3,
                    height: "100%",
                    ease: "none",
                    onComplete() {
                      localTimeline.to(".top-left-to-top-right", {
                        duration: 1,
                        width: "100%",
                        ease: "expo.out",
                      });
                    },
                  });
                },
              });
            },
          },
          1
        );
      },
    },
    0.3
  );
  return localTimeline;
};

export const square_7_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "-200px" },
    { duration: 1, stagger: 0.2, marginLeft: 0 },
    0.2
  );
  localTimeline.to(".shape-wrap", {
    duration: 1.5,
    left: "-20%",
    ease: "expo.out",
  });
  return localTimeline;
};
export const square_7_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.from(
    ".text-wrapper div",
    { marginRight: "-200px" },
    { duration: 1, stagger: 0.2, right: 0, marginRight: "0" },
    0.2
  );
  localTimeline.to(".shape-wrap", {
    duration: 1.5,
    left: "15%",
    ease: "expo.out",
  });
  return localTimeline;
};

export const story_7_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".back-layer",
    {
      duration: 3,
      scale: 1.2,
    },
    1
  );
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 0.4,
    },
    +2
  );
  return localTimeline;
};
export const story_7_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 1,
    }
  );
  localTimeline.fromTo(
    ".shape-wrap",
    { left: "51%" },
    { duration: 2, left: "-35%", ease: "expo.out" },
    1.5
  );
  return localTimeline;
};

export const story_7_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 1,
    }
  );
  localTimeline.to(
    ".shape-wrap",
    { duration: 2, right: "-65%", ease: "expo.out" },
    1.5
  );
  return localTimeline;
};

export const story_7_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".text-wrapper",
    { duration: 1, clipPath: "polygon(0 100%, 100% 100%, 100% 0%, 0 0%)" },
    0.5
  );
  localTimeline.to(
    ".text-wrapper div",
    { duration: 1, clipPath: "polygon(0 100%, 100% 100%, 100% 0%, 0 0%)" },
    1.1
  );
  return localTimeline;
};

export const story_7_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".ellipse",
    { marginLeft: "-500%" },
    { duration: 0.5, marginLeft: "-240%" },
    1
  );
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: "0" },
    { autoAlpha: 1, stagger: 1 },
    0.5
  );
  localTimeline.fromTo(
    ".vid-wrapper",
    { marginLeft: "-200px" },
    {
      duration: 0.5,
      marginLeft: "-3px",
      ease: "none",
      onComplete() {
        localTimeline.to(
          ".top-right-to-bottom-right",
          {
            duration: 0.3,
            height: "100%",
            ease: "none",
            onComplete() {
              localTimeline.to(".bottom-right-to-bottom-left", {
                duration: 0.3,
                width: "100%",
                ease: "none",
                onComplete() {
                  localTimeline.to(".bottom-left-to-top-left", {
                    duration: 0.3,
                    height: "100%",
                    ease: "none",
                    onComplete() {
                      localTimeline.to(".top-left-to-top-right", {
                        duration: 0.3,
                        width: "100%",
                        ease: "expo.out",
                      });
                    },
                  });
                },
              });
            },
          },
          2
        );
      },
    },
    0.3
  );
  return localTimeline;
};

export const story_7_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".ellipse",
    { marginRight: "-500%" },
    { duration: 0.5, marginRight: "-240%" }
  );
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { autoAlpha: 1, stagger: 1 },
    0.5
  );
  localTimeline.fromTo(
    ".vid-wrapper",
    { marginLeft: "200px" },
    {
      duration: 0.5,
      marginLeft: "-23px",
      ease: "none",
      onComplete() {
        localTimeline.to(
          ".top-right-to-bottom-right",
          {
            duration: 0.3,
            height: "100%",
            ease: "none",
            onComplete() {
              localTimeline.to(".bottom-right-to-bottom-left", {
                duration: 0.3,
                width: "100%",
                ease: "none",
                onComplete() {
                  localTimeline.to(".bottom-left-to-top-left", {
                    duration: 0.3,
                    height: "100%",
                    ease: "none",
                    onComplete() {
                      localTimeline.to(".top-left-to-top-right", {
                        duration: 1,
                        width: "100%",
                        ease: "expo.out",
                      });
                    },
                  });
                },
              });
            },
          },
          1
        );
      },
    },
    0.3
  );
  return localTimeline;
};

export const story_7_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "-200px" },
    { duration: 1, stagger: 0.2, marginLeft: 0 },
    0.2
  );
  localTimeline.to(".shape-wrap", {
    duration: 1.5,
    left: "-20%",
    ease: "expo.out",
  });
  return localTimeline;
};

export const story_7_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.from(
    ".text-wrapper div",
    { marginRight: "-200px" },
    { duration: 1, stagger: 0.2, right: 0, marginRight: "0" },
    0.2
  );
  localTimeline.to(".shape-wrap", {
    duration: 1.5,
    left: "15%",
    ease: "expo.out",
  });
  return localTimeline;
};
