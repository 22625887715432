import styled from "styled-components";

export const Square4Style = styled.div`
  width: 100%;
  height: 100%;

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container5 {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }


  .back-layer {
    width: 100%;
    height: 100%;
  }

  .back-layer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .text-wrapper {
    top: 0;
    position: absolute;
    width: 50%;
    left:20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  .text-wrapper > div {
    width:100%;
  }

`;
