import { init } from "./common";
import { gsap, Power1, Power2, Power3, Power4, Linear } from "gsap";

export const regular_10_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".ellipse", {
    duration: 1,
    opacity: 1,
    scale: 1.4,
    ease: "expo.out",
  });

  localTimeline.fromTo(
    ".text-wrapper div",
    { marginBottom: "-200px", autoAlpha: 0 },
    {
      autoAlpha: 1,
      duration: 0.7,
      marginBottom: "0",
      stagger: 0.2,
    }
  );

  return localTimeline;
};

export const regular_10_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginBottom: "-200px" },
    {
      autoAlpha: 1,
      bottom: "0vh",
      stagger: 0.1,
      marginBottom: "0",
      duration: 1,
    },
    0.5
  );
  return localTimeline;
};

export const regular_10_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".ellipse",
    { duration: 1, opacity: 1, scale: 2, ease: "expo.out" },
    1
  );
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginBottom: "-200px" },
    { autoAlpha: 1, marginBottom: "0", duration: 0.5, stagger: 0.3 }
  );

  return localTimeline;
};

export const regular_10_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".ellipse",
    {
      duration: 1,
      opacity: 1,
      scale: 2,
      ease: "expo.out",
    },
    1
  );

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginBottom: "-200px" },
    {
      autoAlpha: 1,
      marginBottom: "0",
      stagger: 0.1,
    }
  );

  return localTimeline;
};

export const regular_10_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(
    ".border-white",
    {
      duration: 0.8,
      // right: "98.8%",
      left: "13.8%",
      ease: "none",
    },
    +1
  );

  localTimeline.to(
    ".text-wrapper",
    {
      duration: 0.8,
      clipPath: "polygon(98% 0%, 100% 0%, 100% 100%, 98% 100%)",
      ease: "none",
    },
    +1
  );

  localTimeline.to(
    ".text-wrapper",
    {
      duration: 0.8,
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      ease: "none",
    },
    +1
  );
  return localTimeline;
};

export const regular_10_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".text-wrapper",
    {
      duration: 0.8,
      clipPath: "polygon(0% 0%, 2% 0%, 2% 100%, 0% 100%)",
      ease: "none",
    },
    +1
  );
  localTimeline.to(
    ".text-wrapper",
    {
      duration: 0.8,
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      ease: "none",
    },
    +1
  );
  localTimeline.to(
    ".border-white",
    {
      duration: 0.8,
      left: "92.8%",
      ease: "none",
    },
    +1
  );
  return localTimeline;
};

export const regular_10_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".front-layer .fw", {
    css: { width: "0%" },
    duration: 0.7,
  });

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { autoAlpha: 1, stagger: 0.8 }
  );

  return localTimeline;
};
export const regular_10_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".container10", {
    duration: 2,
    clipPath: "circle(100% at 50% 50%)",
    ease: "none",
  });

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { autoAlpha: 1, stagger: 0.8 }
  );

  return localTimeline;
};

export const square_10_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".ellipse", {
    duration: 1,
    opacity: 1,
    scale: 1.4,
    ease: "expo.out",
  });

  localTimeline.fromTo(
    ".text-wrapper div",
    { marginBottom: "-200px", autoAlpha: 0 },
    {
      autoAlpha: 1,
      duration: 0.7,
      marginBottom: "0",
      stagger: 0.2,
    }
  );

  return localTimeline;
};
export const square_10_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginBottom: "-200px" },
    {
      autoAlpha: 1,
      bottom: "0vh",
      stagger: 0.1,
      marginBottom: "0",
      duration: 1,
    },
    0.5
  );
  return localTimeline;
};

export const square_10_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".ellipse",
    { duration: 1, opacity: 1, scale: 2, ease: "expo.out" },
    1
  );
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginBottom: "-200px" },
    { autoAlpha: 1, marginBottom: "0", duration: 0.5, stagger: 0.3 }
  );

  return localTimeline;
};

export const square_10_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".ellipse",
    {
      duration: 1,
      opacity: 1,
      scale: 2,
      ease: "expo.out",
    },
    1
  );

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginBottom: "-200px" },
    {
      autoAlpha: 1,
      marginBottom: "0",
      stagger: 0.1,
    }
  );

  return localTimeline;
};

export const square_10_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".text-wrapper", {
    duration: 0.5,
    clipPath: "polygon(98% 0%, 100% 0%, 100% 100%, 98% 100%)",
    ease: "none",
    onComplete() {
      localTimeline.to(".text-wrapper", {
        duration: 0.8,
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        ease: "none",
      });
      localTimeline.to(".border-white", {
        duration: 0.8,
        right: "98.8%",
        ease: "none",
      });
    },
  });
  return localTimeline;
};

export const square_10_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".text-wrapper", {
    duration: 0.5,
    clipPath: "polygon(0% 0%, 2% 0%, 2% 100%, 0% 100%)",
    ease: "none",
    onComplete() {
      localTimeline.to(".text-wrapper", {
        duration: 0.8,
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        ease: "none",
      });
      localTimeline.to(".border-white", {
        duration: 0.8,
        left: "98.8%",
        ease: "none",
      });
    },
  });
  return localTimeline;
};

export const square_10_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".front-layer .fw", {
    css: { width: "0%" },stagger:0,
    duration: 1.5,
  },+1);

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { autoAlpha: 1, stagger: 0.8 }
  );

  return localTimeline;
};
export const square_10_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".container10", {
    duration: 2,
    clipPath: "circle(100% at 50% 50%)",
    ease: "none",
  });

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { autoAlpha: 1, stagger: 0.8 }
  );

  return localTimeline;
};

export const story_10_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".ellipse", {
    duration: 1,
    opacity: 1,
    scale: 1.4,
    ease: "expo.out",
  });

  localTimeline.fromTo(
    ".text-wrapper div",
    { marginBottom: "-200px", autoAlpha: 0 },
    {
      autoAlpha: 1,
      duration: 0.7,
      marginBottom: "0",
      stagger: 0.2,
    }
  );

  return localTimeline;
};
export const story_10_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginBottom: "-200px" },
    {
      autoAlpha: 1,
      bottom: "0vh",
      stagger: 0.1,
      marginBottom: "0",
      duration: 1,
    },
    0.5
  );
  return localTimeline;
};

export const story_10_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".ellipse",
    { duration: 1, opacity: 1, scale: 2, ease: "expo.out" },
    1
  );

  localTimeline.fromTo(
    ".text-wrapper div",
    { marginBottom: "-200px", autoAlpha: 0 },
    {
      autoAlpha: 1,
      duration: 0.7,
      marginBottom: "0",
      stagger: 0.2,
    }
  );
  return localTimeline;
};

export const story_10_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".ellipse",
    {
      duration: 1,
      opacity: 1,
      scale: 2,
      ease: "expo.out",
    },
    1
  );

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginBottom: "-200px" },
    {
      autoAlpha: 1,
      marginBottom: "0",
      stagger: 0.1,
    }
  );

  return localTimeline;
};

export const story_10_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".text-wrapper", {
    duration: 0.5,
    clipPath: "polygon(98% 0%, 100% 0%, 100% 100%, 98% 100%)",
    ease: "none",
    onComplete() {
      localTimeline.to(".text-wrapper", {
        duration: 0.8,
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        ease: "none",
      });
      localTimeline.to(".border-white", {
        duration: 0.8,
        right: "98%",
        ease: "none",
      });
    },
  });
  return localTimeline;
};

export const story_10_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".text-wrapper", {
    duration: 0.5,
    clipPath: "polygon(0% 0%, 2% 0%, 2% 100%, 0% 100%)",
    ease: "none",
    onComplete() {
      localTimeline.to(".text-wrapper", {
        duration: 0.8,
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        ease: "none",
      });
      localTimeline.to(".border-white", {
        duration: 0.8,
        left: "98%",
        ease: "none",
      });
    },
  });
  return localTimeline;
};

export const story_10_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".front-layer .fw", {
    css: { width: "0%" },stagger:0,
    duration: 0.7,
  },+0.5);

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { autoAlpha: 1, stagger: 0.8 }
  );

  return localTimeline;
};

export const story_10_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".container10", {
    duration: 2,
    clipPath: "circle(100% at 50% 50%)",
    ease: "none",
  });

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { autoAlpha: 1, stagger: 0.8 }
  );

  return localTimeline;
};
