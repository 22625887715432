import styled from "styled-components";

export const Regular1Style = styled.div`
  width: 100%;
  height: 100%;
  .flex-column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .flex-row-center {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .flex-row-left {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: left;
    justify-content: left;
  }

  .subflex-left {
    width: 100%;
    text-align: left;
  }

  .subflex-right {
    width: 100%;
    text-align: right;
  }

  .subflex-center {
    width: 100%;
    text-align: center;
  }

  .poster {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
  }

  .logo-container {
    position: relative;
    height: 150px;
    width: 100%;
    overflow: hidden;
  }

  .logo {
    position: absolute;
    transform: rotate(-90deg);
    bottom: -150px;
    //height: 150px;
    width: 150px;
  }

  .text-wrapper {
    position: relative;
    width: 100%;
    min-height: 16px;
    overflow: hidden;
  }
`;
