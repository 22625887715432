import { gsap, Linear, Power1, Power4, Power2 } from "gsap";
import { init } from "./common";

export const regular_2_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".front-layer",
    { left: "-200%" },
    { duration: 1, left: "0", ease: "expo.out" },
    1
  );
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0, top: -1500 },
    { top: 0, stagger: 0.2, autoAlpha: 1, duration: 1 },
    +1.2
  );

  return localTimeline;
};

export const regular_2_2 = (pauseTTS) => {
  const localTimeline = gsap.timeline({
    paused: true,
    onComplete: pauseTTS,
  });
  localTimeline.to(".front-layer", { right: 0, duration: 1 }, +1);
  localTimeline.fromTo(
    ".text-wrappers div",
    { marginBottom: "-200%", autoAlpha: 0 },
    { marginBottom: "0", duration: 1, autoAlpha: 1, stagger: 0.4 },
    +1.3
  );
  return localTimeline;
};

export const regular_2_3 = (pauseTTS) => {
  const localTimeline = gsap.timeline({
    paused: true,
    onComplete: pauseTTS,
  });
  localTimeline.to(
    ".front-layer",
    {
      duration: 3,
      left: "-65%",
      ease: "expo.out",
    },
    +1
  );
  localTimeline.fromTo(
    ".text-wrappers",
    { autoAlpha: 0 },
    { autoAlpha: 1, stagger: 1 },
    +1.1
  );
  return localTimeline;
};

export const regular_2_4 = (pauseTTS) => {
  const localTimeline = gsap.timeline({
    paused: true,
    onComplete: pauseTTS,
  });
  localTimeline.to(".front-layer", {
    duration: 3,
    right: "-43%",
    scale: 1,
    ease: "expo.out",
  });
  localTimeline.to(".text-wrappers", { opacity: 1, stagger: 1 }, "-=2");
  localTimeline.to(".back-layer img", { duration: 5, scale: 1.1 });
  return localTimeline;
};

export const regular_2_5 = (pauseTTS) => {
  const localTimeline = gsap.timeline({
    paused: true,
    onComplete: pauseTTS,
  });
  localTimeline.to(".back-layer img", { duration: 8, scale: 1.1 });
  localTimeline.to(".text-wrappers", { opacity: 1, stagger: 1 }, "-=7");
  return localTimeline;
};
export const regular_2_6 = (pauseTTS) => {
  const localTimeline = gsap.timeline({
    paused: true,
    onComplete: pauseTTS,
  });
  localTimeline.to(".front-layer .fw", { css: { width: "0%" }, duration: 1.5 });
  localTimeline.to(".back-layer img", { duration: 8, scale: 1.1 });
  localTimeline.to(".text-wrappers", { opacity: 1, stagger: 1 }, "-=6.5");
  return localTimeline;
};
export const regular_2_7 = (pauseTTS) => {
  const localTimeline = gsap.timeline({
    paused: true,
    onComplete: pauseTTS,
  });
  localTimeline.to(".back-layer img", { duration: 3, left: "10%" }, 1);
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0, marginLeft: "-200px" },
    { marginLeft: "0", autoAlpha: 1, left: "0", stagger: 0.5 },
    +2
  );
  localTimeline.to(
    ".text-wrappers div span",
    { left: 0, opacity: 1, stagger: 0.5, ease: Linear.easeNone },
    +2
  );
  return localTimeline;
};
export const regular_2_8 = (pauseTTS) => {
  const localTimeline = gsap.timeline({
    paused: true,
    onComplete: pauseTTS,
  });
  localTimeline.to(".back-layer img", { duration: 5, scale: 1 }, 1);
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0 },
    {
      autoAlpha: 1,
      clipPath: "polygon(0% 0%,100% 0%,100% 100%,0% 100%)",
      stagger: 0.3,
    },
    +3
  );
  return localTimeline;
};

export const square_2_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".front-layer",
    { left: "-200%" },
    { duration: 1, left: "0", ease: "expo.out" },
    1
  );
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0, top: -1500 },
    { top: 0, stagger: 0.2, autoAlpha: 1, duration: 1.3 },
    +1.2
  );

  return localTimeline;
};
export const square_2_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".front-layer",
    { right: "-200%" },
    { duration: 1, right: "0", ease: "expo.out" },
    1
  );
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0, bottom: -1500 },
    { autoAlpha: 1, duration: 1, bottom: 0, stagger: 0.1 },
    +1.2
  );

  return localTimeline;
};

export const square_2_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(
    ".front-layer",
    { duration: 1, left: "-45%", scale: 1, ease: "expo.out" },
    1
  );
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0 },
    { autoAlpha: 1, stagger: 0.1 },
    +1.3
  );
  return localTimeline;
};

export const square_2_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".front-layer", {
    duration: 1,
    right: "-43%",
    scale: 1,
    ease: "expo.out",
  });
  localTimeline.to(".back-layer img", { duration: 5, scale: 1.1 });
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0 },
    { autoAlpha: 1 },
    2
  );
  return localTimeline;
};

export const square_2_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".back-layer img", { duration: 8, scale: 1.1 }, 1);
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0 },
    { autoAlpha: 1, duration: 2 },
    +1.5
  );
  return localTimeline;
};

export const square_2_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0 },
    { autoAlpha: 1, duration: 1.3 },
    +1.3
  );
  localTimeline.to(
    ".front-layer .col .fw",
    {
      css: { width: "0" },
      duration: 0.7,
    },
    +0.7
  );
  localTimeline.to(".back-layer img", { duration: 8, scale: 1.1 });
  return localTimeline;
};

export const square_2_7 = (pauseTTS) => {
  const localTimeline = gsap.timeline({
    paused: true,
    onComplete: pauseTTS,
  });
  localTimeline.fromTo(
    ".back-layer img",
    { left: "0" },
    { duration: 1, left: "10%" },
    1
  );
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0, marginLeft: "-200px" },
    { marginLeft: "0", autoAlpha: 1, left: "0", stagger: 0.5 },
    +2
  );
  localTimeline.to(
    ".text-wrappers div span",
    { left: 0, opacity: 1, stagger: 0.5, ease: Linear.easeNone },
    +2
  );
  return localTimeline;
};

export const square_2_8 = (pauseTTS) => {
  const localTimeline = gsap.timeline({
    paused: true,
    onComplete: pauseTTS,
  });
  localTimeline.to(".back-layer img", { duration: 5, scale: 1 }, 1);
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0 },
    {
      autoAlpha: 1,
      clipPath: "polygon(0% 0%,100% 0%,100% 100%,0% 100%)",
      stagger: 0.3,
    },
    +3
  );
  return localTimeline;
};

export const story_2_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".front-layer",
    { left: "-200%" },
    { duration: 1, left: "0", ease: "expo.out" },
    1
  );
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0, top: -1500 },
    { top: 0, stagger: 0.2, autoAlpha: 1, duration: 1.3 },
    +1.2
  );

  return localTimeline;
};
export const story_2_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".front-layer",
    { right: "-200%" },
    { duration: 1, right: "0", ease: "expo.out" },
    1
  );
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0, bottom: -1500 },
    { autoAlpha: 1, duration: 1, bottom: 0, stagger: 0.1 },
    +1.2
  );

  return localTimeline;
};
export const story_2_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(
    ".front-layer",
    { duration: 1, left: "-45%", scale: 1, ease: "expo.out" },
    1
  );
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0 },
    { autoAlpha: 1, stagger: 0.1 },
    +1.3
  );
  return localTimeline;
};

export const story_2_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".front-layer",
    {
      duration: 1,
      right: "-43%",
      scale: 1,
      ease: "expo.out",
    },
    1
  );
  localTimeline.to(".back-layer img", { duration: 5, scale: 1.1 }, 1);
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0 },
    { autoAlpha: 1 },
    +1.3
  );
  return localTimeline;
};

export const story_2_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".back-layer img", { duration: 8, scale: 1.1 }, 1);
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0 },
    { autoAlpha: 1, duration: 2 },
    +1.5
  );
  return localTimeline;
};
export const story_2_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.fromTo(
    ".front-layer .col .fw",
    { css: { width: "100%" } },
    { css: { width: "0" }, duration: 0.5 },
    0.5
  );
  localTimeline.to(".back-layer img", { duration: 8, scale: 1.1 }, 0.5);
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0 },
    { autoAlpha: 1, duration: 2 },
    1
  );
  return localTimeline;
};
export const story_2_7 = (pauseTTS) => {
  const localTimeline = gsap.timeline({
    paused: true,
    onComplete: pauseTTS,
  });
  localTimeline.fromTo(
    ".back-layer img",
    { left: "0" },
    { duration: 3, left: "10%" },
    1
  );
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0, marginLeft: "-200px" },
    { marginLeft: "0", autoAlpha: 1, left: "0", stagger: 0.5 },
    +2
  );
  localTimeline.to(
    ".text-wrappers div span",
    { left: 0, opacity: 1, stagger: 0.5, ease: Linear.easeNone },
    +2
  );
  return localTimeline;
};

export const story_2_8 = (pauseTTS) => {
  const localTimeline = gsap.timeline({
    paused: true,
    onComplete: pauseTTS,
  });
  localTimeline.to(".back-layer img", { duration: 5, scale: 1 }, 1);
  localTimeline.fromTo(
    ".text-wrappers div",
    { autoAlpha: 0 },
    {
      autoAlpha: 1,
      clipPath: "polygon(0% 0%,100% 0%,100% 100%,0% 100%)",
      stagger: 0.3,
    },
    +3
  );
  return localTimeline;
};
