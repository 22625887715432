import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import ProfileImage from "./ProfileImage";
import ProfileInfo from "./ProfileInfo";
import ProfilePassword from "./ProfilePassowrd";
import { useSelector } from "react-redux";
import { appName } from "../../global/global";

const Profile = () => {
    const brandName = useSelector(state => state.rebrand.data);

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Profile </title>
            </Helmet>
            <Navbar />
            <section className="profile-sec">
                <div className="container">
                    <ProfileImage />
                    <ProfileInfo />
                    <ProfilePassword />
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Profile;