import React from "react";
import { Portait4Style } from "./css/Portrait4Style";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";

const Template = (props) => {
  return (
    <Portait4Style>
      <div className="template-wrapper">
        <div className="poster nine-by-sixteen">
          <TemplateLogo logo={props.logo} />
          <TemplateWatermark watermark={props.watermark} />
          <div className="bg four">
            <TemplateImage slideType={props.slideType} file={props.file} />
          </div>
          <div className="poster-content flex-column four">
            <div className="t4-mob-text-wrapper">
              <TemplateText
                id={props.id}
                text={props.text}
                textStyle={props.textStyle}
                editStatus={props.editStatus}
                onInputSlideText={props.onInputSlideText}
              />
            </div>
          </div>
        </div>
      </div>
    </Portait4Style>
  );
};

export default Template;
