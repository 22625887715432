import React, { useEffect, useState } from "react";
import reelapps from "../images/ReelApps.png"
import { useSelector } from "react-redux";

const Footer = () => {

    const rebrandData = useSelector(state => state.rebrand.data);
    const [rebrand, setRebrand] = useState(rebrandData);

    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])

    return (
        <>
            <section className="footer footerbg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 d-flex align-items-center">
                            <div className="footer-menu">
                                <ul className="list-inline m-0 d-flex">
                                    <li><a href="https://reelapps.io/terms.html" target="_blank">TERMS</a></li>
                                    <li><a href="https://reelapps.io/privacy.html" target="_blank">PRIVACY</a></li>
                                    <li><a href="http://support.vineasx.com/" target="_blank">SUPPORT</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5 d-flex align-items-center justify-content-center">
                            <div className="footer-copyright text-white text-center ">
                                <p className="m-0">
                                    © {(new Date().getFullYear())}, All Rights Reserved to <a href="https://vineasx.com/" target="blank"> VineaSX Solutions LLC.</a> Developed and Maintained by
                                    <a href="https://vega6.com/" target="_blank" className="ml-2">Vega6.</a></p>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 d-flex align-items-center justify-content-lg-end justify-content-center">
                            <div className="vineasx-logo">
                                <a
                                    className="text-white"
                                    target="blank"
                                    style={{ fontWeight: "bold", fontSize: "1.5rem" }}
                                    href="https://reelapps.io/"
                                >
                                    <img
                                        src={rebrand.logo || reelapps}
                                        className="img-fluid"
                                        style={{ height: "40px" }}
                                        alt="" />
                                </a>
                                {/* {
                                    rebrand.data !== false ?
                                        <a>
                                            <img src={rebrand.data.logo} width="180px" height="46px" />
                                        </a> : */}

                                {/* } */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default React.memo(Footer);