import styled from "styled-components";

export const Square5Style = styled.div`
  width:100%;
  height:100%;

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container4 {
    width:100%;
    height:100%;
    overflow: hidden;
    background: #f98365;
  }

  .f-peach {
    color: #f98365;
  }

  .f-white {
    color: #fff;
  }

  .f-blue {
    color: #005596;
  }

  .back-layer {
    width:100%;
    height:100%;
    position: absolute;
    background-size: cover;
    opacity: 0.6;
  }

  .front-layer:before {
    content: "";
    width:100%;
    height:100%;
    background: #000;
    position: absolute;
    opacity: 0.5;
  }

  .front-layer {
    position: absolute;
    width:99%;
    height:97%;  
    padding:1%;
    top: 0;
    clip-path: inset(0% 100% 0% 0%);
    transition: 0.5s cubic-bezier(0, 0, 0, 0);
  }

  .text-wrapper {
    top: 0;
    position: absolute;
    width:100%;
    height:100%;
    display: flex;
    padding: 5%;
    justify-content:center;
    align-items: center;
    flex-direction: column;
  }

  .text-wrapper > div {
    width: 100% ;
  }
`;
