import axios from "axios";
import { baseURL } from "../global/global";
import { setAlert } from "./alert";

export const handleDragStatus = () => (dispatch) => {
    dispatch({ type: 'UPDATE_DRAG_STATUS' })
}

export const addVideoData = (data) => (dispatch) => {
    dispatch({ type: 'ADD_VIDEO_DATA', payload: data })
}

export const addNewTiles = (data) => (dispatch) => {
    dispatch({ type: 'ADD_NEW_TILES', payload: data })
}

export const addScrapText = (data) => (dispatch) => {
    dispatch({ type: 'ADD_SCRAP_TEXT', payload: data });
}

export const addScrapImage = (data) => (dispatch) => {
    dispatch({ type: 'ADD_SCRAP_IMAGE', payload: data });
}

export const updateSelectedTiles = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_SELECTED_TILES', payload: data });
}

export const updateSlidesData = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_SLIDES_DATA', payload: data });
}

export const updateVideoThumbnail = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_THUMBNAIL', payload: data });
}

export const updateStoryStatus = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_STORY_STATUS', payload: data })
}

export const removeScrapImage = () => (dispatch) => {
    dispatch({ type: 'REMOVE_SCRAP_IMAGE' })
}


function checkURL(images) {

    const random = Math.floor(Math.random() * images.length);
    return images[random];

}

export const generateSlideData = (text, uniq_id, template_id, setLoadSlide, type = false, randomKey = false) => (dispatch, getState) => {

    axios({
        method: 'POST',
        url: `${baseURL}add-slide`,
        data: JSON.stringify({ text: text, uniq_id: uniq_id, variation_id: randomKey !== false ? randomKey : uniq_id, template_id: template_id }),
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {

            if (getState().videos.scrap_images.length > 0 && getState().videos.text !== false) {
                let resData = checkURL(getState().videos.scrap_images)

                res.data.data.file_url = resData;
                res.data.data.poster = resData;
            }

            dispatch(addNewTiles(res.data.data));
            if (type === false) {
                dispatch(updateStoryStatus(uniq_id));
            }
        }
        setLoadSlide(false);
    }).catch(err => {
        setLoadSlide(false);
    })
}

export const generateBlankSlide = (keyword = 'nature', uniq_id, template_id, randomKey, setLoaderNew) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}add-slide`,
        data: JSON.stringify({ keyword: keyword, uniq_id: uniq_id, variation_id: randomKey, template_id: template_id }),
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            dispatch(addNewTiles(res.data.data));
            dispatch(updateSelectedTiles(uniq_id - 1))
        }
        setLoaderNew(false);
    }).catch(err => {
        setLoaderNew(false);
    })
}


export const changeImage = (keyword, type, index, setRefreshLoader) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}search-media`,
        data: JSON.stringify({ keyword: keyword, search_type: type, page: 1 }),
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            let resArray = res.data.data[Math.floor(Math.random() * res.data.data.length)];
            let jsonData = {
                poster: resArray.thumb,
                index: index,
                file_url: resArray.image
            }

            dispatch({ type: 'UPDATE_SLIDE_IMAGES', payload: jsonData });

        }
        setRefreshLoader(false);
    }).catch(err => {
        setRefreshLoader(false);
    })
}


export const deleteSlideData = (data) => (dispatch) => {
    dispatch({ type: 'DELETE_SLIDE', payload: data })
}

export const deleteActiveSlide = (data, index) => (dispatch) => {
    dispatch({ type: 'DELETE_SLIDE', payload: data })
    dispatch({ type: 'UPDATE_SLIDE_ACTIVE_STATUS', payload: index })
}


/* Text Styles */
export const updateFontSize = (size, index) => (dispatch) => {

    let data = {
        font_size: size,
        index: index
    }

    dispatch({ type: 'UPDATE_FONT_SIZE', payload: data });
}

export const updateFontFamily = (font, index) => (dispatch) => {
    let data = {
        font_family: font,
        index: index
    }

    dispatch({ type: 'UPDATE_FONT_FAMILY', payload: data });
}

export const updateFontColor = (color, index) => (dispatch) => {
    let data = {
        font_color: color,
        index: index
    }

    dispatch({ type: 'UPDATE_FONT_COLOR', payload: data });
}

export const updateFontBgColor = (color, index) => (dispatch) => {
    let data = {
        bg_color: color,
        index: index
    }

    dispatch({ type: 'UPDATE_FONT_BG_COLOR', payload: data });
}


export const removeFontBgColor = (index) => (dispatch) => {
    dispatch({ type: 'REMOVE_FONT_BG_COLOR', payload: index });
}

export const updateTextBold = (status, index) => (dispatch) => {
    let data = {
        status: status,
        index: index
    }
    dispatch({ type: 'UPDATE_FONT_BOLD', payload: data });
}

export const updateTextItalic = (status, index) => (dispatch) => {
    let data = {
        status: status,
        index: index
    }
    dispatch({ type: 'UPDATE_FONT_ITALIC', payload: data });
}

export const updateTextUpper = (status, index) => (dispatch) => {
    let data = {
        status: status,
        index: index
    }
    dispatch({ type: 'UPDATE_FONT_UPPER', payload: data });
}

export const updateTextLower = (status, index) => (dispatch) => {
    let data = {
        status: status,
        index: index
    }
    dispatch({ type: 'UPDATE_FONT_LOWER', payload: data });
}

export const updateTextUnderline = (status, index) => (dispatch) => {
    let data = {
        status: status,
        index: index
    }
    dispatch({ type: 'UPDATE_FONT_UNDERLINE', payload: data });
}

export const updateTextStrike = (status, index) => (dispatch) => {
    let data = {
        status: status,
        index: index
    }
    dispatch({ type: 'UPDATE_FONT_STRIKE', payload: data });
}


export const updateTextAlign = (type, index) => (dispatch) => {
    let data = {
        align: type,
        index: index
    }
    dispatch({ type: 'UPDATE_FONT_ALIGN', payload: data });
}

export const updateSlideText = (text, index) => (dispatch) => {
    let data = {
        text: text,
        index: index
    }
    dispatch({ type: 'UPDATE_SLIDE_TEXT', payload: data });
}

export const updateTextLineHeight = (value, index) => (dispatch) => {
    let data = {
        line: value,
        index: index
    }
    dispatch({ type: 'UPDATE_TEXT_LINE_HEIGHT', payload: data });
}

export const updateSlideImage = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_SLIDE_IMAGE', payload: data });
    dispatch(setAlert("Uploaded Successfully", 'success'));

}

export const updateSlideVideo = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_SLIDE_VIDEO', payload: data });
    dispatch(setAlert("Uploaded Successfully", 'success'));

}


export const updateTtsText = (text, index) => (dispatch) => {
    let data = {
        text: text,
        index: index
    }
    dispatch({ type: 'REMOVE_BG_SPAN', payload: index })
    dispatch({ type: 'UPDATE_TTS_TEXT', payload: data });
}

export const updateLogoWatermark = (type, data) => (dispatch) => {
    dispatch({ type: type, payload: data });
}

export const removeLogoWatermark = (type) => (dispatch) => {
    dispatch({ type: type })
}

export const addTtsText = (data) => (dispatch) => {
    dispatch({ type: 'ADD_TTS_DATA', payload: data });
    dispatch(setAlert('TTS applied successfully', 'success'));
}

export const addTtsGenStatus = (data) => (dispatch) => {
    dispatch({ type: 'ADD_TTS_GEN_DATA', payload: data });
    // dispatch(removeAudioFromSlides());
}

export const removeTtsGenStatus = (data) => (dispatch) => {
    dispatch({ type: 'REMOVE_TTS_GEN_DATA', payload: data });
}


export const updateTtsGenStatus = (index, status) => (dispatch) => {
    let jsonData = {
        index: index,
        status: status
    }
    dispatch({ type: 'UPDATE_TTS_GEN_STATUS', payload: jsonData });
}

export const addAudioToSlides = (data) => (dispatch) => {
    dispatch({ type: 'ADD_AUDIO_SLIDES', payload: data })
}

export const removeAudioFromSlides = () => (dispatch) => {
    dispatch({ type: 'REMOVE_AUDIO_SLIDES' })
}

export const updateDimmerStatus = (status) => (dispatch) => {
    dispatch({ type: 'REMOVE_DIMMER_LAYER', payload: status })
}

export const addOutroData = (data) => (dispatch) => {
    dispatch({ type: 'ADD_OUTRO_DATA', payload: data });
}

export const addOutroImage = (data) => (dispatch) => {
    dispatch({ type: 'SET_OUTRO_IMAGE', payload: data });
}

export const updateOutroData = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_OUTRO_SLIDE', payload: data })
}

export const deleteOutroLogo = () => (dispatch) => {
    dispatch({ type: 'REMOVE_OUTRO_LOGO' })
}

export const addSlideDuration = (value, index) => (dispatch) => {
    let data = {
        value: value,
        index: index
    }

    dispatch({ type: 'ADD_SLIDE_DURATION', payload: data })
}

export const updateTempVariation = (id, index) => (dispatch) => {
    let data = {
        index: index,
        variation: id
    }

    dispatch({ type: 'UPDATE_TEMPLATE_VARIATION', payload: data });
}


export const updateCampaignName = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_CAMPAIGN_NAME', payload: data })
}

export const removeOutro = () => (dispatch) => {
    dispatch({ type: 'REMOVE_OUTRO' })
}

export const replaceWord = (data, text, index) => (dispatch) => {
    let jsonData = {
        data: data,
        text: text,
        index: index
    }

    dispatch({ type: 'REMOVE_WHITE_SPACE', payload: index });
    // dispatch({type: 'REMOVE_HTML', payload: jsonData});
    dispatch({ type: 'REPLACE_WORDS', payload: jsonData })
}

export const addHighLight = (text, index) => (dispatch) => {
    let jsonData = {
        text: text,
        index: index
    }

    dispatch({ type: 'UPDATE_SLIDE_TEXT', payload: jsonData })
}

export const removeSpanBg = (index) => (dispatch) => {
    dispatch({ type: 'REMOVE_WHITE_SPACE', payload: index });
    dispatch({ type: 'REMOVE_BG_SPAN', payload: index })
}

export const updateTranslate = (text, index) => (dispatch) => {
    let jsonData = {
        index: index,
        text: text
    }

    dispatch({ type: 'UPDATE_TRANSLATE_TEXT', payload: jsonData });
}

export const updateSlideSound = (status, index) => (dispatch) => {
    let jsonData = {
        status: status,
        index: index
    }

    dispatch({ type: 'UPDATE_SOUND_STATUS', payload: jsonData })
}


export const applyToAllSlide = (type) => (dispatch) => {
    dispatch({ type: 'APPLY_TO_ALL_SLIDE', payload: type })
}