import React, {useEffect, useState} from "react";
import imac from "../../images/IMAC.png"
import mac from "../../images/MAC.png"
import mac2 from "../../images/MAC2.png"
import mac3 from "../../images/MAC3.png"
import {useSelector} from "react-redux";

const DashboardAbout = () => {
    const rebrandData = useSelector(state => state.rebrand);
    const [rebrand, setRebrand] = useState(rebrandData);

    useEffect(()=>{
        setRebrand(rebrandData);
    },[])

    return(
        <>
        <div className="dashborad-sec-wrap">
            <section className="dashborad-sec-3">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 d-flex align-items-center">
                            <div className="dashborad-sec-3-content text-white">
                                <h6>Get your next video content in 60 seconds</h6>
                                <h4 className="mt-3">Create videos simply by pasting any URL or keyword</h4>
                                <p className="mt-4">Your brand-new video is just a few clicks away. No more need to
                                    spend hours upon hours manually writing, editing, and rendering just to get your
                                    next upload out there. Now, all you need is a URL or a keyword and { rebrand.data === false ? 'ClipsReel' : rebrand.data.name} powerful A.I. converts it into a video for you.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="dashborad-sec-3-img imgsizeleft pl-lg-5 pl-md-5">
                                <img src={imac} alt="mac" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="dashborad-sec-3">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-md-6">
                            <div className="dashborad-sec-3-img imgsizeright">
                                <img src={mac} alt="mac" />
                            </div>
                        </div>


                        <div className="col-md-6 d-flex align-items-center">
                            <div className="dashborad-sec-3-content text-white">
                                <h6>Polish your videos effortlessly</h6>
                                <h4 className="mt-3">Fully-customize and optimize all your videos </h4>
                                <p className="mt-4">Forget about painstakingly editing in captions, graphics, logo, call
                                    to action, watermark, and more. { rebrand.data === false ? 'ClipsReel' : rebrand.data.name} has a marketer-optimized editor that
                                    readily gives you all the tools that make all your videos unique and brandable.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="dashborad-sec-3">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 d-flex align-items-center">
                            <div className="dashborad-sec-3-content text-white">
                                <h6>Boost your viewership and watch time</h6>
                                <h4 className="mt-3">Get high-quality voiceover and captions easily</h4>
                                <p className="mt-4">Captions and voiceovers are proven to drive your viewer count and
                                    watch time. And { rebrand.data === false ? 'ClipsReel' : rebrand.data.name} makes it easy for you to get those. Simply paste your text
                                    to get a high-quality voiceover narration or upload your audio to get a ready-to-use
                                    caption and subtitle.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="dashborad-sec-3-img imgsizeleft pl-lg-5 pl-md-5">
                                <img src={mac2} alt="mac" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="dashborad-sec-3">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-md-6">
                            <div className="dashborad-sec-3-img imgsizeright">
                                <img src={mac3} alt="" />
                            </div>
                        </div>


                        <div className="col-md-6 d-flex align-items-center">
                            <div className="dashborad-sec-3-content text-white">
                                <h6>Create videos that stand out</h6>
                                <h4 className="mt-3 pr-lg-5">Unlimited access to a rich media library</h4>
                                <p className="mt-4">No more need to pay for third-party stock photo and video providers.
                                    Create captivating videos with unlimited access to { rebrand.data === false ? 'ClipsReel' : rebrand.data.name}’s media library filled
                                    with copyright-free media elements for all your videos. </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            </div>
        </>
    )
}

export default DashboardAbout;