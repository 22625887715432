import styled from "styled-components";

export const Square3Style = styled.div`
  width:100%;
  height:100%;

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container4 {
    width:100%;
    height:100%;
    overflow: hidden;
  }

  .f-peach {
    color: #f98365;
  }

  .f-white {
    color: #fff;
  }

  .f-blue {
    color: #005596;
  }

  .back-layer {
    width:100%;
    height:100%;
    position: absolute;
    background-size: cover;
  }

  .front-layer {
    width:100%;
    height:100%;
    background: #f98365;
    position: absolute;
  }

  .text-wrapper {
    top: 0;
    position: absolute;
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5%;
    flex-direction: column;
  }

  .text-wrapper > div {
    width: 100%;
  }
`;
