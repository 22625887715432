import React from "react";
import { Square4Style } from "./css/Square4Style";
import OutroLogo from "../../components/editor/Templates/OutroLogo";
import OutroText from "../../components/editor/Templates/OutroText";

const square_4 = (props) => {
  return (
    <Square4Style>
      <div className="poster flex-column">
        <div className="poster-content">
          <div className="logo-container flex-column">
            <OutroLogo logo={props.file} />
          </div>
          <div className="text-wrapper flex-column">
            <OutroText
              id={props.id}
              text={props.text}
              textStyle={props.textStyle}
              editStatus={props.editStatus}
              onInputSlideText={props.onInputSlideText}
            />
          </div>
        </div>
      </div>
    </Square4Style>
  );
};

export default square_4;
