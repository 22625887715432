
const initialState = {
    data: {
        id: false,
        user_id: false,
        template_id: false,
        name: false,
        url: "",
        poster: false,
        storyboard: [],
        slides: [{
            type: "image",
            text: false,
            uniq_id: "0",
            font_color: "#FFFFFF",
            font_family: "San-Serif",
            bg_color: false,
            font_size: 18,
            align: "left",
            bold: false,
            italic: false,
            uppercase: false,
            lowercase: false,
            underline: false,
            strike: false,
            file_url: false,
            poster: false,
            audio: false,
            line_height: 1,
            is_selected: true,
            tts: false,
            translate_text: false,
            generated: true,
        }],
        size: "9:16",
        music: false,
        logo: false,
        outro: false,
        watermark: false,
        video_path: false,
        status: false,
        in_queue: false,
        failed: false,
        dimmer: false,
        error_log: false,
        deleted: false,
        sound: true,
        is_drag: "0"
    },
    text: false,
    scrap_images: []
}

export default function (state = initialState, action) {
    switch (action.type) {

        case 'UPDATE_DRAG_STATUS':
            return {
                ...state,
                data: {
                    ...state.data,
                    is_drag: "1"
                }
            }

        case 'ADD_VIDEO_DATA':
            return {
                ...state,
                data: action.payload
            }

        case 'ADD_SCRAP_TEXT':
            return {
                ...state,
                text: action.payload
            }

        case 'ADD_SCRAP_IMAGE':
            let arrayImg = [...state.scrap_images];
            arrayImg.push(action.payload);
            return {
                ...state,
                scrap_images: arrayImg
            }

        case 'REMOVE_SCRAP_IMAGE':
            return {
                ...state,
                scrap_images: []
            }

        case 'ADD_NEW_TILES':
            let array = state.data.slides;
            array.push(action.payload);
            state.data.slides = array
            return {
                ...state,
            }

        case 'UPDATE_TRANSLATE_TEXT':
            const newTrans = [...state.data.slides];
            newTrans[action.payload.index].translate_text = action.payload.text
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: newTrans
                }
            }

        case 'UPDATE_SELECTED_TILES':
            let index = state.data.slides.findIndex(({ is_selected }) => is_selected === true);
            state.data.slides[index].is_selected = false;
            state.data.slides[action.payload].is_selected = true;
            return {
                ...state
            }

        case 'UPDATE_SLIDES_DATA':
            state.data.slides = action.payload;
            return {
                ...state
            }

        case 'UPDATE_THUMBNAIL':
            state.data.poster = action.payload
            return {
                ...state
            }

        case 'UPDATE_STORY_STATUS':
            state.data.storyboard[action.payload].status = true;
            return {
                ...state
            }

        case 'UPDATE_SLIDE_IMAGES':
            state.data.slides[action.payload.index].file_url = action.payload.file_url;
            state.data.slides[action.payload.index].poster = action.payload.poster;
            state.data.slides[action.payload.index].type = "image";
            return {
                ...state
            }

        case 'UPDATE_SLIDE_TEXT':
            const newArray = [...state.data.slides];
            newArray[action.payload.index].text = action.payload.text
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: newArray
                }
            }

        case 'DELETE_SLIDE':
            state.data.slides = action.payload
            return {
                ...state
            }

        case 'ADD_SLIDE_DURATION':
            state.data.slides[action.payload.index].duration = action.payload.value;
            return {
                ...state
            }

        case 'UPDATE_FONT_SIZE':
            state.data.slides[action.payload.index].font_size = action.payload.font_size;
            return {
                ...state
            }

        case 'UPDATE_FONT_FAMILY':
            state.data.slides[action.payload.index].font_family = action.payload.font_family;
            return {
                ...state
            }

        case 'UPDATE_FONT_COLOR':
            state.data.slides[action.payload.index].font_color = action.payload.font_color;
            return {
                ...state
            }

        case 'UPDATE_FONT_BG_COLOR':
            state.data.slides[action.payload.index].bg_color = action.payload.bg_color;
            return {
                ...state
            }

        case 'UPDATE_TEXT_LINE_HEIGHT':
            state.data.slides[action.payload.index].line_height = action.payload.line;
            return {
                ...state
            }

        case 'REMOVE_FONT_BG_COLOR':
            state.data.slides[action.payload].bg_color = false;
            return {
                ...state
            }

        case 'UPDATE_FONT_BOLD':
            state.data.slides[action.payload.index].bold = action.payload.status;
            return {
                ...state
            }

        case 'UPDATE_FONT_ITALIC':
            state.data.slides[action.payload.index].italic = action.payload.status;
            return {
                ...state
            }

        case 'UPDATE_FONT_UPPER':
            state.data.slides[action.payload.index].uppercase = action.payload.status;
            return {
                ...state
            }

        case 'UPDATE_FONT_LOWER':
            state.data.slides[action.payload.index].lowercase = action.payload.status;
            return {
                ...state
            }

        case 'UPDATE_FONT_UNDERLINE':
            state.data.slides[action.payload.index].underline = action.payload.status;
            return {
                ...state
            }

        case 'UPDATE_FONT_STRIKE':
            state.data.slides[action.payload.index].strike = action.payload.status;
            return {
                ...state
            }

        case 'UPDATE_FONT_ALIGN':
            state.data.slides[action.payload.index].align = action.payload.align;
            return {
                ...state
            }

        case 'UPDATE_SLIDE_IMAGE':
            state.data.slides[action.payload.index].file_url = action.payload.file_url;
            state.data.slides[action.payload.index].poster = action.payload.poster;
            state.data.slides[action.payload.index].type = "image";
            return {
                ...state
            }

        case 'UPDATE_SLIDE_VIDEO':
            state.data.slides[action.payload.index].file_url = action.payload.file_url;
            state.data.slides[action.payload.index].poster = action.payload.poster;
            state.data.slides[action.payload.index].type = action.payload.type;
            return {
                ...state
            }

        case 'UPDATE_TTS_TEXT':
            state.data.slides[action.payload.index].tts_text = action.payload.text;
            return {
                ...state
            }

        case 'ADD_LOGO':
            state.data.logo = action.payload
            return {
                ...state
            }

        case 'ADD_WATERMARK':
            state.data.watermark = action.payload
            return {
                ...state
            }

        case 'REMOVE_LOGO_LAYER':
            state.data.logo = ""
            return {
                ...state
            }

        case 'REMOVE_WATERMARK_LAYER':
            state.data.watermark = ""
            return {
                ...state
            }

        case 'REMOVE_DIMMER_LAYER':
            console.log(action.payload);
            state.data.dimmer = action.payload
            return {
                ...state
            }

        case 'UPDATE_LOGO_POSITION':
            state.data.logo.position = action.payload
            return {
                ...state,
            }

        case 'UPDATE_WATERMARK_POSITION':
            state.data.watermark.position = action.payload
            return {
                ...state
            }

        case 'ADD_TTS_DATA':
            state.data.slides[action.payload.index].tts = action.payload
            return {
                ...state
            }

        case 'ADD_TTS_GEN_DATA':
            state.data.slides[action.payload.index].tts_status = action.payload
            return {
                ...state
            }

        case 'REMOVE_TTS_GEN_DATA':
            state.data.slides[action.payload].tts_status = false
            state.data.slides[action.payload].tts = false
            return {
                ...state
            }

        case 'UPDATE_TTS_GEN_STATUS':
            state.data.slides[action.payload.index].tts_status = action.payload.status
            return {
                ...state
            }

        case 'ADD_AUDIO_SLIDES':
            state.data.music = action.payload
            return {
                ...state
            }

        case 'REMOVE_AUDIO_SLIDES':
            state.data.music = false
            return {
                ...state
            }

        case 'ADD_OUTRO_DATA':
            state.data.outro = action.payload
            return {
                ...state
            }

        case 'SET_OUTRO_IMAGE':
            let outroIndex = state.data.slides.findIndex(({ is_outro }) => is_outro === true);
            state.data.slides[outroIndex].file_url = action.payload;
            return {
                ...state
            }

        case 'UPDATE_OUTRO_SLIDE':
            let outroI = state.data.slides.findIndex(({ is_outro }) => is_outro === true);
            state.data.slides[outroI].variation_id = action.payload.variation;
            return {
                ...state
            }


        case 'REMOVE_OUTRO_LOGO':
            let indexOutro = state.data.slides.findIndex(({ is_outro }) => is_outro === true);
            state.data.slides[indexOutro].file_url = false;
            // state.data.outro.logo = false
            return {
                ...state
            }

        case 'UPDATE_TEMPLATE_VARIATION':
            state.data.slides[action.payload.index].variation_id = action.payload.variation;
            return {
                ...state
            }

        case 'UPDATE_CAMPAIGN_NAME':
            state.data.name = action.payload
            return {
                ...state
            }

        case 'REMOVE_OUTRO':
            state.data.outro = false;
            return {
                ...state
            }

        case 'REMOVE_HTML':
            console.log(action.payload);
            let tempArr1 = state.data.slides[action.payload.index].text.replace(/<\/?span[^>]*>/g, '');
            // let tempArr1 = state.data.slides[action.payload.index].text.map((item)=>{
            //     return(
            //         item.replace(/<[^>]+>/g, '')
            //     )
            // })
            state.data.slides[action.payload.index].text = tempArr1;
            return {
                ...state
            }

        case 'REMOVE_BG_SPAN':
            let tempArrColor = state.data.slides[action.payload].text.replace(/<\/?span[^>]*>/g, '');
            state.data.slides[action.payload].text = tempArrColor;
            return {
                ...state
            }

        case 'REMOVE_WHITE_SPACE':
            let tempArr2 = state.data.slides[action.payload].text.replace('&nbsp;', '');
            state.data.slides[action.payload].text = tempArr2;
            return {
                ...state
            }

        case 'REPLACE_WORDS':
            let tempArr = state.data.slides[action.payload.index].text.replace(action.payload.text, action.payload.data)
            state.data.slides[action.payload.index].text = tempArr;
            return {
                ...state
            }


        case 'REMOVE_SCRAP_DATA':
            return {
                ...state,
                text: false
            }

        case 'UPDATE_SOUND_STATUS':
            const newSound = [...state.data.slides];
            newSound[action.payload.index].sound = action.payload.status
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: newSound
                }
            }

        case 'UPDATE_SLIDE_ACTIVE_STATUS':
            state.data.slides[action.payload].is_selected = true;
            return {
                ...state
            }

        case 'APPLY_TO_ALL_SLIDE':
            console.log('apply')
            let selectedLayer = state.data.slides.find(({ is_selected }) => is_selected === true);
            let selectSlides = [...state.data.slides];
            selectSlides.map((slide, index) => {
                return (
                    slide.bg_color = selectedLayer.bg_color,
                    slide.font_color = selectedLayer.font_color,
                    slide.font_family = selectedLayer.font_family,
                    slide.font_size = selectedLayer.font_size,
                    slide.align = selectedLayer.align,
                    slide.bold = selectedLayer.bold,
                    slide.italic = selectedLayer.italic,
                    slide.uppercase = selectedLayer.uppercase,
                    slide.lowercase = selectedLayer.lowercase,
                    slide.strike = selectedLayer.strike,
                    slide.underline = selectedLayer.underline
                )
            })

            return {
                ...state
            }

        default:
            return state

    }
}