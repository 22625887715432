import styled from "styled-components";

export const Regular6Style = styled.div`
width: 100% !important;
height: 100% !important;


.t6-containersix .t6-img-fluid {
  display: block;
  max-width: 100%;
}

.t6-containersix {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0vh 0;
  background: #00d1cf;
  display: flex;
  flex-direction: column;
}

.f-cyan {
  color: #00fffc;
}

.f-white {
  color: #fff;
}

.f-blue {
  color: #005596;
}

.t6-containersix div {
  font-family: "Poppins", sans-serif;
  line-height: 1.3;
  font-weight: 500;
  margin: 0;
}

.t6-containersix .t6-text-wrappers div {
  /*font-size: 1.65vw;*/
  text-align: center;
}

.t6-containersix .t6-woman {
  margin: 0 auto;
  clip-path: circle(0.001% at 50% 50%);
  max-width: 25%;
  max-height: 50%;
}

.t6-containersix .t6-text-wrappers {
  max-width: 85%;
  max-height: 45%;
  overflow: hidden;
  margin: 0vh auto 0;
}
  .t6-containersix .t6-text-wrappers {
    top:5%;
    text-align: center;
  }
@media screen and (max-width: 1366.98px) {
  .t6-containersix .t6-text-wrappers p {
    /*font-size: 1.4vw;*/
  }


}

@media screen and (max-width: 991.98px) {
  .t6-containersix .t6-text-wrappers p {
    /*font-size: 2.1vw;*/
  }
}

@media screen and (max-width: 640.98px) {
  .t6-containersix .t6-text-wrappers p {
    /*font-size: 3vw;*/
  }
}
`;
