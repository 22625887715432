import React, { useState } from "react";
import swal from "sweetalert";
import { useSelector } from "react-redux";

const StoryBoardText = ({ item, index, generateNewSlides, slideLength }) => {

    const auth = useSelector(state => state.auth);
    const slide20 = ['ryoungblood77@gmail.com', 'sumit@vega6.com', 'jjsmith@westnet.com.au', 'horizonunl@gmail.com'];
    const slide30 = ['khalifsbm69@gmail.com', 'salinasgino@yahoo.com', 'carla4001@gmail.com', 'guen.schmitt@yahoo.de', 'sisawoyusupha@yahoo.com', 'gisela@quakecityvideo.com', 'mr880usa@comcast.net', 'doc@iplas.com', 'leonardjffrsn@yahoo.com', 'rkmtnhi@gmail.com', 'clickonadmon@gmail.com', 'aiia.dsilva@gmail.com', 'shussey@testathletes.com', 'constance.hall@gmail.com', 'prismaelicoidale@gmail.com', 'Albaracin38@gmail.com', 'kblack59@gmail.com', 'albaracin38@gmail.com', 'darlan@adsolver.com.br', 'davecarrau@gmail.com', 'niches@marketingbiztips.com', 'simon@adasim.com', 'jbarnett7860@gmail.com', 'itajjohn@gmail.com', 'watersjulienne@gmail.com', 'olympiovital@gmail.com', 'enzo.giovinazzo77@gmail.com', 'menajohn25@gmail.com', 'pakdoctor@hotmail.com', 'norm@normbond.com', 'john.brack@gmail.com', 'dennistchen@yahoo.com', 'jolinmktg@gmail.com'];
    const slide50 = ['titogarciaogoy@gmail.com', 'rosonero484@gmail.com', 'constance.hall@gmail.com', 'Constance.hall@gmail.com', 'tiffyh2000@gmail.com', 'c023y.l3w15@gmail.com', 'dwdvids@gmail.com', 'andrew.smith02@hotmail.com', 'john.brack@gmail.com'];
    const slide100 = ['wpcontentdiscovery@gmail.com', 'rkariola19@hotmail.com', 'amous.ken@gmail.com'];
    const slide120 = ['pakdoctor@hotmail.com'];


    const [loader, setLoader] = useState(false);
    const [id, setID] = useState('');

    const addNewSlide = async (item, index) => {

        let slideLimit = 15;
        if (slide20.includes(auth.user.email)) {
            slideLimit = 20;
        }

        if (slide30.includes(auth.user.email)) {
            slideLimit = 30;
        }

        if (slide50.includes(auth.user.email)) {
            slideLimit = 50;
        }

        if (slide100.includes(auth.user.email)) {
            slideLimit = 100;
        }

        if (slide120.includes(auth.user.email)) {
            slideLimit = 120;
        }

        if (auth.user) {
            if (auth.user.membership) {
                let arr = auth.user.membership.split("__")
                if (arr.includes("unlimited")) {
                    slideLimit = 50
                }
            }
        }

        if (slideLength < slideLimit) {
            setLoader(true);
            setID(index);

            await generateNewSlides(item, index);
        } else {
            swal('Oops..', `You cannot add more than ${slideLimit} slides`);
        }

    }

    return (
        <>
            <div className={`story-para ${item.status === true ? 'para-active' : ''}`}
                onClick={(e) => addNewSlide(item, index)}
            >
                <p className="m-0">{item.text}</p>
                {
                    item.status === false && id === index ?
                        <div className="story-loader">
                            <i className="fa fa-spinner fa-spin mr-2" />
                        </div>
                        : ''
                }
            </div>
        </>
    )
}

export default React.memo(StoryBoardText);