import styled from "styled-components";

export const Portait2Style = styled.div`
  width:100%;
  height:100%;

  .container3 {
    width:100%;
    height:100%;
    overflow: hidden;
    background: #a1dffb;
  }

  .f-red {
    color: #c33124;
  }

  .f-peach {
    color: #f98365;
  }

  .f-white {
    color: #fff;
  }

  .f-blue {
    color: #005596;
  }

  .container3 .text-wrapper div {
    font-family: "Poppins", sans-serif;
    line-height: 1.5;
    font-weight: 600;
    margin: 0;
  }

  .back-layer {
    width: 100%;
    height: 100%;
    opacity: 0.6;
    clip-path: ellipse(0% 0% at 50% 0%);
  }

  .text-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5%;
  }



  .back-layer img,
  .back-layer video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 150%;
  }
`;
