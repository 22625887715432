import React, {useEffect} from 'react';
import TemplateComponents from "../../../global/TemplateComponents";
import OutroComponents from "../../../global/OutroComponents";
import {common_export} from "./gsapExport";
import {outro_export} from "./outroExport";
let localTimeline = false;
let audio = false;
let clearAudio;
const TemplateBody = (props) => {

    console.log('bodyyyyyyyyyyyyyy', props);

    let DynamicTemplate;
    if(props.is_outro)
        DynamicTemplate = OutroComponents[props.dimension][`${props.variation_id}`];
    else
        DynamicTemplate = TemplateComponents[props.dimension][`${props.template_no}-${props.variation_id}`];

    const pauseTTS = () => {
        if(audio !== false){
            console.log(Number((audio.duration).toFixed(0)));

            // audio.pause();
        //     clearAudio = setTimeout(()=>{
        //         audio.pause();
        //     }, (Number((audio.duration).toFixed(0))*1000))
        }
    }
    useEffect(() => {

        setTimeout( () => {
            if(props.is_outro)
                localTimeline = outro_export(props.dimension, props.variation_id.toString(), pauseTTS)
            else
                localTimeline = common_export(props.dimension, props.template_no.toString(), props.variation_id.toString(), pauseTTS)

            if(audio !== false){
                audio.pause();
            }

            if (props.tts !== false && props.sound === true) {
                audio = new Audio(props.tts.url);
                audio.play().then((r) => {
                    localTimeline.play();
                }).catch((err) => console.log("TTS err", err))
            } else {
                localTimeline.play(0);
            }
        },10)
    }, [props.id,props.variation_id]);

    useEffect(() => {
        if(props.playSlide === true)
        {
            if(props.tts !== false){

                audio = new Audio(props.tts.url);
                audio.play().then((r)=> localTimeline.play(0) )
            }else{
                localTimeline.play(0);
            }
        }
        else{

            if(localTimeline !== false) {
                localTimeline.pause();
            }
        }

        return () => {
            if(audio !== false){
                audio.pause();
                clearTimeout(clearAudio);
            }
        }

    },[props.playSlide])



    return (
        <DynamicTemplate {...props} />
    );
};

export default TemplateBody;
