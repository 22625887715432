import styled from "styled-components";

export const Regular4Style = styled.div`
  width: 100%;
  height: 100%;

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container_two_four {
    width: 560px;
    height: 315px;
    overflow: hidden;
  }

  .f-orange {
    color: #fb4b1f;
  }

  .f-light-orange {
    color: #fe783b;
  }

  .f-pink {
    color: #fbc6d0;
  }

  .f-pink-dark {
    color: #ff95a9;
  }

  .container_two_four div {
    font-family: "Poppins", sans-serif;
    line-height: 1.2;
    font-weight: 600;
    margin: 0;
  }

  .container_two_four .front-layer {
    position: absolute;
    top: 0;
    bottom: 0;
    /* left: 0; */
    width: 570px;
    height: 315px;
    background: #fb4b1f;
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);
    transform: scale(1.5);
  }

  .container_two_four .f-inside-layer {
    background: #fff;
    width: 560px;
    height: 315px;
    float: right;
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);
  }

  .container_two_four .front-layer img {
    width: 100%;
    height: 100%;
  }

  .container_two_four .back-layer {
    width: 100%;
    height: 100%;
    background: #ff95a9;
  }

  .container_two_four .back-layer img,
  .container_two_four .back-layer video {
    opacity: 0.5;
    width: 100%;
    object-fit: cover;
  }

  .container_two_four .text-wrappers {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 50%;
    right: 0;
    padding: 0 15px;
    height: max-content;
    opacity: 0;
  }

  .container_two_four .text-wrappers div {
    font-size: 20px;
    flex: 0 0 100%;
    text-align: right;
  }

  .container_two_four .text-wrappers div:last-child {
    font-size: 10px;
    margin-top: 10px;
  }
  
  
  // adi
  .container_two_four{
    width:100%;
    height:100%;
  }
  .back-layer{
    width: 100%;
    height: 100%;
  }

  .front-layer img, .front-layer video{
    width:100%;
  }

  .text-wrappers {
    position:absolute;
    top:0;
    left:50%;
    width:50%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text-wrappers > div {
    width:100%;
    overflow: hidden;
  }
`;
