import styled from "styled-components";

export const Square7Style = styled.div`
width: 100%;
height: 100%;

.img-fluid {
  display: block;
  max-width: 100%;
}

.container7 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.f-white {
  color: #fff;
}

.f-pippin {
  color: #ffe0db;
}

.f-cinnabar {
  color: #ec4f43;
}

.f-cinnabar-transparency {
  color: #f3928b;
}

.text-wrapper div {
  font-family: "Nexa", sans-serif;
  line-height: 1em;
  font-weight: 600;
  margin: 0;
}

.back-layer {
  width: 100%;
  height: 100%;
}

.back-layer img,
.back-layer video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.shape-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  filter: drop-shadow(10px 0px 40px rgba(0 0 0 / 50%));
  left: 29.8%;
  transform: scale(2);
}

.shape {
  position: absolute;
  background: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  clip-path: polygon(75% 0, 60% 50%, 75% 100%, 0 100%, 0 0);
}

.shape.transparent {
  left: 1.2%;
  opacity: 0.6;
}

.text-wrapper {
  top: 0;
  margin: auto;
  z-index: 1;
  position: absolute;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
}

.text-wrapper div {
  /* opacity: 0; */
  color:#e94f43 ;
  left: -50%;
  width: 100%;
}
`;
