import styled from "styled-components";

export const Square6Style = styled.div`
width: 100%;
height: 100%;
.flex-column {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.flex-row-center {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.flex-row-left {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: left;
	justify-content: left;
}

.subflex-left {
	width: 100%;
	text-align: left;
}

.subflex-right {
	width: 100%;
	text-align: right;
}

.subflex-center {
	width: 100%;
	text-align: center;
}

.f-arial {
	font-family: Arial;
}

.f-helv {
	font-family: Helvetica;
}

.f-helvbold {
	font-family: HelveticaBold;
}

.f-black {
	color: #000;
}

.f-white {
	color: #FFFFFF;
}

.f-orange {
	color: #FF7D01; 
}

.f-blue {
	color: #0076FE;
}

.poster {
	position: relative;
	overflow: hidden !important;
}

.poster.sixteen-by-nine.lg {
	width: 1920px;
	height: 1080px;
}

.poster.sixteen-by-nine.sm {
	width: 560px;
	height: 315px;
}

.poster.nine-by-sixteen.lg {
	width: 1080px;
	height: 1920px;
}

.poster.nine-by-sixteen.sm {
	width: 171px;
	height: 304px;
}

.poster.one-by-one.lg {
	width: 100%;
	height: 100%;
}

.poster.one-by-one.sm {
	width: 310px;
	height: 310px;
}




.img-container {
	position: absolute;
	left: 0;
	top: 0;
	height: 70%;
	width: 100%;
	overflow: hidden;
}

.img-container > img {
	position: absolute;
	height: 100%;
	z-index: 1;
}

.cover {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	background-color: #FFFFFF;
}

.text-wrapper {
	position: absolute;
	z-index: 3;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.text-wrapper > div {
	width: 100%;
}

/* FOR TESTING ONLY */

.f-lg {
	font-size: 92px;
}

.f-sm {
	margin-top: 50px !important;
	font-size: 62px;
}

.poster.sm .f-lg {
	font-size: 14px;
}

.poster.sm .f-sm {
	margin-top: 9px !important;
	font-size: 9px;
}

`;
