import React from "react";
import { Regular2Style } from "./css/Regular2Style";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";

const regular_1_2 = (props) => {
  return (
    <Regular2Style>
      <div className="template-wrapper">
        <TemplateLogo logo={props.logo} />
        <TemplateWatermark watermark={props.watermark} />
        <div className="t2-containersTwo d-flex">
          <div className="t2-t-colmn t2-t-colmn-1">
            <div className="t2-text-wrappers">
              <TemplateText
                id={props.id}
                text={props.text}
                textStyle={props.textStyle}
                editStatus={props.editStatus}
                onInputSlideText={props.onInputSlideText}
              />
            </div>
          </div>
          <div className="t2-t-colmn t2-t-colmn-2">
            <div className="t2-img-wrapper t2-woman">
              <TemplateImage slideType={props.slideType} file={props.file} />
            </div>
          </div>
        </div>
      </div>
    </Regular2Style>
  );
};

export default regular_1_2;
