import styled from "styled-components";

export const Portait7Style = styled.div`
width:100%;
height:100%;

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container9{
    width:100%;
    height:100%;
    overflow: hidden;
  }

  .text-wrapper div {
   width:100%;
  }

  .back-layer {
    width:100%;
    height:100%;
  }

  .back-layer img,
  .back-layer video {
    width:100%;
    height:100%;
    object-fit: cover;
    object-position: center;
  }

  .front-layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    right: 0;
    background: -moz-linear-gradient(
      left,
      rgba(0, 0, 0, 1) 0%,
      rgba(255, 255, 255, 0) 55%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(0, 0, 0, 1) 0%,
      rgba(255, 255, 255, 0) 55%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 0%,
      rgba(255, 255, 255, 0) 55%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00ffffff',GradientType=1 );
  }

  .text-wrapper {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
  }

`;
