import { combineReducers } from "redux";
import authReducer from "./authReducer";
import alertReducer from "./alertReducer";
import supportReducer from "./supportReducer";
import { RebrandingReducer } from "./rebrandReducer";
import socialReducer from "./socialReducer";
import videoReducer from "./videoReducer";
import scriptReducer from "./scriptReducer";
import campaignReducer from "./campaignReducer";
import mergeVideos from "./mergeVideos";
import { WhiteLabelData } from "./WhiteLabelData";
import branding from "./branding";

const appReducer = combineReducers({
    alert: alertReducer,
    auth: authReducer,
    support: supportReducer,
    rebrand: RebrandingReducer,
    social: socialReducer,
    videos: videoReducer,
    script: scriptReducer,
    campaigns: campaignReducer,
    merge: mergeVideos,
    wl: WhiteLabelData,
})

const rootReducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = {
            ...state,
            alert: undefined,
            auth: undefined,
            support: undefined,
            rebrand: +state.auth?.user?.isWL === 1 ? state ? state.rebrand : undefined : undefined,
            social: undefined,
            videos: undefined,
            script: undefined,
            campaigns: undefined,
            merge: undefined,
            wl: undefined,
        };
    }

    return appReducer(state, action)
}

export default rootReducers;