import styled from "styled-components";

export const Portait2Style = styled.div`
width:100%;
  height:100%;

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container7 {
    width: 100%;
    height:  100%;
    overflow: hidden;
  }

  .f-white {
    color: #fff;
  }

  .f-pippin {
    color: #ffe0db;
  }

  .f-cinnabar {
    color: #ec4f43;
  }

 

  .back-layer {
    width:  100%;
    height:  100%;
  }

  .back-layer img,
  .back-layer video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position:absolute
  }

  .shape-wrap {
    width: 110%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    filter: drop-shadow(10px 0px 40px rgba(0 0 0 / 50%));
    left: 0%;
    transform: scale(1.5);
  }

  .shape {
    position: absolute;
    background: #fe948d;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left:-15%;
    clip-path: polygon(40% 0, 100% 50%, 40% 100%, 0 100%, 0 0);
  }

  .text-wrapper {
    z-index: 1;
    position: absolute;
    top: 0;
    width:55%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;  
  }

  .text-wrapper div {

    width:100%;
  }

`;
